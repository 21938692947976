// articles edit
// редактор статей
// редактор всего
// longread editor
import "./index.scss"
import './recommendations'

var prw_tpl = '<div class="dz-preview-custom dz-file-preview"><div class="dz-details"><img data-dz-thumbnail /></div><div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span><div class="dz-filename"><span data-dz-name></span></div><div class="dz-upload-progress"></div></div><div class="dz-success-mark"></div><div class="dz-error-mark"></div><div class="dz-error-message"><span data-dz-errormessage></span></div></div>';

function progressToBg(progress) {
  var backgroundBg = "background-image: none";
  if (progress < 50) {
      backgroundBg = 'linear-gradient(' + (90 + (360 * progress / 100)) + 'deg, transparent 50%, white 50%),linear-gradient(90deg, white 50%, transparent 50%)';
  } else if (progress == 50) {
      backgroundBg = 'linear-gradient(90deg, white 50%, transparent 50%)';
  } else if (progress > 50) {
      backgroundBg = 'linear-gradient(' + ((360 * progress / 100) - 90) + 'deg, transparent 50%, #389a52 50%),linear-gradient(90deg, white 50%, transparent 50%)';
  } else if (progress == 100) {
      backgroundBg = 'none';
  }
  return backgroundBg;
}

function initCoverDropzone($el, width) {
  console.log("init dropzone", $el, width)

  var dz = $el.dropzone({
      dictDefaultMessage: '<font>Перетащите изображение в окно для загрузки</font><em>Поддерживаемые форматы: *.jpg, *.bmp, *.png</em><button type="button">Выбрать на компьютере</button>',
      acceptedFiles: 'image/*',
      thumbnailWidth: width,
      previewTemplate: prw_tpl,
      paramName: 'article[background_image]',
      init: function() {
          this.on("sending", function() {
            initAutoSave();
          })
          this.on("queuecomplete", function() {
            removeAutoSave()
          })
          this.on("success", function(file, responseText) {
            var $t = dz.parents('.article-background-uploader')
            $t.html(responseText)
            var $dz = $t.find(".dropzone")
            initCoverDropzone($dz, $t.data("width"))
          });
          this.on("thumbnail", function(file, dataUrl) {
              if ($('img.exist_cover').length) {
                  $('img.exist_cover').attr('src', dataUrl);
              }
          });
          this.on("dragstart", function() {
              $('html').addClass('drag_open');
          });
          this.on("uploadprogress", function(file, progress) {
              // console.log(this);
              $('[data-dz-uploadprogress]').css({
                  'background-image': progressToBg(progress)
              });
              $('.dz-upload-progress').text('Загружено: ' + progress + '%');
          })
      },
      resize: function(file) {
          var info;
          info = {
              srcX: 0,
              srcY: 0,
              srcWidth: file.width,
              srcHeight: file.height,
              trgX: 0,
              trgY: 0,
              trgWidth: this.options.thumbnailWidth,
              trgHeight: parseInt(this.options.thumbnailWidth * file.height / file.width)
          }
          return info;
      }
  });
}

document.addEventListener("turbolinks:load", function() {
  $('.article-background-uploader').each(function() {
    var $t = $(this)
    var $dz = $t.find(".dropzone")
    initCoverDropzone($dz, $t.data("width"))
  })
});
