tinymce.PluginManager.add('maxchars', function(editor) {
  // set a default value for the maxChars peroperty
  editor.maxChars = editor.settings.maxChars || 500;

  const label = null;

  let $warn;

  function stripTags(str) {
    const div = document.createElement("div");
    div.innerHTML = str;
    const text = div.textContent || div.innerText || "";
    return text;
  }


  function updateStyle() {
    const $t = $(editor.editorContainer);

    const content = editor.getContent();

    const len = stripTags(content).length;
    // console.log('content', content, content.length);
    // console.log('strip', stripTags(content), stripTags(content).length, len);

    if (len > editor.maxChars) {
      $warn = $t.find('.js-warning');
      if ($warn.length === 0 ){
        $t.append("<div style='font-size: 20px; color: red; white-space: normal;padding:5px;' class='js-warning'></div>");
        $warn = $t.find('.js-warning');
      }
      $warn.html(`Количество символов превышает максимально допустимое значение; Осталось удалить ${  len - editor.maxChars}`);
    } else if ($warn) {
        $warn.html("");
      }
  };

  editor.on('init', function() {
    editor.contentStyles.push(".maxchars {color: red !important;}");
    updateStyle(); // check if the initial content is valid
    editor.on("change keyUp keydown keypress paste", updateStyle);
  });
});
