import "./subscription"
import "./editor"
import "./admin"
import './articles'
// import './comment'
import './auth'
import './help'
import './leaderboard'
import './greet'
// import './material'
import './product'
import './stat'
import './moderations'
import './info'
import './profile'
import './competitions'
import initCategory from './category';


export default () => {
  const $category = $('.category');
  if ($category.length) {
    initCategory($category);
  }
}
