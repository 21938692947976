import throttle from 'lodash-es/throttle';

import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './community-recommendation.sass';
import './community-rec.sass';

const initSliders = () => {
  if (window.innerWidth > 480) {
    $('.community-recommendation__list-wrap').off('scroll');
    $(window).off('resize.shadowable');

    const $sliders = $('.community-recommendation__list').filter(":not(.slick-slider)");
    $sliders.slick({
      infinite: false,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 481,
          settings: "unslick",
        }
      ],
    });
  } else {

    const $scrollableContainers = $('.community-recommendation__list-wrap:not(.is-init)');

    if ($scrollableContainers.length) {

      $scrollableContainers.addClass('is-init');

      const checkShadow = ($scrollableContainer) => {
        const $shadowableContainer = $scrollableContainer.parent();
        if ($scrollableContainer[0].scrollWidth > $scrollableContainer.outerWidth()) {
          if ($scrollableContainer.scrollLeft()
            < Math.floor($scrollableContainer[0].scrollWidth - $scrollableContainer.outerWidth())
          ) {
            $shadowableContainer.addClass('has-sh-right');
          } else {
            $shadowableContainer.removeClass('has-sh-right');
          }
          if ($scrollableContainer.scrollLeft() > 12) {
            $shadowableContainer.addClass('has-sh-left');
          } else {
            $shadowableContainer.removeClass('has-sh-left');
          }
        } else {
          $shadowableContainer.removeClass('has-sh-right');
          $shadowableContainer.removeClass('has-sh-left');
        }
      };

      const checkShadows = () => {
        $scrollableContainers.each(function () {
          checkShadow($(this));
        });
      };

      $(window).on('resize.shadowable', throttle(function () {
        checkShadows();
      }, 50));

      $scrollableContainers.on('scroll', function () {
        checkShadow($(this));
      });

      checkShadows();
    }

  }
};

export default () => {
  initSliders();
  $(document).on('load-scroll', initSliders);
  $(window).on('resize', throttle(initSliders, 100));
};
