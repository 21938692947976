export default () => {
  $(document).off('click.user_more_action').on('click.user_more_action', '.user_more_action', function () {
    const $parentItem = $(this).parents('.subscribe_author_item');
    $('.subscribe_author_item').not($parentItem).removeClass('is-active')
    $parentItem.toggleClass('is-active');
  })

  $(document).off('click.user_more_action_clear').on('click.user_more_action_clear', function (e) {
    if (!$(e.target).closest('.user_more_action').length){
      $('.subscribe_author_item').removeClass('is-active');
    }
  })
}
