import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Presentation from '~/editor2/components/Presentation';
import ButtonIcon from '~/editor2/components/ButtonIcon';

import iconChangeBtnSrc from './icon-change.svg';
import ModalAddPresentation from '~/editor2/components/Modal/ModalAddPresentation';
import {withEditorContentContext} from "~/editor2/components/EditorContent/EditorContentContext";

class Block extends PureComponent {
  constructor(props) {
    super(props);

    this.handlerClickChange = ::this.handlerClickChange;
    this.handlerCloseModal = ::this.handlerCloseModal;
    this.handlerCompleteChange = ::this.handlerCompleteChange;

    this.state = {
      showModal: false,
    };
  }

  handlerCompleteChange(fileUrl) {
    const {container} = this.props;
    container.updateData({ fileUrl });
    this.setState({ showModal: false });
  }

  handlerCloseModal() {
    this.setState({ showModal: false });
  }

  handlerClickChange(){
    this.setState({ showModal: true });
  }

  handlerFocus = () => {
    this.setState({ isFocused: true });
  }

  handlerBlur = () => {
    this.setState({ isFocused: false });
  }

  isCanEdit() {
    const { isFocused } = this.state;
    const { isReadOnly } = this.props;
    return !isReadOnly; // || isFocused;
  }

  render() {
    const { data, article = {} } = this.props;
    const { showModal } = this.state;
    const { fileUrl } = data;
    return (
      <div
        className="Docs"
        onFocus={(e) => e.stopPropagation()}
        onBlur={(e) => e.stopPropagation()}
        onInput={(e) => e.stopPropagation()}
      >
        <Presentation src={fileUrl} />
        {this.isCanEdit() && (
          <>
            <ButtonIcon
              className="Docs__BtnChange"
              iconSrc={iconChangeBtnSrc}
              text="Заменить документ"
              onClick={this.handlerClickChange}
            />
            <ModalAddPresentation
              showModal={showModal}
              articleId={article.id}
              onClose={this.handlerCloseModal}
              onCompleteAdd={this.handlerCompleteChange}
            />
          </>
        )}
      </div>
    );
  }
}

Block.propTypes = {
  article: PropTypes.any,
  isReadOnly: PropTypes.bool,
  container: PropTypes.any,
  data: PropTypes.shape({
    fileUrl: PropTypes.string,
  }),
};

export default withEditorContentContext(Block);
