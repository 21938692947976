import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pluralize from 'pluralize-ru';
import {RCTooltipCustom} from '../../components/RCTooltip';
import './QuizProgressBar.sass';

export const QuizProgressBar = props => {
  const {progressValue, label, votes, isChoice, isAnonymous, onClick} = props;

  const handleClickItem = useCallback(() => {
    if (!isAnonymous && onClick) {
      onClick();
    }
  }, [isAnonymous, onClick]);

  const content = (
    <div
      className={classNames("QuizProgressBar", {
        'QuizProgressBar--chosen': isChoice,
        'QuizProgressBar--Clickable': !isAnonymous,
      })}
      onClick={handleClickItem}
    >
      <div className={classNames('QuizProgressBar__bar')} style={{width: `${progressValue}%`}}/>
      <div className="QuizProgressBar__info">
        <span className="QuizProgressBar__info-label">{label}</span>
        <span className="QuizProgressBar__info-separator" />
        <span className="QuizProgressBar__info-count">{votes}</span>
      </div>
      <div className="QuizProgressBar__percentage">
        {isChoice && <span className="QuizProgressBar__checkmark"/>}
        <span className="QuizProgressBar__percentage-label">{`${progressValue} %`}</span>
      </div>
    </div>
  );

  if (!isAnonymous) {
    return (
      <RCTooltipCustom
        overlay={
          <>
            <div className="QuizProgressBar__TooltipTitle">
              {votes === 1 ? "Проголосовал" : "Проголосовали"}
              {' '}
              {votes} {pluralize(votes, 'человек', 'человек', 'человека', 'человек')}
            </div>
            <span>Нажмите, чтобы просмотреть проголосовавших пользователей.</span>
          </>
        }
        placement="top"
      >
        {content}
      </RCTooltipCustom>
    );
  };

  return content;
};

QuizProgressBar.propTypes = {
  label: PropTypes.string,
  votes: PropTypes.number,
  progressValue: PropTypes.number,
  isChoice: PropTypes.bool,
  isAnonymous: PropTypes.bool,
  onClick: PropTypes.func,
};

export default QuizProgressBar;
