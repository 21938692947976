import './statistics'
import axios from 'axios';
import flash from '~/blocks/flash';

function init() {
  $(document).on('click', '.js-admin-article-publish', function(event) {
    event.preventDefault()
    axios.post($(this).attr('href'), {}).then(resp => {
      flash({success: resp.data.published_message})
      $(`.moderation__box[data-id='${resp.data.id}']`).parent().remove()
      $('#articles_on_moderation_count').html(""+((+$('#articles_on_moderation_count').text()) - 1))
    }).catch(err => {
      flash({danger: err.response.data.error})
    })
  })
}

$(init)
