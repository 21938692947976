var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (avatar, email, id, name) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"subscribe_author_item\""+pug.attr("id", "subscribe_author_item_" + id, true, true)) + "\u003E" + (null == (pug_interp = avatar) ? "" : pug_interp) + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-md-10\"\u003E\u003Cdiv class=\"row_1\"\u003E\u003Cspan class=\"size15 subbold black user_name\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"size12 gray row_2\"\u003E" + (pug.escape(null == (pug_interp = email) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-md-2 alignright\"\u003E\u003Cdiv class=\"user_checkbox\"\u003E\u003Cinput" + (" type=\"checkbox\" name=\"users[][id]\""+pug.attr("id", "user_id_"+id, true, true)+pug.attr("value", id, true, true)) + "\u003E\u003Clabel" + (pug.attr("for", "user_id_"+id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "avatar" in locals_for_with ?
        locals_for_with.avatar :
        typeof avatar !== 'undefined' ? avatar : undefined, "email" in locals_for_with ?
        locals_for_with.email :
        typeof email !== 'undefined' ? email : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined));
    ;;return pug_html;};
module.exports = template;