import axios from 'axios';
import debounce from 'lodash-es/debounce';
import flash from '~/blocks/flash/index';
import './post.sass';

/**
 *  Post show more
 */
function showMore() {
  const $elem = $('.post__description');

  $elem.each((i, v) => {
    const $this = v;
    const $btn = $(v).next();
    const $height = $(v).outerHeight();
    const $originHeight = $(v)[0].scrollHeight;

    if ($originHeight > $height) {
      $($btn).css('display', 'block');

      $($btn).off('click').on('click', function() {
        $($this).css('maxHeight', $originHeight);
        $(this).css('opacity', 0);
      });
    }
  });
}

function initOnlyOnce() {
  let bookmarkInProcess = false;
  $(document).on('click', '.js-toggle-bookmark', function clickToggleBookmark(event) {
    event.preventDefault();
    if (bookmarkInProcess) return;
    bookmarkInProcess = true;
    const $this = $(this);
    const isCheck = $this.hasClass('is-check');
    const url = $this.attr('data-url');
    const method = isCheck ? 'delete' : 'post';
    axios[method](url)
      .then((resp) => {
        $(`.js-toggle-bookmark[data-url="${url}"]`)
          .toggleClass('is-check')
          .attr('data-url', resp.data.url);
      })
      .catch(err => {
        console.error(err);
        flash({ error: 'Произошла ошибка' });
      }).then(() => {
        bookmarkInProcess = false;
      });
  });
}

function init() {

  showMore();
}

// Ellipsis

function ellipsisText(el) {
  const $el = $(el);
  const dataTitle = $el.data('title');
  if (dataTitle !== undefined && dataTitle !== null) {
    const initialTxt = (dataTitle).toString();
    const txtWrapper = initialTxt
      .split(' ')
      .map(work =>
        work
          .split('')
          .map(char => `<span>${char}</span>`)
      )
      .reduce((acc, word) => [...acc, " ", ...word], []);

    $el.html(txtWrapper.join(''));
    const $chars = $el.find('span');
    let lastIndex = $chars.length - 1;
    if (el.scrollHeight > el.offsetHeight) {
      $el.append("<span>...</span>");
      while (el.scrollHeight > el.offsetHeight) {
        $chars.eq(lastIndex)
          .hide();
        lastIndex -= 1;
      }
    }
  }
}

function ellipse() {
  $(".post__title").each((index, item) => {
    ellipsisText(item);
  });
}

ellipse();
$(initOnlyOnce);
$(document).on('turbolinks:load', init);

$(document).on('load-scroll', showMore);
$(document).on('load-scroll', ellipse);
$(window).on('resize.show-more', showMore);
$(window).on('resize.post-title', debounce(ellipse, 300));
