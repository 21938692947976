var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (all_results_url, all_tags_url, all_users_url, articles, searchRegExpReplace, tags, users) {
      pug_html = pug_html + "\u003Cdiv id=\"search_popup_inner\"\u003E";
if (articles.length > 0) {
pug_html = pug_html + "\u003Cdiv class=\"suggest__articles search_popup_sec\"\u003E\u003Cdiv class=\"search_popup_sec_head\"\u003E\u003Cstrong\u003EМатериалы\u003C\u002Fstrong\u003E";
if (articles.length > 2) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", all_results_url, true, true)) + "\u003EВсе результаты\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"search_popup_sec_body search_popup_materials\"\u003E";
// iterate articles
;(function(){
  var $$obj = articles;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var article = $$obj[pug_index0];
pug_html = pug_html + "\u003Ca" + (" class=\"suggest__article suggest__item\""+pug.attr("href", article.url, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = article.category_name) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003Cfont\u003E" + (pug.escape(null == (pug_interp = ' – ') ? "" : pug_interp)) + "\u003C\u002Ffont\u003E" + (null == (pug_interp = (article.title || '').replace(searchRegExpReplace, '<span class="highlight">$1</span>')) ? "" : pug_interp) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var article = $$obj[pug_index0];
pug_html = pug_html + "\u003Ca" + (" class=\"suggest__article suggest__item\""+pug.attr("href", article.url, true, true)) + "\u003E\u003Cspan\u003E" + (null == (pug_interp = article.category_name) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003Cfont\u003E" + (pug.escape(null == (pug_interp = ' – ') ? "" : pug_interp)) + "\u003C\u002Ffont\u003E" + (null == (pug_interp = (article.title || '').replace(searchRegExpReplace, '<span class="highlight">$1</span>')) ? "" : pug_interp) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
if (users.length > 0) {
pug_html = pug_html + "\u003Cdiv class=\"suggest__users search_popup_sec\"\u003E\u003Cdiv class=\"search_popup_sec_head\"\u003E\u003Cstrong\u003EАвторы\u003C\u002Fstrong\u003E";
if (users.length > 2) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", all_users_url, true, true)) + "\u003EВсе Авторы\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"search_popup_sec_body search_popup_authors\"\u003E";
// iterate users
;(function(){
  var $$obj = users;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var user = $$obj[pug_index1];
pug_html = pug_html + "\u003Ca" + (" class=\"suggest__user suggest__item\""+pug.attr("href", user.url, true, true)) + "\u003E\u003Cspan class=\"avatar\"\u003E" + (null == (pug_interp = user.avatar) ? "" : pug_interp) + "\u003C\u002Fspan\u003E" + (null == (pug_interp = (user.name || '').replace(searchRegExpReplace, '<span class="highlight">$1</span>')) ? "" : pug_interp) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var user = $$obj[pug_index1];
pug_html = pug_html + "\u003Ca" + (" class=\"suggest__user suggest__item\""+pug.attr("href", user.url, true, true)) + "\u003E\u003Cspan class=\"avatar\"\u003E" + (null == (pug_interp = user.avatar) ? "" : pug_interp) + "\u003C\u002Fspan\u003E" + (null == (pug_interp = (user.name || '').replace(searchRegExpReplace, '<span class="highlight">$1</span>')) ? "" : pug_interp) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
if (tags.length > 0) {
pug_html = pug_html + "\u003Cdiv class=\"suggest__tags search_popup_sec\"\u003E\u003Cdiv class=\"search_popup_sec_head\"\u003E\u003Cstrong\u003EТэги\u003C\u002Fstrong\u003E";
if (tags.length > 2) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", all_tags_url, true, true)) + "\u003EВсе Тэги\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"search_popup_sec_body search_popup_tags\"\u003E";
// iterate tags
;(function(){
  var $$obj = tags;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var tag = $$obj[pug_index2];
pug_html = pug_html + "\u003Ca" + (" class=\"suggest__tag suggest__item tag\""+pug.attr("href", tag.url, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = tag.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var tag = $$obj[pug_index2];
pug_html = pug_html + "\u003Ca" + (" class=\"suggest__tag suggest__item tag\""+pug.attr("href", tag.url, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = tag.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
if (articles.length === 0 && users.length === 0 && tags.length === 0) {
pug_html = pug_html + "\u003Cdiv class=\"search_popup_sec\"\u003E\u003Cdiv class=\"search_popup_sec_head\"\u003EНичего не найдено\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "all_results_url" in locals_for_with ?
        locals_for_with.all_results_url :
        typeof all_results_url !== 'undefined' ? all_results_url : undefined, "all_tags_url" in locals_for_with ?
        locals_for_with.all_tags_url :
        typeof all_tags_url !== 'undefined' ? all_tags_url : undefined, "all_users_url" in locals_for_with ?
        locals_for_with.all_users_url :
        typeof all_users_url !== 'undefined' ? all_users_url : undefined, "articles" in locals_for_with ?
        locals_for_with.articles :
        typeof articles !== 'undefined' ? articles : undefined, "searchRegExpReplace" in locals_for_with ?
        locals_for_with.searchRegExpReplace :
        typeof searchRegExpReplace !== 'undefined' ? searchRegExpReplace : undefined, "tags" in locals_for_with ?
        locals_for_with.tags :
        typeof tags !== 'undefined' ? tags : undefined, "users" in locals_for_with ?
        locals_for_with.users :
        typeof users !== 'undefined' ? users : undefined));
    ;;return pug_html;};
module.exports = template;