import axios from 'axios'

const MIN_TIME = 2000; //ms минимум смотреть на пост

const SendScroll = () => {
  const id = $('.js-article-show').data('time-id')
  if (+id === 0) {
    return
  }
  // если (когда) будем менять роуты, этот будет легко найти
  const article_viewed_till_end_update_path = `/article/viewed-till-end/update/${id}`
  axios.post(article_viewed_till_end_update_path, {} ).then(()=>{}).catch((error)=>console.error(error))
}

const initViewTillEnd = () => {
  $(document).off('scroll.article-show');
  var $t = $('.js-article-show')
  if ($t.length === 0 || $t.data('type-id') === 3 || $t.data('id') === 0 ) return;
  var scroll_event;
  var sent = false;
  const checkScroll = () => {
    const $endOfArticle = $('.js-end-of-article');
    if($endOfArticle.length && $(window).scrollTop() + $(window).height() >= $endOfArticle.position().top && !sent) {
      SendScroll();
      sent = true;
    }
  }
  scroll_event = function (){
    checkScroll()
  }
  $(document).on('scroll.article-show', scroll_event);
  setTimeout(checkScroll, 2000)
}
const init = () => {
  initViewTillEnd()
}

$(document).on('turbolinks:load', init);
