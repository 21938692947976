import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ModalWindow.sass';

class ModalWindow extends PureComponent {
  render() {
    const { title, onClose, isCenter, headerWithLine, children } = this.props;
    return (
      <div
        className={classNames("ModalWindow", {
          "ModalWindow--Center": isCenter
        })}
      >
        <div className="ModalWindow__Main">
          {title && (
            <div className={classNames('ModalWindow__Title', { 'ModalWindow__Title--WithLine': headerWithLine })}>
              {title}
            </div>
          )}
          {children}
          <button type="button" tabIndex={0} className="ModalWindow__Close" onClick={onClose}>
            &times;
          </button>
        </div>
      </div>
    );
  }
}

ModalWindow.propTypes = {
  title: PropTypes.node,
  onClose: PropTypes.func,
  isCenter: PropTypes.bool,
  headerWithLine: PropTypes.bool,
};

export default ModalWindow;
