import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Modal from '~/editor2/components/Modal';
import ProgressCircle from '~/editor2/components/ProgressCircle';

const maxSizeFile = 50 * 1024 * 1024;

class ModalAddPresentation extends PureComponent {
  constructor (props) {
    super(props);

    this.handlerCloseModal = ::this.handlerCloseModal;
    this.handlerChangeFile = ::this.handlerChangeFile;

    this.state = {
      canAddFile: true,
      isLoading: false,
      progress: 0,
    };
  }

  handlerChangeFile(event) {
    const { onCompleteAdd, articleId } = this.props;
    const file = event.target.files[0];
    if (/\.(pdf|ppt|pptx)$/.test(file.name)) {
      if (file.size <= maxSizeFile) {
        this.setState({
          canAddFile: false,
          isLoading: true,
          progress: 0,
        }, () => {
          const formData = new FormData();
          formData.append('file', file, file.name);
          axios
            .post(`/api/v1/articles/${articleId}/presentations`, formData, {
              onUploadProgress: progressEvent => {
                const progress = progressEvent.loaded / progressEvent.total;
                this.setState({ progress });
              }
            })
            .then(response => {
              this.setState({
                canAddFile: true,
                isLoading: false,
                progress: 1,
              }, () => {
                onCompleteAdd && onCompleteAdd(response.data.url);
              });
            });
        });
      } else {
        alert("Документ должен быть меньше 50 МБ");
      }
    } else {
      alert("Документ должен быть в формате PDF, PPT, PPTX");
    }
  }

  handlerCloseModal() {
    const { onClose } = this.props;
    onClose && onClose();
  }

  render () {
    const { showModal } = this.props;
    const { canAddFile, isLoading, progress } = this.state;
    return (
      <Modal title="Загрузка документа" isOpen={showModal} onRequestClose={this.handlerCloseModal}>
        <div className="DocsUploader" contentEditable={false}>
          <div className="DocsUploader__Requirements">
            <div className="DocsUploader__RequirementsInner" style={{ opacity: isLoading? 0 : 1 }}>
              <p>Требования к документам:</p>
              <ul>
                <li>Файл не должен превышать 50 МБ</li>
                <li>Поддерживаемые типы файлов PDF, PPT, PPTX</li>
                <li>Документ не должен нарушать авторские права.</li>
              </ul>
            </div>
            {isLoading && (
              <div className="DocsUploader__Progress">
                <ProgressCircle className="DocsUploader__ProgressCircle" size={60} progress={progress} />
                <div className="DocsUploader__ProgressText">
                  {`Загружено: ${(+progress * 100).toFixed(1)}%`}
                </div>
              </div>
            )}
          </div>
          <div className="text-center">
            <label className="DocsUploader__Btn button button--prime" role="button">
              Выбрать файл
              {canAddFile && (
                <input
                  type="file"
                  accept=".pdf,.ppt,.pptx"
                  onChange={this.handlerChangeFile}
                />
              )}
            </label>
          </div>
        </div>
      </Modal>
    );
  }
}

ModalAddPresentation.propTypes = {
  articleId: PropTypes.number,
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onCompleteAdd: PropTypes.func,
};

export default ModalAddPresentation;
