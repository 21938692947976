import React from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import Crop from '~/editor/Crop';

export class CropCommunity extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      saving: false,
      error: false,
    };
  }

  handlerCrop = (crop) => {
    const { id, onCropStart, onCropEnd, onCropError } = this.props;
    onCropStart && onCropStart();
    this.setState({ saving: true, error: false });
    return axios.post(`/api/v1/categories/${id}/resize`, crop).then(resp => {
      this.setState({ saving: false, crop});
      onCropEnd && onCropEnd(resp.data);
    }).catch(err => {
      console.error(err);
      this.setState({ saving: false, error: true, crop});
      onCropError && onCropError();
    });
  }

  render() {
    const { image } = this.props;
    const {crop, saving, error } = this.state;
    return (
      <Crop
        aspect={9/5}
        onSubmit={this.handlerCrop}
        disabled={saving}
        crop={crop}
        image={image}
        error={error}
        autoUpdate
      />
    );
  }
}

CropCommunity.propTypes = {
  id: PropTypes.number,
  image: PropTypes.string,
  onCropStart: PropTypes.func,
  onCropEnd: PropTypes.func,
  onCropError: PropTypes.func,
};

export default function initCrop(el, props) {
  ReactDom.render(
    <CropCommunity {...props} />, el
  );
}
