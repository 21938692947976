import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './FormCheckbox.sass';

export function FormCheckbox(props) {
  const { label, className, ...restProps } = props;
  return (
    <label className={classNames("FormCheckbox", className)}>
      <input className="FormCheckbox__Input" {...restProps} type="checkbox" />
      <span className="FormCheckbox__Control" />
      {label && <span className="FormCheckbox__Label">{label}</span>}
    </label>
  );
}

FormCheckbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormCheckbox;
