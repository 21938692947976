import axios from 'axios';
function init() {
  $(document).on('click', '.js-subscribe-and-create-article', function(event) {
    event.preventDefault();
    const category_id = +$(this).data('id')
    axios.post('/subscription/themes/update', {
      category_id,
      check: 1,
    }).then(() => {
      window.location = `/articles/new?category_id=${category_id}`;
    })

  })
}
$(init)
