import './index.scss'
export function attachDatePicker(selector, onSelect) {
  return $(selector).attachDatepicker({
    showAnim: "",
    rangeSelect: false,
    changeYear: false,
    changeMonth: false,
    mandatory: true,
    dateFormat: "yy-mm-dd",
    maxDate: 0,
    showOtherMonths: true,
    onSelect
  })
}
