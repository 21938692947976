import './index.scss'

// window.show_search = function() {
//   $('#page_content').hide();
//   $('#header_search').show();
//   $('#search_content').show();
//   $('#after_header_links').hide()
// }
//
// window.hide_search = function() {
//   $('#page_content').show();
//   $('#search_content').hide();
//   $('#search_content').empty();
//   $('input#title').val("<%= @category_name %>").prop('disabled', false);
//   $('#after_header_links').show()
// }
