import './index.sass'
import axios from 'axios'
import flash from '~/blocks/flash'

function init() {
  const $root = $('.js-article-share');
  if ($root.length === 0) return;
  $root.on('click', function(event) {
    event.preventDefault()
    const $t = $(this)
    axios.get($t.attr('href')).then(resp => {
      window.showModal(resp.data)
    }).catch(err => {
      console.error(err)
    })
  })
  $(document).on('submit', '.js-article-shares-users-search', function (event) {
    event.preventDefault()
    const $t = $(this)
    axios.get($t.attr('action'), {params: {q: $('.js-article-shares-users-search-input').val()}}).then(resp => {
      $('#list_users').replaceWith(resp.data)
    }).catch(err => {
      console.error(err)
    })
  })
  $(document).on('submit', '#share_article', function (event) {
    event.preventDefault()
    axios.post($(this).attr('action'), $(this).serialize()).then(resp => {
      window.closeModal()
      flash({success: 'Вы поделилилсь записью'})
    }).catch(err => {
      console.error(err)
    })
  })
  $(document).on('click', '#list_users > .infinity_scroll > .pagination > a', function(event) {
    event.preventDefault()
    axios.get($(this).attr('href')).then(resp => {
      $('#search_in_modal').parent().html(resp.data)
    })
  })
  $(document).on('click', '.js-close-share-modal', function (event) {
    window.closeModal()
  })
}

$(document).on('turbolinks:load', init);
