import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';
import classNames from 'classnames';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import ru from 'date-fns/locale/ru';
import Cleave from 'cleave.js/react';

import { EditorContext } from '~/editor2/context';

import {FormCheckbox} from '../../../components/Form/FormCheckbox';
import {RCTooltipCustom} from '../../../components/RCTooltip';
import preview1 from './preview-1.png';
import preview2 from './preview-2.png';

import './QuizEdit.sass';
import './QuizEditDateTimeInput.sass';

export const QuizStyles = {
  Light: 1,
  Green: 2,
};

export const QuizFormat = {
  Anonymous: 1,
  Public: 2,
};

const bgs = [
  {
    imgSrc: preview1,
    value: QuizStyles.Light,
  },
  {
    imgSrc: preview2,
    value: QuizStyles.Green,
  },
];

const formats = [
  {
    label: 'Анонимный',
    value: QuizFormat.Anonymous,
  },
  {
    label: 'Публичный',
    value: QuizFormat.Public,
  },
];

const DateInput = React.forwardRef((props, ref) =>
  // console.log(props);
   (
    <Cleave {...props}/>
  )
);

const DateEndInput = (props) => {
  const {date, onChange} = props;
  const filterPassedTime = time => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };
  return (
    <div className="QuizEditDateTimeInput">
      <ReactDatePicker
        className="QuizEditDateTimeInput__Input QuizEditDateTimeInput__Input--Date"
        selected={date}
        locale={ru}
        dateFormat="dd.MM.yyyy"
        minDate={new Date()}
        filterTime={filterPassedTime}
        customInput={
          <DateInput
            options={{
              date: true,
              delimiter: '.',
              datePattern: ['d', 'm', 'Y'],
            }}
          />
        }
        placeholderText="ДД.ММ.ГГГГ"
        onChange={onChange}
      />
      <span className="QuizEditDateTimeInput__Devider"> в </span>
      <ReactDatePicker
        className="QuizEditDateTimeInput__Input QuizEditDateTimeInput__Input--Time"
        selected={date}
        locale={ru}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        minDate={new Date()}
        filterTime={filterPassedTime}
        customInput={
          <DateInput
            options={{
              time: true,
              timePattern: ['h', 'm']
            }}
          />
        }
        placeholderText="ЧЧ:ММ"
        onChange={onChange}
        timeCaption="Время"
      />
    </div>
  );
};

DateEndInput.propTypes = {
  date: PropTypes.any,
  onChange: PropTypes.func,
};

export class QuizEdit extends Component {
  static contextType = EditorContext;

  state = {
    isHideTooltipForQuestion: false,
    isShowError: false,
  }

  isAddedNewAnswer = false;

  lastAnswerRef = React.createRef();

  questionRef = React.createRef();

  componentDidMount() {
    const {blockProps, isFocusQuestionOnMount} = this.props;
    if (isFocusQuestionOnMount && this.questionRef && this.questionRef.current) {
      blockProps.setReadOnly(true);
      setTimeout(() => {
        this.questionRef.current.focus();
      });
    }
  }

  componentDidUpdate() {
    if (this.isAddedNewAnswer && this.lastAnswerRef && this.lastAnswerRef.current) {
      this.lastAnswerRef.current.focus();
    }
    this.isAddedNewAnswer = false;

    const { isOpenPubQuizModal } = this.context;
    if (isOpenPubQuizModal !== this.isOpenPubQuizModal && isOpenPubQuizModal) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowError: isOpenPubQuizModal,
      });
    }
    this.isOpenPubQuizModal = isOpenPubQuizModal;
  }

  /**
   * @param {Object} quizData
   * @param {number|undefined} quizData.id
   * @param {string|undefined} quizData.question
   * @param {number|undefined} quizData.format
   * @param {number|undefined} quizData.style
   * @param {string|undefined} quizData.date_end
   * @param {boolean|undefined} quizData.is_multi
   * @param {boolean|undefined} quizData.is_disallow_revote
   * @param {boolean|undefined} quizData.can_change_settings
   */
  updateQuiz = quizData => {
    const {onUpdateQuiz, quiz} = this.props;
    if (onUpdateQuiz) {
      onUpdateQuiz({
        ...quiz,
        ...quizData
      });
    }
    this.hideError();
  };

  handleClickAddAnswer = () => {
    const {onAddAnswer} = this.props;
    if (onAddAnswer) {
      onAddAnswer();
      this.isAddedNewAnswer = true;
    }
  };

  handleChangeStyle = (e) => {
    this.updateQuiz({
      style: +e.target.value
    });
  };

  handleChangeAnswer = (event, index) => {
    const {answers, onUpdateAnswer} = this.props;
    if (onUpdateAnswer) {
      onUpdateAnswer({
        ...answers[index],
        answer: event.target.value
      });
    }
    this.hideError();
  };

  handleRemoveAnswer = (index) => {
    const {answers, onDeleteAnswer, onUpdateAnswer} = this.props;

    if (answers.length > 2) {
      if (onDeleteAnswer) {
        onDeleteAnswer(answers[index]);
      }
    } else if (onUpdateAnswer) {
      onUpdateAnswer({
        ...answers[index],
        answer: '',
      });
    }
  };

  handleChangeDateEnd = date_end => {
    this.updateQuiz({
      date_end
    });
  };

  handleChangeFormat = format => {
    this.updateQuiz({
      format,
    });
  };

  handleChangeQuestion = event => {
    this.updateQuiz({
      question: event.target.value.slice(0, 250),
    });
  };

  handleFocusQuestion = () => {
    setTimeout(() => {
      this.setState({
        isHideTooltipForQuestion: true,
      });
    }, 100);
  }

  handleBlurQuestion = () => {
    this.setState({
      isHideTooltipForQuestion: false,
    });
  }

  handleClickPreviewBtn = () => {
    const { isCanPreview, onPreview } = this.props;
    if (isCanPreview) {
      onPreview && onPreview();
    } else {
      this.showError();
    }
  }

  showError() {
    this.setState({
      isShowError: true,
    });
  }

  hideError() {
    this.setState({
      isShowError: false,
    });
  }

  renderButtonAddingAnswers() {
    const {answers} = this.props;

    const isDisable = answers.length >= 10;

    const btn = (
      <button
        className="QuizEdit__AnswerAdd"
        onClick={this.handleClickAddAnswer}
        disabled={isDisable}
      >
        Добавить еще 1 ответ
      </button>
    );

    if (isDisable) {
      return (
        <RCTooltipCustom overlay="Можно добавить максимум 10 вариантов ответа" placement="bottom">
          <div>
            {btn}
          </div>
        </RCTooltipCustom>
      );
    }

    return btn;
  }

  renderAnswers() {
    const {answers} = this.props;
    const {isShowError} = this.state;
    return answers.map((answerItem, index) => (
      <div className="QuizEdit__Answer" key={index.toString()}>
        <input
          className={classNames("QuizEdit__AnswerInput", {
            'QuizEdit__AnswerInput--Error': isShowError && !answerItem.answer
          })}
          type="text"
          value={answerItem.answer}
          ref={this.lastAnswerRef}
          onChange={event => {
            this.handleChangeAnswer(event, index);
          }}
          placeholder={`Вариант ответа №${index + 1}`}
        />
        <RCTooltipCustom overlay="Удалить ответ" placement="bottom">
          <div
            className="QuizEdit__AnswerRemove"
            onClick={() => this.handleRemoveAnswer(index)}
          />
        </RCTooltipCustom>
      </div>
    ));
  }

  renderFormats() {
    const {quiz: {format, can_change_settings}} = this.props;
    return (
      <div className="QuizEdit__GroupInput" style={{width: '100%'}}>
        <div className="QuizEdit__GroupInputField">

          <div
            className={classNames("QuizEdit__Switcher", {
              "QuizEdit__Switcher--Disabled": !can_change_settings,
            })}
          >
            {formats.map((item) => (
              <div
                className={classNames("QuizEdit__SwitcherBtn", {
                  "QuizEdit__SwitcherBtn--Selected": format === item.value
                })}
                key={item.value}
                onClick={() => {
                  if (can_change_settings) {
                    this.handleChangeFormat(item.value);
                  }
                }}
              >
                {item.label}
              </div>
            ))}
          </div>

        </div>
      </div>
    );
  }

  renderDateEnd() {
    const {quiz: {date_end}} = this.props;
    const { isShowError } = this.state;
    return (
      <div className="QuizEdit__GroupInput">
        <div className="QuizEdit__GroupInputField">

          <div className={classNames("QuizEdit__DateEnd", { "QuizEdit__DateEnd--Error": isShowError && !date_end })}>
            <DateEndInput
              date={date_end}
              onChange={this.handleChangeDateEnd}
            />
          </div>

        </div>
      </div>
    );
  }

  renderBGStyles() {
    const {quiz: {style}} = this.props;
    return bgs.map((item) => (
      <div className="QuizEdit__BGItem" key={item.value}>
        <label className="QuizEdit__BG">
          <img className="QuizEdit__BGPreview" src={item.imgSrc} alt=""/>
          <span className="QuizEdit__BGInputWrap">
              <input
                className="QuizEdit__BGInput"
                type="radio"
                name="bg"
                value={item.value}
                checked={style === item.value}
                onChange={this.handleChangeStyle}
              />
              <span className="QuizEdit__BGControl"/>
            </span>
        </label>
      </div>
    ));
  }

  render() {
    const {
      onRemove,
      quiz: {
        question,
        is_multi,
        is_disallow_revote,
        can_change_settings,
        date_end
      },
      onDateEndShow,
      isShowEndDate,
      isCanPreview,
    } = this.props;

    const { isHideTooltipForQuestion, isShowError } = this.state;

    return (
      <div className="QuizEdit">
        <div className="QuizEdit__HeadWrap">
          <div className="QuizEdit__Head">Создание опроса</div>
          <RCTooltipCustom overlay="Удалить опрос" placement="bottom">
            <div className="QuizEdit__Close" onClick={onRemove}/>
          </RCTooltipCustom>
        </div>
        <div className="QuizEdit__QuestionWrap">
          <RCTooltipCustom
            overlay="Изменить название опроса"
            placement="bottom"
            isHide={isHideTooltipForQuestion}
          >
            <Textarea
              inputRef={this.questionRef}
              className={classNames("QuizEdit__QuestionInput", {
                "QuizEdit__QuestionInput--Error": isShowError && !question
              })}
              value={question || ''}
              placeholder="Тема опроса"
              onChange={this.handleChangeQuestion}
              onFocus={this.handleFocusQuestion}
              onBlur={this.handleBlurQuestion}
            />
          </RCTooltipCustom>
          <div className="QuizEdit__QuestionCounter">{question?.length || 0} / 250</div>
        </div>
        <div className="QuizEdit__Group">
          <div className="QuizEdit__GroupTitle">Варианты ответа</div>
          <div className="QuizEdit__GroupFields">
            {this.renderAnswers()}
            {this.renderButtonAddingAnswers()}
          </div>
        </div>
        <div className="QuizEdit__Group">
          <div className="QuizEdit__GroupTitle">Тип опроса</div>
          <div className="QuizEdit__GroupFields">
            {this.renderFormats()}
          </div>
        </div>

        <div className="QuizEdit__Group">
          <div className="QuizEdit__GroupTitle">Настройки опроса</div>
          <div className="QuizEdit__GroupCheckRow">
            <FormCheckbox
              label="Выбор нескольких вариантов"
              disabled={!can_change_settings}
              checked={is_multi}
              onChange={e => {
                this.updateQuiz({
                  is_multi: e.target.checked
                });
              }}
            />
          </div>
          <div className="QuizEdit__GroupCheckRow">
            <FormCheckbox
              label="Запретить отмену голоса"
              disabled={!can_change_settings}
              checked={is_disallow_revote}
              onChange={e => {
                this.updateQuiz({
                  is_disallow_revote: e.target.checked
                });
              }}
            />
          </div>
          <div className="QuizEdit__GroupCheckRow">
            <FormCheckbox
              label="Ограниченное время голосования"
              disabled={!can_change_settings}
              checked={isShowEndDate}
              onChange={e => {
                onDateEndShow(e.target.checked);
                this.handleChangeDateEnd(null);
              }}
            />
          </div>
          {
            isShowEndDate && (
              <div className="QuizEdit__GroupFields">
                <div className="QuizEdit__GroupCheckRow">
                  {this.renderDateEnd()}
                </div>
              </div>
            )
          }

        </div>

        <div className="QuizEdit__Group">
          <div className="QuizEdit__GroupTitle">Выбор фона</div>
          <div className="QuizEdit__GroupFields">
            <div className="QuizEdit__Backgrounds">
              {this.renderBGStyles()}
            </div>
          </div>
        </div>
        <div className="QuizEdit__Group">
          <RCTooltipCustom
            overlay={
              <>
                Чтобы перейти к предпросмотру, проверьте<br/>
                заполнение темы опроса, укажите более 1-го<br/>
                варианта ответа,
                { !(!!date_end || !isShowEndDate) && <> выберите время голосования <br/></> }
                и выберите формат опроса<br/>
                (анонимный или публичный)
              </>
            }
            placement="top"
            isHide={isCanPreview}
          >
            <button
              className="button button--outline2 button--lg button--wide"
              onClick={this.handleClickPreviewBtn}
            >
              Предпросмотр
            </button>
          </RCTooltipCustom>
        </div>
      </div>
    );
  }
}

QuizEdit.propTypes = {
  isFocusQuestionOnMount: PropTypes.bool,
  isShowEndDate: PropTypes.bool,
  isCanPreview: PropTypes.bool,
  blockProps: PropTypes.any,
  quiz: PropTypes.any,
  answers: PropTypes.any,
  onPreview: PropTypes.func,
  onRemove: PropTypes.func,
  onUpdateQuiz: PropTypes.func,
  onUpdateAnswer: PropTypes.func,
  onDeleteAnswer: PropTypes.func,
  onAddAnswer: PropTypes.func,
  onDateEndShow: PropTypes.func,
};

export default QuizEdit;
