import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames';
import './CustomCheckbox.sass';

class CustomCheckbox extends PureComponent {
  render () {
    const { className, ...props } = this.props;
    return (
      <label className={classNames('CustomCheckbox', className)}>
        <input className="CustomCheckbox__Input" type="checkbox" {...props} />
        <span className="CustomCheckbox__Control" />
      </label>
    );
  }
}

CustomCheckbox.propTypes = {
  className: PropTypes.string,
};

export default CustomCheckbox;
