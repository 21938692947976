import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from 'react-popover';

import './SizePopover.sass';

import IconContains from './icon-contains.r.svg';
import IconFloat from './icon-float.r.svg';
import IconFullScreen from './icon-full-screen.r.svg';
import IconFullWidth from './icon-full-width.r.svg';

export const Sizes = {
  FLOAT: 'FLOAT',
  CONTAINS: 'CONTAINS',
  FULL_WIDTH: 'FULL_WIDTH',
  FULL_SCREEN: 'FULL_SCREEN',
};

const SizesIcons = {
  [Sizes.FLOAT]: IconFloat,
  [Sizes.CONTAINS]: IconContains,
  [Sizes.FULL_WIDTH]: IconFullWidth,
  [Sizes.FULL_SCREEN]: IconFullScreen,
};

class SizePopover extends PureComponent {
  handlerClickItem(size) {
    const { onChange } = this.props;
    onChange && onChange(size);
  }

  render() {
    const { selected, sizes, ...props } = this.props;
    return (
      <Popover
        appendTarget={document.body}
        body={(
          <div className="SizePopover">
            {Object.values(sizes).map((size) => {
              const Icon = SizesIcons[size];
              return (
                <div
                  className={classNames('SizePopover__Item', {
                    'SizePopover__Item--Active': selected === size,
                  })}
                  key={size}
                  onClick={() => this.handlerClickItem(size)}
                >
                  <Icon className="SizePopover__Icon" />
                </div>
              );
            })}
          </div>
        )}
        style={{
          zIndex: 10000,
        }}
        preferPlace="above"
        {...props}
      />
    );
  }
}

SizePopover.propTypes = {
  selected: PropTypes.oneOf([Sizes.CONTAINS, Sizes.FLOAT, Sizes.FULL_WIDTH, Sizes.FULL_SCREEN]),
  sizes: PropTypes.arrayOf(PropTypes.oneOf([Sizes.FLOAT, Sizes.CONTAINS, Sizes.FULL_WIDTH, Sizes.FULL_SCREEN])),
  onChange: PropTypes.func,
};

SizePopover.defaultProps = {
  selected: Sizes.CONTAINS,
  sizes: [Sizes.FLOAT, Sizes.CONTAINS, Sizes.FULL_WIDTH, Sizes.FULL_SCREEN],
};

export default SizePopover;
