import React from 'react'
import PropTypes from 'prop-types';
import { initPdf, loadPdfjs } from "~/presentation/loader"

class Presentation extends React.PureComponent {
  componentDidMount() {
    this.loadPresentation();
  }

  componentDidUpdate() {
    this.loadPresentation();
  }

  loadPresentation() {
    const { src } = this.props;
    loadPdfjs().then((render) => {
      initPdf(render, $(this.el), src)
    });
  }

  render() {
    return <div ref={(el) => { this.el = el }} />
  }
}

Presentation.propTypes = {
  src: PropTypes.string,
}

export default Presentation;
