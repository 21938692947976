import './index.sass';

export default function anchor() {
  const $anchor = $('.anchor');

  $(window).on('scroll', function(e){
    e.preventDefault();
    if($(window).scrollTop() > 300) {
      $anchor.addClass('anchor--show');
    }else {
      $anchor.removeClass('anchor--show')
    }
  });

  $anchor.on('click', function(e){
    e.preventDefault();
    $('html, body').animate({
      'scrollTop':   0
    }, 1000);
  })
};
