import './info.sass'
import './info-wrap.sass'
import './info-menu.sass'
import './info-cotent.sass'

import './help-info-menu'

import axios from 'axios';
import flash from '~/blocks/flash';

function init() {
  $('.js-info-support-form').on('submit', function(event) {
    event.preventDefault();
    $(this).find('.js-info-support-form-submit').attr("disabled", true)
    axios.post($(this).attr('action'), $(this).serialize()).then(resp => {
      flash({success: 'Сообщение отправлено'})
      $(this).find('.js-info-support-form-textarea').val('')
    }).catch(err => {
      flash({error: 'Ошибка отправки'})
    }).then(() => {
      $(this).find('.js-info-support-form-submit').attr("disabled", false)
    })
  })
}

document.addEventListener('turbolinks:load', init)
