window.onYouTubeIframeAPIReady = () => {
  console.log("4 onYouTubeIframeAPIReady");

  $(".youtube-video").each(function() {
    var $t = $(this)
    var sent = false;
    var sending = false;

    function sendViewedTillEnd(articleId) {
      console.log("local video is sending message: article " + articleId + " is viewed till end");
      sending = true;
      $.ajax({
        type: "post",
        url: "/article/viewed-till-end/update/" + articleId,
        data: {},
        success: function() {
          sent = true;
        },
        error: function(error) {
          console.error(error)
          sending = false;
        }
      });
    }

    function yt_onPlayerStateChange(e) {
      console.log("player state change", $t, e, e.target.getDuration(), e.target.getCurrentTime())
      if ( !sent && !sending && (e.target.getCurrentTime() / e.target.getDuration() > 0.95) ) {
        var articleId = $t.data('article-id')
        sendViewedTillEnd(articleId)
      }
    }

    new YT.Player($t[0], {
      height: $t.data('height'),
      width: $t.data('width'),
      videoId: $t.data("id"),
      events: {
          'onStateChange': yt_onPlayerStateChange
      }
    });
  })
}
