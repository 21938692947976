import './info-popup.sass'
import axios from 'axios';

export default ($popupRoot) => {

  $.magnificPopup.open({
    items: {
      src: $popupRoot,
      type: 'inline'
    }
  });
  axios.post('/users/popup_watched', {kind: 'profile'})

}
