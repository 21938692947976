import './index.scss';
import axios from 'axios';
import flash from '~/blocks/flash';

export default () => {
  let $failBtn = $('.js-refused');
  let $refusedBtn = $('.moderation__refused');
  let $commentBox = $('.moderation__comment');
  let $moderationSubmit = $('.moderation__submit');
  $failBtn.each(function (idx, item) {
    $(item).on('click', function (e) {
      e.preventDefault();
      $($commentBox[idx]).addClass('moderation__show');
    })
  });
  $refusedBtn.each(function (idx, item) {
    $(item).on('click', function (e) {
      e.preventDefault();
      $($commentBox[idx]).removeClass('moderation__show');
    })
  });

  $moderationSubmit.on('click', function (e) {
    e.preventDefault();
    let value = $(this).closest('.moderation__comment').find('.moderation__area').val();
    let id = $(this).closest('.moderation__elem').find('.moderation__box').attr('data-id');
    const params = {
      article_reject: {
        content: value,
      },
    };
    axios.post(`/api/v1/articles/${id}/rejects`, params).then(resp => {
      $(`.moderation__box[data-id="${resp.data.id}"]`).parent().remove()
      $('#articles_on_moderation_count').html(""+((+$('#articles_on_moderation_count').text()) - 1))
      flash({success: resp.data.published_message})
    }).catch(err => {
      flash({error: err.response.data.error})
      console.error('error',err);
    });
  });

  $('.dropdown__menu').click(function () {
    $('.left_menu').slideToggle('show');
    $('.dropdown__img').toggleClass('rotate');
  });
  $('.dropdown__img').click(function () {
    $('.left_menu').slideToggle('show');
    $('.dropdown__img').toggleClass('rotate');
  });
};
