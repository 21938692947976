import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import Modal from '~/editor2/components/Modal';
import './ModalQuizPub.sass';

class ModalQuizPubWhithoutDescr extends PureComponent {
  render() {
    const {isOpen, onClose} = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        title={<>Нельзя создавать материал<br />только с опросом</>}
        portalClassName="ModalQuizPub"
      >
        <div className="ModalQuizPub__Content">
          К опросу должна быть написана какая-либо подводка.
        </div>
      </Modal>
    );
  }
}

ModalQuizPubWhithoutDescr.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ModalQuizPubWhithoutDescr;
