const DataTransfer = require('fbjs/lib/DataTransfer.js');

const { getHTML } = DataTransfer.prototype;

DataTransfer.prototype.getHTML = function getHTMLOveride() {
  const html = getHTML.call(this);

  return html && html.normalize ? html.normalize() : html;
};

module.exports = DataTransfer;
