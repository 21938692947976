import "./index.scss"
import './search'

$(document).on('turbolinks:load', function() {
  const $topPanel = $('#top_panel');
  const $burger = $('.header__burger');

  if ($('#create-popup').length) {
    $('.js-add-material').on('click', (e) => {
      e.stopPropagation();
      e.preventDefault();
      $.magnificPopup.open({
        type: 'inline',
        showCloseBtn: false,
        fixedBgPos: true,
        fixedContentPos: true,
        modal: true,
        items: {
          src: '#create-popup'
        }
      });
    });
  }

  $burger.on('click', function () {
    $('#after_header_menu').slideToggle('medium', function () {
      if ($(this).is(':visible')) {
        $('html').addClass('no_scroll');
      }
    }).css('display', 'flex');
    $(this).toggleClass('header__burger--active');
  });

  function topPanel() {
    var start_block_position = $topPanel.offset().top;
    var height_block = $topPanel.outerHeight();


    if (!$topPanel.length) return
      $(window).on('scroll', function () {
        var position = $(window).scrollTop();
        if (position > start_block_position) {
          $topPanel.addClass('top-panel__fixed');
          $('#header').css("margin-bottom", height_block)

        } else {
          $topPanel.removeClass('top-panel__fixed');
          $('#header').css("margin-bottom", "0");
        }
      });

  }

  $topPanel.length && topPanel();
});



