import './user-profile.sass';
import axios from 'axios';
import flash from '~/blocks/flash';

function initLoadMore() {
  $('.js-profile-show-more-btn').on('click.load_more').on('click.load_more', function(event) {
    event.preventDefault();
    const $t = $(this);
    const html = $t.html();
    axios.get($t.attr('href')).then(resp => {
      $t.closest('.js-profile-load-more-block').find('.js-profile-list').append(resp.data);
      const next = resp.headers['x-next-page-url'];
      if (next && next !== "") {
        $t.attr('href', next);
      } else {
        $t.remove();
      }
    }).catch(err => {
      console.error(err);
      flash({error: 'Произошла ошибка'});
    }).then(() => $t.html(html));
  });
}

export function init() {
  let idTimeout = null;
  $(document).off('click.subscribe').on('click.subscribe', '.js-subscribe', function (event) {
    event.preventDefault();
    const $t = $(event.target);
    $t.attr('disabled', true);
    clearTimeout(idTimeout);
    $('body > .alert').remove();
    axios.post(event.target.href).then(() => {
      flash({success: 'Подписка оформлена'});
      $t.hide().attr('disabled', false);
      $t.siblings('.js-unsubscribe').show();
      idTimeout = setTimeout(() => $('body > .alert').remove(), 1500);
    }).catch(err => {
      console.error(err);
      flash({error: 'Произошла ошибка'});
    });
  });
  $(document).off('click.unsubscribe').on('click.unsubscribe', '.js-unsubscribe', function (event) {
    event.preventDefault();
    const $t = $(event.target);
    $t.attr('disabled', true);
    clearTimeout(idTimeout);
    $('body > .alert').remove();
    axios.delete(event.target.href).then(() => {
      flash({success: 'Отписка оформлена'});
      $t.hide().attr('disabled', false);
      $t.siblings('.js-subscribe').show();
      idTimeout = setTimeout(() => $('body > .alert').remove(), 1500);
    }).catch(err => {
      console.error(err);
      flash({error: 'Произошла ошибка'});
    });
  });
  const $showMoreTitle = $('.user-profile__job-show');
  const $bodyJobBlock = $('.user-profile__job-wrap');
  const $hideMoreTitle = $('.user-profile__job-hide');
  $showMoreTitle.on('click', function () {
    $showMoreTitle.hide();
    $bodyJobBlock.show();
    $hideMoreTitle.show()
  });
  $hideMoreTitle.on('click', function () {
    $showMoreTitle.show();
    $bodyJobBlock.hide();
    $hideMoreTitle.hide()
  });
  initLoadMore();
}

$(document).on('turbolinks:load', init);
