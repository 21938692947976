import './edit-profile.sass';
import './popup-upload.sass';
import tplPopup from './popup-upload.pug';
const getCounterText = (length, maxLength) => `<span>${length}</span> / ${maxLength}`;

const ReactLoader = () => import(/* webpackChunkName: "react" */ 'react');
const ReactDOMLoader = () => import(/* webpackChunkName: "react-dom" */ 'react-dom');
const AvatarCropLoader = () => import(/* webpackChunkName: "avatar-crop" */ './avatar-crop');

let reactDom = null;

const getImage = (img, {x, y, width, naturalWidth}) => `
  <div class="edit-profile__avatar">
    <div class="edit-profile__avatar-new-img" style="background-image: url('${img}');  background-size: 100%"></div>
  </div>
`;

/**
 * @param {jQuery} $root
 */
export default ($root) => {
  $root.each(function () {
    const $thisEditProfile = $(this);
    const $aboutWrap = $thisEditProfile.find('.edit-profile__self-about');
    const $inputTextAbout = $thisEditProfile.find('.edit-profile__text');
    const $textAboutLength = $thisEditProfile.find('.edit-profile__counter');
    const $inputAvatar = $thisEditProfile.find('.edit-profile__input-avatar');
    const $loadAvatar = $thisEditProfile.find('.edit-profile__placeholder-upload');

    let $avatar = $thisEditProfile.find('.edit-profile__avatar');
    const $avatarClone = $avatar.clone();

    let AvatarCropApp = null;

    $loadAvatar.on('click', function () {
      $.magnificPopup.open({
        items: {
          src: '<div id="react-crop"></div>',
          type: 'inline',
        },
        showCloseBtn: false,
        callbacks: {
          beforeOpen(){
            $('html').css({
              height: '100%',
              overflow: 'hidden'
            })
          },

          open(){
            Promise.all([
              ReactLoader(),
              ReactDOMLoader(),
            ]).then(([{default: React}, {default: ReactDOM}]) => {
              reactDom = ReactDOM;
              AvatarCropLoader().then(({default: AvatarCrop}) => {
                AvatarCropApp = React.createElement(AvatarCrop, {
                  onCompleteResize: (photo, crop) => {
                    // TODO:
                    console.log('from outside',photo, crop);
                    $('.edit-profile__avatar').replaceWith(getImage(photo, crop));
                    $loadAvatar.append('<input type="hidden" name="user[commit_avatar]" value="1"></input>')
                    $.magnificPopup.close();
                  }
                }, null);
                ReactDOM.render(AvatarCropApp, document.getElementById('react-crop'));
                console.log('poup avatar', AvatarCropApp);
              })
            })
          },

          close() {
            reactDom.unmountComponentAtNode(document.getElementById('react-crop'));
          },

          afterClose(){
            $('html').css({
              height: '',
              overflow: ''
            })
          },
        }
      })
    });

    const resetImage = () => {
      const $oldAvatar = $avatarClone.clone();
      $avatar.replaceWith($oldAvatar);
      $avatar = $oldAvatar;
      $loadAvatar.css('opacity', '');
    }

    const clearInput = () => {
      $inputAvatar.val("")
      resetImage();
    }

    $inputAvatar.on('change', (e) => {
      const files = e.target.files;
      if (files.length) {
        const file = files[0];

        if (!/(png|jpeg|jpg)/.test(file.type)) {
          alert('Формат должен быть: JPG, JPEG, PNG')
          clearInput();
          return;
        }

        // if (file.size > (200 * 1024)) {
        //   alert('Объем файла не должен превышать 200 кб.')
        //   clearInput();
        //   return;
        // }

        const $img = $('<img />');
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          let img = new Image();
          img.src = e.target.result;

          img.onload = function () {
            // if (this.width < 181 && this.height < 181) {
              $img.attr('src', e.target.result);
              $img.attr('class', $avatar.attr('class'));
              $avatar.replaceWith($img);
              $avatar = $img;
              $loadAvatar.css('opacity', 0.8);
              $.magnificPopup.close();
            // } else {
            //   alert("Размер изображения не должен превышать 181x181");
            //   clearInput();
            // }
          }
        }
        fileReader.readAsDataURL(file);
      } else {
        resetImage();
      }
    });

    const maxLength = $inputTextAbout.attr('maxlength');
    const updateCounterText = () => $textAboutLength.html(getCounterText($inputTextAbout.val().length, maxLength));
    const checkEmptyText = () => {
      if ($inputTextAbout.val().length) {
        $aboutWrap.addClass('edit-profile__self-about--not-empty');
      } else {
        $aboutWrap.removeClass('edit-profile__self-about--not-empty');
      }
    }
    const updateHeightTextAbout = () => {
      $inputTextAbout.height('').height();
      $inputTextAbout.outerHeight($inputTextAbout[0].scrollHeight + 2);
    }

    updateCounterText();
    checkEmptyText();
    updateHeightTextAbout();

    $(window).resize(updateHeightTextAbout)

    $inputTextAbout.on('input', () => {
      updateCounterText();
      checkEmptyText();
      updateHeightTextAbout();
    });

    $inputTextAbout.on('focus', function () {
      $aboutWrap.addClass('edit-profile__self-about--focus');
    });

    $inputTextAbout.on('blur', function () {
      $aboutWrap.removeClass('edit-profile__self-about--focus');
    });
  })
}
