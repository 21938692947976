import axios from 'axios';
import debounce from 'lodash-es/debounce';
import './infinity-scroll.sass';

const preloader = require('svg-inline-loader?!./preloader.inline.svg');

const animatePreloader = ($preloader) => {

  var version = detectIE();

  if (version) {
    const $circle = $preloader.find('circle');
    $circle.css({
      'stroke-dashoffset': 170
    });

    $circle.animate({
      'stroke-dashoffset': 0
    }, 1000, function() {
      $circle.animate({
        'stroke-dashoffset': -170
      }, 1000, () => animatePreloader($preloader))
    });
  } 

  function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }
}

const init = () => {
  const $infScroll = $('.infinity-scroll');
  const $window = $(window);
  const $preloader = $(preloader);

  animatePreloader($preloader);

  let isLoading = false;
  $window.off('scroll.infinity');
  if ($infScroll.length && $infScroll.find('.pagination').length) {
    const $container = $($infScroll.data('container'));
    $infScroll.prepend($preloader);
    $window
      .on('scroll.infinity', debounce(() => {
        if (isLoading) return;
        if ($infScroll.offset().top < $window.scrollTop() + $window.height() + $window.height() * 0.5) {
          isLoading = true;
          const $nextPageLink = $infScroll.find('.next_page');
          if ($nextPageLink.length && $nextPageLink.attr('href')) {
            axios.get($nextPageLink.attr('href')).then(resp => {
              const $html = $(resp.data);
              //window.lastHTML = $(resp.data);
              //console.log(resp.data, $infScroll.data('container'), $html.find($infScroll.data('container')).html());
              $infScroll.find('.pagination').replaceWith($html.find('.pagination'));
              $container.append($html.find($infScroll.data('container')).html());
              $(document).trigger('load-scroll');
              setTimeout(() => {
                isLoading = false;
              }, 300);
            });
          } else {
            $preloader.hide();
          }
        }
      }, 50));
  }
};

$(document).on('turbolinks:load', init);

export {
  preloader
};
