import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from './icon.r.svg';
import constants from './constants';
import insertDataBlock from '~/editor2/utils/draftjs/insertDataBlock';

class Button extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = ::this.onClick;
  }

  onClick() {
    const { onChange, editorState } = this.props;
    const data = {
      type: constants.PLUGIN_TYPE,
    };

    onChange(insertDataBlock(editorState, data));
  }

  render() {
    const { className } = this.props;
    return (
      <button
        className={className}
        type="button"
        onClick={this.onClick}
        title={constants.PLUGIN_TITLE_BUTTON}
      >
        <Icon />
      </button>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.any,
  editorState: PropTypes.any,
};

export default Button;
