import flash from '~/blocks/flash'
function init() {
  $('.js-badge-prohibited').on('click', function(event) {
    event.preventDefault()
    console.log("aaa")
    flash({error: 'Сообщество закрыто'})
  })
}

$('turbolinks:load', init)
