import React, { PureComponent, Fragment } from 'react';
import classNames from 'classnames';
import validUrl from 'valid-url';
import axios from 'axios';

import Icon from './icon.r.svg';
import constants from './constants';
import insertDataBlock from '~/editor2/utils/draftjs/insertDataBlock';
import { Sizes } from '~/editor2/components/Popovers/Size';
import Modal from '~/editor2/components/Modal';
import Dropzone from '~/editor2/components/Dropzone';
import PopoverError from '~/editor2/components/Popovers/Error';
import OrDivider from '~/editor2/components/OrDivider';
import {withEditorContentContext} from "~/editor2/components/EditorContent/EditorContentContext";

class Button extends PureComponent {
  constructor(props) {
    super(props);
    this.handlerClickBtn = ::this.handlerClickBtn;
    this.handleCloseModal = ::this.handleCloseModal;
    this.handlerUploadStart = ::this.handlerUploadStart;
    this.handlerUploadComplete = ::this.handlerUploadComplete;
    this.handlerChangeUrl = ::this.handlerChangeUrl;
    this.handlerClickAccept = ::this.handlerClickAccept;

    this.state = {
      showModal: false,
      canEdit: true,
      inputUrl: '',
      errorInput: false,
    };
  }

  handlerClickBtn() {
    const { onError } = this.props;
    if (onError) {
      onError({ category: '' });
    }
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  createBlock(image) {
    const { onChange, editorState } = this.props;
    const data = {
      type: constants.PLUGIN_TYPE,
      caption: '',
      size: Sizes.CONTAINS,
      isCarousel: false,
      images: [
        image,
      ],
    };
    onChange(insertDataBlock(editorState, data));
    this.setState({
      showModal: false,
      inputUrl: "",
      canEdit: true,
    });
  }

  handlerUploadStart() {
    this.setState({ canEdit: false });
  }

  handlerUploadComplete(image) {
    this.setState({
      canEdit: true,
    }, () => {
      this.createBlock(image);
    });
  }

  handlerChangeUrl(e) {
    this.setState({
      inputUrl: e.target.value,
      errorInput: false,
    });
  }

  handlerClickAccept() {
    const { inputUrl } = this.state;

    this.setState({
      errorInput: false,
      canEdit: false,
    });

    if (validUrl.isUri(inputUrl)) {
      axios
        .post(`/api/v1/articles/${this.props.id}/images`, { url: inputUrl })
        .then(({ data }) => {
          this.createBlock(data);
        })
        .catch((e) => {
          console.warn(e);
          this.setState({
            errorInput: true,
            canEdit: true,
          });
        });
    } else {
      this.setState({
        errorInput: true,
        canEdit: true,
      });
    }
  }


  render() {
    const {
      showModal, canEdit, inputUrl, errorInput,
    } = this.state;
    const { className } = this.props;
    // console.log('images plugin button props', this.props)
    return (
      <>
        <button
          className={className}
          type="button"
          onClick={this.handlerClickBtn}
          title={constants.PLUGIN_TITLE_BUTTON}
        >
          <Icon />
        </button>
        <Modal
          title="Загрузка изображения"
          isOpen={showModal}
          onRequestClose={this.handleCloseModal}
        >
          <div className="form-group">
            <Dropzone
              articleId={this.props.id}
              onUploadStart={this.handlerUploadStart}
              onUploadComplete={this.handlerUploadComplete}
              onError={() => this.setState({ canEdit: true })}
            />
          </div>
          <OrDivider />
          <div className="form-group">
            <label htmlFor="img-url">
              Вставить ссылку на изображение
            </label>
            <div className="form-control-group">
              <PopoverError error="Проверьте правильность вставленной ссылки" isShow={errorInput}>
                <input
                  className={classNames('form-control', { 'is-error': errorInput })}
                  id="img-url"
                  type="text"
                  placeholder="Ссылка"
                  disabled={!canEdit}
                  value={inputUrl}
                  onChange={this.handlerChangeUrl}
                />
              </PopoverError>
              <button
                className="button button--prime"
                type="button"
                disabled={!canEdit}
                onClick={this.handlerClickAccept}
              >
                Прикрепить изображение
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withEditorContentContext((props) =>
  <Button id={(props.article || {}).id} {...props} />
);
