import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from './icon.r.svg';
import constants from './constants';
import ModalAddPresentation from '~/editor2/components/Modal/ModalAddPresentation';
import insertDataBlock from '~/editor2/utils/draftjs/insertDataBlock';
import {withEditorContentContext} from "~/editor2/components/EditorContent/EditorContentContext";

const maxSizeFile = 50 * 1024 * 1024;

class Button extends PureComponent {
  constructor(props) {
    super(props);

    this.handlerClick = ::this.handlerClick;
    this.handlerCloseModal = ::this.handlerCloseModal;
    this.handlerCompleteAdd = ::this.handlerCompleteAdd;

    this.state = {
      showModal: false,
    };
  }

  handlerClick() {
    this.setState({ showModal: true });
  }

  handlerCloseModal() {
    this.setState({ showModal: false });
  }

  handlerCompleteAdd(fileUrl) {
    const { onChange, editorState } = this.props;
    const data = {
      type: constants.PLUGIN_TYPE,
      fileUrl,
    };
    onChange(insertDataBlock(editorState, data));
    this.setState({ showModal: false });
  }

  render() {
    const { className, article = {} } = this.props;
    const { showModal } = this.state;
    // console.log('docs plugin button props', this.props)
    return (
      <div
        onFocus={(e) => e.stopPropagation()}
        onBlur={(e) => e.stopPropagation()}
      >
        <button
          className={className}
          type="button"
          onClick={this.handlerClick}
          title={constants.PLUGIN_TITLE_BUTTON}
        >
          <Icon />
        </button>
        <ModalAddPresentation
          showModal={showModal}
          articleId={article.id}
          onClose={this.handlerCloseModal}
          onCompleteAdd={this.handlerCompleteAdd}
        />
      </div>
    );
  }
}

Button.propTypes = {
  article: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.any,
  editorState: PropTypes.any,
};

export default withEditorContentContext(Button);
