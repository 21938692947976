function init() {
  if ($('.js-admin-article-statistics').length === 0) return;
  setTimeout(function(){
    $('#stat_els_per_page').selectmenu({
      'width': 40,
      'position': {
        my: "center top+26",
        at: 'center center'
      },
      create: function(event, ui) {
        $('#' + $(event.target).attr('id') + '-button').removeClass('select');
      },
      open: function(event, ui) {
        var index = $(event.target).find("option:selected").index();
        $('#ui-id-' + (index + 1)).addClass('ui-select');
      },
      select: function(event, ui) {
        console.log(event, ui)
        $(event.toElement).closest('ul').find('.ui-select').removeClass('ui-select');
          $(event.toElement).addClass('ui-select');
            $('#' + $(event.target).attr('id') + '-button').addClass('select');
        $('#table').bootstrapTable('refresh', {
            size_: ui.item.value
        });
      }
    });
  }, 500);
  $('#table').bootstrapTable();

}

$(document).on('turbolinks:load', init);

