// import Dropzone from 'dropzone'
//
// Dropzone.autoDiscover = false;

window.fixPasswordFieldInMozilla = function() {
    if ($("#user_password").val() == '') {
        $("label[for=user_password]").show();
    } else {
        $("label[for=user_password]").hide();
    }
}

window.initFixedBlock = function() {
    if ($('.fixed_edit_block').length) {

    }
    if($('.leaderboard_author_list').length ) {
        if($(window).outerWidth() > 767) {
            var maxHeight = $(window).outerHeight() - $('.leaderboard_author_list').offset().top - 10;
            $('.leaderboard_author_list').css({'max-height': maxHeight});
        } else {
            $('.leaderboard_author_list').css({'max-height': 'none'});
            if($('.leader_author_item.current_user').length){
                var currentTop = $('.leader_author_item.current_user').offset().top;
                if(currentTop - $(window).outerHeight() + 60 > $(window).scrollTop()) {
                    $('#mobile_scroll_to_user').fadeIn(200);
                } else {
                    $('#mobile_scroll_to_user').fadeOut(200);
                }

                $(window).scroll(function(){
                    if(currentTop - $(window).outerHeight() + 60 > $(window).scrollTop()) {
                        $('#mobile_scroll_to_user').fadeIn(200);
                    } else {
                        $('#mobile_scroll_to_user').fadeOut(200);
                    }
                });
                $('#mobile_scroll_to_user a').on('touchend', function(){
                    $('html, body').animate({scrollTop:currentTop},500);
                })
            }
        }
    }
}

window.initTags = function(){
  $('.detail_edit_tag').each(function () {
    var $addTagContainer = $(this);
    var $addForm = $addTagContainer.find('form');
    $addForm.on('change submit', function (e) {
      e.preventDefault();
      $addForm.ajaxSubmit(window.formSubmitConfig());
    })
  })
}

window.initLables = function() {
    $('.form_item input, .form_item textarea').each(function() {
        if ($(this).val() != '') {
            $(this).closest('.form_item').addClass('active');
            if ($(this).hasClass('auto_height')) {
                resizeArea($(this), $(this).next('.textarea_hidden'), 40, 4000);
            }
        } else {
            $(this).closest('.form_item').removeClass('active');
        }
    });

    $('.form_item input, .form_item textarea').off('focus.slabels').on('focus.slabels', function() {
        $(this).closest('.form_item').addClass('active');
    });

    $('.form_item input, .form_item textarea').off('blur.slabels').on('blur.slabels', function() {
        if ($(this).val() != '') {
            $(this).closest('.form_item').addClass('active');
        } else {
            $(this).closest('.form_item').removeClass('active');
        }
    });

    $('.form_item input, .form_item textarea').off('input.slabels change.slabels keyup.slabels').on('input.slabels change.slabels keyup.slabels', function() {
        if ($(this).val() != '') {
            $(this).closest('.form_item').addClass('active');
            if ($(this).hasClass('auto_height')) {
                resizeArea($(this), $(this).next('.textarea_hidden'), 40, 4000);
            }
        } else {
            $(this).closest('.form_item').removeClass('active');
        }
    });
}

window.resizeArea = function(area, area_hidden, minHeight, maxHeight) {
    //autosize(area);
}


window.resizeInputWidth = function(area, area_hidden) {
    area_hidden.text(area.val().replace(/\s\s/g, ' &nbsp;'));
    area.width(area_hidden.outerWidth());
}


window.contenteditableInit = function(el) {
    var placeholder = el.data('placeholder');

    if (el.text() == '') {
        el.addClass('not_value');
    }

    el.focus(function() {
        if ($(this).hasClass('not_value')) {
            el.removeClass('not_value').text('');
        }
    });

    el.blur(function() {
        var form = $('form#' + el.data('for'));
        if (el.text() == '') {
            el.addClass('not_value');
        }
        form.ajaxSubmit(window.formSubmitConfig());
    });

    el.keydown(function(e) {
        var form = $('form#' + el.data('for')),
            key = event.keyCode || event.which;
        if (key === 13) {
            e.preventDefault();
            form.ajaxSubmit(window.formSubmitConfig());
            el.blur();
            window.getSelection().removeAllRanges();
        }
    });

    el.keyup(function(e) {
        var form = $('form#' + el.data('for'));
        form.find('input[type=text]').val(el.text());
    });
}

window.contenteditInit = function(el) {
    var placeholder = el.data('placeholder');

    if (el.text() == '') {
        el.addClass('not_value');
    }

    el.focus(function() {
        if ($(this).hasClass('not_value')) {
            el.removeClass('not_value').text('');
        }
    });

    el.blur(function() {
        if (el.text() == '') {
            el.addClass('not_value');
        }
    });

    el.keyup(function(e) {
        var input = (el.prev('input').length) ? el.prev('input') : el.prev('textarea');
        input.val(el.text());
    });
}

window.initToggleBlock = function(toggle_block) {
    toggle_block.on('click', function() {
        var link = $(this),
            block = $('.' + link.data('block')),
            showText = link.data('show'),
            hideText = link.data('hide');
        if (block.is(':hidden')) {
            link.text(hideText);
            block.show();
        } else {
            link.text(showText);
            block.hide();
        }
    })
}

window.autoSaveForm = function() {
    $(".detail_edit_ form input, .detail_edit_ form textarea, .detail_edit_materials form input, .detail_edit_materials form textarea").on('change', function(event) {
      $(this).closest('form').ajaxSubmit(window.formSubmitConfig());
    })
}

window.removeAutoSave = function() {
  $('.process_info').fadeOut(200, function() {
      $('.process_info').remove();
  })
}

window.initAutoSave = function() {
    if ($('.publish_wrap').length) {
        $('.process_info').remove();
        var el = '<div class="process_info">Изменения сохранены...</div>';
        $('.publish_wrap').append(el);
        $('.process_info').fadeIn(200);
    }
}

window.showModal = function(content, isNew = false) {

  var html = `<div class="modal ${isNew  ? 'modal--new-competiton': ''}"><div class="modal_wrap"><div class="modal_inner"><div class="modal_content">${content}</div></div></div><a href="javascript:;" class="close_modal"></a></div>`
  $('body').append(html)
  $('.modal').fadeIn(400, function () {
    $('.modal .modal_content #list_users, .modal .modal_content #list_articles')
      .css('max-height', $(window).outerHeight() - $('#search_in_modal')
      .outerHeight() * 2 - 60)
  })

  $(window).resize(function () {
    $('.modal .modal_content #list_users, .modal .modal_content #list_articles')
      .css('max-height', $(window).outerHeight() - $('#search_in_modal')
      .outerHeight() * 2 - 60)
  })

  $('.modal .close_modal').on('click', function () {
    closeModal()
  })
}



window.formSubmitConfig = function() {
  return {
    beforeSend: function() {
      initAutoSave()
    },
    success: function(e) {
      removeAutoSave()
    },
    error: function(e) {
      console.error("error", e)
      removeAutoSave()
    }
  }
}


window.showImgModal = function(img) {
    var html = '<div class="modal modal_img"><div class="modal_wrap"><div class="modal_inner"><div class="modal_content"><img src="'+img+'" alt="" /><a href="javascript:;" class="close_modal"></a></div></div></div></div>';
    $('html').addClass('no_scroll_desktop');
    $('body').append(html);
    $('.modal').fadeIn(400);
    $('.modal .close_modal').on('click', function() {
        $('html').removeClass('no_scroll_desktop');
        closeModal();
    });
}
window.showVideoModal = function(video) {
    var html = '<div class="modal modal_img"><div class="modal_wrap"><div class="modal_inner"><div class="modal_content"><div class="player"><video controls="controls" autoplay="autoplay" src="'+video+'"></video></div><a href="javascript:;" class="close_modal"></a></div></div></div></div>';
    $('html').addClass('no_scroll_desktop');
    $('body').append(html);
    $('.modal').fadeIn(400);
    $('.modal .close_modal').on('click', function() {
        $('html').removeClass('no_scroll_desktop');
        closeModal();
    });
}

window.closeModal = function() {
    $('.modal').fadeOut(300, function() {
        $('.modal').remove();
    });
}

window.showDialog = function(content) {
    var html = '<div class="modal_dialog"><div class="modal_dialog_wrap"><div class="modal_dialog_inner"><div class="modal_dialog_content">' + content + '</div></div></div><a href="javascript:;" class="close_modal"></a></div>';
    $('body').append(html);
    $('.modal_dialog').fadeIn(200);

    $('.modal_dialog .close_modal').on('click', function() {
        $('.modal_dialog').fadeOut(200, function() {
            $(this).remove();
        })
    });
}

window.closeNotice = function() {
    $('.alert').remove();
}

window.loader = function() {
    return '<div id="loader" style="width:29px;height:29px;transform:scale(0.6);-o-transform:scale(0.6);-ms-transform:scale(0.6);-webkit-transform:scale(0.6);-moz-transform:scale(0.6);"><div class="f_circleG" id="frotateG_01" style="left:0;top:12px;animation-delay:0.2295s;-o-animation-delay:0.2295s;-ms-animation-delay:0.2295s;-webkit-animation-delay:0.2295s;-moz-animation-delay:0.2295s;"></div><div class="f_circleG" id="frotateG_02" style="left:3px;top:3px;animation-delay:0.316s;-o-animation-delay:0.316s;-ms-animation-delay:0.316s;-webkit-animation-delay:0.316s;-moz-animation-delay:0.316s;"></div><div class="f_circleG" id="frotateG_03" style="left:12px;top:0;animation-delay:0.3925s;-o-animation-delay:0.3925s;-ms-animation-delay:0.3925s;-webkit-animation-delay:0.3925s;-moz-animation-delay:0.3925s;"></div><div class="f_circleG" id="frotateG_04" style="right:3px;top:3px;animation-delay:0.469s;-o-animation-delay:0.469s;-ms-animation-delay:0.469s;-webkit-animation-delay:0.469s;-moz-animation-delay:0.469s;"></div><div class="f_circleG" id="frotateG_05" style="right:0;top:12px;animation-delay:0.5455s;-o-animation-delay:0.5455s;-ms-animation-delay:0.5455s;-webkit-animation-delay:0.5455s;-moz-animation-delay:0.5455s;"></div><div class="f_circleG" id="frotateG_06" style="right:3px;bottom:3px;animation-delay:0.622s;-o-animation-delay:0.622s;-ms-animation-delay:0.622s;-webkit-animation-delay:0.622s;-moz-animation-delay:0.622s;"></div><div class="f_circleG" id="frotateG_07" style="left:12px;bottom:0;animation-delay:0.6985s;-o-animation-delay:0.6985s;-ms-animation-delay:0.6985s;-webkit-animation-delay:0.6985s;-moz-animation-delay:0.6985s;"></div><div class="f_circleG" id="frotateG_08" style="left:3px;bottom:3px;animation-delay:0.775s;-o-animation-delay:0.775s;-ms-animation-delay:0.775s;-webkit-animation-delay:0.775s;-moz-animation-delay:0.775s;"></div></div>';
}

// window.initPhotogrigPhotoDropzone = function(el) {
//     el.dropzone({
//         dictDefaultMessage: '<font>Перетащите изображение в окно для загрузки</font><em>Поддерживаемые форматы: *.jpg, *.bmp, *.png</em><em>Размер загружаемого изображения 750х420 пикс.</em>',
//         acceptedFiles: 'image/*',
//         thumbnailWidth: 750,
//         thumbnailHeight: 420,
//         maxFiles: 1,
//         previewTemplate: document.getElementById('dropzone_prw').innerHTML,
//         paramName: el.find('input[type=file]').attr('name'),
//         maxfilesexceeded: function(file) {
//             this.removeAllFiles();
//             this.addFile(file);
//         },
//         init: function() {
//             initAutoSave();
//             this.on("complete", function(file) {
//                 removeAutoSave();
//                 $('.add_photo_to_grid').prop('disabled', false);
//             });
//             this.on("uploadprogress", function(file, progress) {
//                 var backgroundBg = "background-image: none";
//                 if (progress < 50) {
//                     backgroundBg = 'linear-gradient(' + (90 + (360 * progress / 100)) + 'deg, transparent 50%, white 50%),linear-gradient(90deg, white 50%, transparent 50%)';
//                 } else if (progress == 50) {
//                     backgroundBg = 'linear-gradient(90deg, white 50%, transparent 50%)';
//                 } else if (progress > 50) {
//                     backgroundBg = 'linear-gradient(' + ((360 * progress / 100) - 90) + 'deg, transparent 50%, #389a52 50%),linear-gradient(90deg, white 50%, transparent 50%)';
//                 } else if (progress == 100) {
//                     backgroundBg = 'none';
//                 }
//                 $('[data-dz-uploadprogress]').css({
//                     'background-image': backgroundBg
//                 });
//                 $('.dz-upload-progress').text('Загружено: ' + progress + '%');
//             })
//         }
//     });
// }

window.initLongreadGrid = function(grid, index) {
    var count = grid.find('.photo_item').length,
        bullets = count,
        current = index;
    grid.find('.grid_bullets').empty();
    for (var i = 0; i < bullets; i++) {
        grid.find('.grid_bullets').append('<span class="bullet_' + i + '" data-index="' + i + '"></span>');
    };
    grid.find('.grid_bullets .current').removeClass('current');
    grid.find('.grid_bullets span.bullet_' + current).addClass('current');
    grid.find('.photo_item[data-i=' + current + ']').addClass('current');

    if (bullets > 1) {
        grid.find('.grid_arrows a.next_arrow').show();
    }

    grid.find('.grid_bullets span').on('click', function() {
        var index = $(this).data('index');
        grid.find('.grid_bullets .current').removeClass('current');
        grid.find('.photo_item').removeClass('current');
        $(this).addClass('current');
        grid.find('.photo_item[data-i=' + index + ']').addClass('current');
        if (index == 0) {
            grid.find('.grid_slider').css('margin-left', -390);
            grid.find('.grid_arrows .prev_arrow').hide();
        } else {
            grid.find('.grid_slider').css('margin-left', -780 * index - 390);
            grid.find('.grid_arrows .prev_arrow').show();
            if (index == bullets - 1) {
                grid.find('.grid_arrows .next_arrow').hide();
            } else {
                grid.find('.grid_arrows .next_arrow').show();
            }
        }

    });

    grid.find('.grid_arrows .prev_arrow').on('click', function() {
        grid.find('.grid_bullets span.current').prev().click();
    })
    grid.find('.grid_arrows .next_arrow').on('click', function() {
        grid.find('.grid_bullets span.current').next().click();
    })
}


window.initFormValidate = function(input) {
    if($(window).outerWidth() <= 767 ) {
        var form = input.closest('form'),
            errors = form.find('input[data-req]').length;
        form.find('input[data-req]').each(function(){
            if($(this).val() !='') {
                errors = errors - 1;
                $(this).closest('.form_item').addClass('active');
            } else {
                $(this).closest('.form_item').removeClass('active');
            }
        })
        if(errors == 0) {
            form.find('[type=submit]').addClass('active');
        } else {
            form.find('[type=submit]').removeClass('active');
        }
    }
}

window.checkLabels = function(){
  $('.textarea textarea').focus(function(){
    $(this).closest('.textarea').addClass('active');
  });

  $('.textarea textarea').blur(function(){
    if($(this).val() != '') {
      $(this).closest('.textarea').addClass('active');
    } else {
      $(this).closest('.textarea').removeClass('active');
    }
  });

  $('.contenteditable_comment').each(function() {
      var el = $(this);

        if (el.text() == '') {
          el.addClass('not_value');
      }

      el.focus(function() {
          if ($(this).hasClass('not_value')) {
              el.removeClass('not_value').text('');
          }
      });

      el.blur(function() {
          if (el.text() == '') {
              el.addClass('not_value');
          }
      });

      el.keyup(function(e) {
          var textarea = el.next('textarea');
          textarea.val(el.text());

          var parent = (el.closest('.add_comment_form').length) ? el.closest('.add_comment_form') : el.closest('.add_subcomment_form')
          if(el.text() != '') {
            parent.next('.add_comment_submit').slideDown(200);
          } else {
            parent.next('.add_comment_submit').slideUp(100);
          }
      });
  });
}
window.checkAddCategoryForm = () => {
	  var errors = 2,
	      form = $('form#create_category');
	  if(form.find('input#category_name').val() != '') {
	      errors--;
	  }
	  if(form.find('#category_bg').attr('data-ready') != '') {
	      errors--;
	  }
	  if(errors == 0) {
	      $('#add_new_category_btn').prop('disabled', false);
	  } else {
	      $('#add_new_category_btn').prop('disabled', true);
	  }
}
