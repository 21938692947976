import React, { PureComponent } from 'react';
import axios from 'axios';
import { preloader } from '~/blocks/infinity-scroll';
import { RCTooltip } from '~/editor2/components/RCTooltip';

import './MentionTooltip.sass';

export default class MentionComponent extends PureComponent {
  state = {
    user: undefined
  }

  toggleSubs = () => {
    const { user } = this.state;
    this.setState({
      isTogglingSubs: true
    });
    if (!user.is_follows) {
      axios.post(user.sub_link).then(() => {
        this.setState({
          isTogglingSubs: false,
          user: {
            ...user,
            subs: user.subs + 1,
            is_follows: true,
          }
        });
      });
    } else {
      axios.delete(user.unsub_link).then(() => {
        this.setState({
          isTogglingSubs: false,
          user: {
            ...user,
            subs: user.subs - 1,
            is_follows: false,
          }
        });
      });
    }
  }

  handlerChangeVisibleTooltip = (isVisilbe) => {
    if (isVisilbe) {
      this.getUserData();
    }
  }

  getUserData() {
    const { mention } = this.props;
    const userId = mention.get('id');
    return axios.get(`/api/v1/users/${userId}`).then(resp => {
      this.setState({
        user: resp.data
      });
    }).catch((e) => {
      console.error(e);
      alert('Ошибка! Не удалось получить информацию о пользователе.');
    });
  }

  renderTooltipContent(){
    const { mention } = this.props;
    const { user, isTogglingSubs } = this.state;
    if (!user) {
      return (
        <div className="MentionTooltip">
          <div dangerouslySetInnerHTML={{ __html: preloader }} />
        </div>
      );
    }
    return (
      <div className="MentionTooltip">
        <div className="MentionTooltip__Main">
          <div className="MentionTooltip__Avatar" dangerouslySetInnerHTML={{ __html: mention.get('avatar') }} />
          <div className="MentionTooltip__MainInfo">
            <a href={mention.get('url')} className="MentionTooltip__Name">{user.name}</a>
            <div className="MentionTooltip__Description">
              {user.about}
            </div>
          </div>
        </div>
        <div className="MentionTooltip__Bottom">
          <div className="MentionTooltip__Stats">
            <div className="MentionTooltip__Stat">
              <span>{user.pubs}</span> {user.pubs_text}
            </div>
            <div className="MentionTooltip__Stat">
              <span>{user.subs}</span> {user.subs_text}
            </div>
          </div>
          <div className="MentionTooltip__Action">
            <button
              className="button button--prime button--outline"
              onClick={this.toggleSubs}
              disabled={isTogglingSubs}
            >
              {!user.is_follows && "Подписаться"}
              {user.is_follows && "Отписаться"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  render () {
    const { mention, children } = this.props;
    return (
      <RCTooltip
        overlayClassName="MentionTooltipWrap"
        overlay={this.renderTooltipContent()}
        destroyTooltipOnHide
        placement="bottom"
        onVisibleChange={this.handlerChangeVisibleTooltip}
      >
        <a href={mention.get('url')}>
          {children}
        </a>
      </RCTooltip>
    );
  }
}
