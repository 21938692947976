var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (result) {
      pug_html = pug_html + "\u003Cli\u003E\u003Ca" + (" class=\"article__magnific\""+pug.attr("href", result, true, true)) + "\u003E\u003Cimg" + (pug.attr("src", result, true, true)) + "\u003E\u003C\u002Fa\u003E\u003Cspan class=\"article__delete\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fli\u003E";
    }.call(this, "result" in locals_for_with ?
        locals_for_with.result :
        typeof result !== 'undefined' ? result : undefined));
    ;;return pug_html;};
module.exports = template;