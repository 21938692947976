import './category.scss';
import axios from 'axios';
import flash from '~/blocks/flash';
import debounce from 'lodash-es/debounce';
import { initSubscriptionForm } from '~/pages/subscription';

function initCategoryOffer() {
  $('.js-new-category-offer').on('click', function (event) {
    event.preventDefault();
    axios.get($(this).attr('href')).then (resp => {
      window.showModal(resp.data)
      $('#new_category_offer').on('submit', function(event) {
        event.preventDefault();
        axios.post($(this).attr('action'), $(this).serialize()).then(resp => {
          window.closeModal();
          flash({success: 'Заявка принята'})
        }).catch(error => {
          flash({error: error.response.data.error})
        })
      })
    })
  })
}

function initSearch() {
  $('.js-categories-search').on('input', debounce(function(event){
    axios.get('/categories', {params: {q: $(this).val()}}).then(resp => {
      $('.js-categories-container').html(resp.data)
      initSubscriptionForm();
    }).catch(err => {
      console.error(err);
      flash({error: 'Произошла ошибка'})
    })
  }, 200));
}

function init() {
  initCategoryOffer()
  initSearch()
}

$(document).on('turbolinks:load', init)


export default ($root) => {
  const dropdown = $root.find('.category_tab--dropdown');
  const dropdownList = $root.find('.category__dropdown-list');
  const moderatorsDropdown = $root.find('.category__moderators-icon');
  const moderatorsList = $root.find('.category__moderators-wrapper');

  dropdown.on('click', function(e) {
    if ($(e.target).closest('.category__tab-drop-selected').length) return;
    const $this = $(this);
    $this.closest('.category__nav-scroll').toggleClass('category__nav-scroll--open');
    $this.toggleClass('category_tab--open');
    dropdownList.fadeToggle(300);
  });

  moderatorsDropdown.on('click', function () {
    $(this).toggleClass('category__moderators-icon--open');
    moderatorsList.slideToggle('show');
  })
}
