import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from 'react-popover';

import './Menu.sass';

class PopoverMenu extends PureComponent {
  render() {
    const { items, ...props } = this.props;
    return (
      <Popover
        className="PopoverMenu"
        place="below"
        appendTarget={document.body}
        body={
          <ul className="PopoverMenu__List">
            {items.map((item, ind) => (
              <li
                className={classNames('PopoverMenu__Item', {
                  'PopoverMenu__Item--Danger': item.modify === 'danger'
                })}
                key={ind.toString()}
                onClick={item.onClick}
              >
                {item.title}
              </li>
            ))}
          </ul>
        }
        style={{ zIndex: 1000 }}
        {...props}
      />
    );
  }
}

PopoverMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      modify: PropTypes.oneOf(['danger', 'simple'])
    }),
  ).isRequired,
};

export default PopoverMenu;
