import Masonry from 'masonry-layout'
function init(){
  if ($("#moderation_grid").length === 0) return;
  var widthBl = ($(window).outerWidth() < 1170) ? ($(window).outerWidth() - 30) : 540;
  $("#moderation_grid .bl_").addClass('ready').css('width', widthBl);
  var masonryModerationOptions = {
      itemSelector: '.bl_',
      gutter: 30,
      columnWidth: widthBl
    };
  new Masonry($('#moderation_grid')[0], masonryModerationOptions);
  $('.author_tooltip.top_t').tooltipster(initTooltip('top'));
}

document.addEventListener("turbolinks:load", init)
