import './index.sass';


export default ($root) => {
  const isShowPopup = window.localStorage.getItem('isShowPopup');
  const $closeButton = $root.find('.popup-main__close');
  if (!isShowPopup || isShowPopup !== '2') {
    window.localStorage.setItem('isShowPopup', '2');
    setTimeout(function () {
      $root.fadeIn();
    }, 500);
  }
  $closeButton.on('click', function () {
    $(this).closest('.popup-main').fadeOut();
  });
};
