import './banner-action.sass';
import './banner-info.sass';
import './banner-inform.sass';
import './banner-condition.sass';

export function cBanner() {


};

