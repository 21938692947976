import IconAt from './at.r.svg';
import IconBold from './bold.r.svg';
import IconH1 from './H1.r.svg';
import IconH2 from './H2.r.svg';
import IconItalic from './italic.r.svg';
import IconLink from './link.r.svg';
import IconOl from './ol.r.svg';
import IconQuote from './quote.r.svg';
import IconStrike from './strike.r.svg';
import IconUl from './ul.r.svg';
import IconUnderline from './underline.r.svg';

export default {
  IconAt,
  IconBold,
  IconH1,
  IconH2,
  IconItalic,
  IconLink,
  IconOl,
  IconQuote,
  IconStrike,
  IconUl,
  IconUnderline,
};
