import axios from 'axios';
import debounce from 'lodash-es/debounce';
import { setHeaders } from "~/axios-headers";

function init() {
  const adminFilter = document.querySelector('.js-admin-categories-filter');
  const form = document.querySelector('.js-admin-categories-search');
  const input = form && form.querySelector('.search__input');
  const container = document.getElementById('category_list');
  const showSearchBtn = document.querySelector('.filter_search_toggle');
  const closeSearchBtn = document.querySelector('.search__close');
  const searchContainer = document.querySelector('.search--category');
  if (!form || !input) {
    return;
  }
  const {CancelToken} = axios;
  let cancel;

  setHeaders();
  const request = debounce(function () {
    axios.get('/admin/panel/categories', {
      params: {q: input.value},
      cancelToken: new CancelToken(c => cancel = c),
    }).then(resp => {
      container.innerHTML = resp.data;
    }).catch(err => {
      if (!axios.isCancel(err)) {
        console.error(err);
        alert('Произошла ошибка.');
      }
    }).then(() => cancel = null);
  });
  const handler = function() {
    if (cancel) {
      cancel();
    }
    request();
  };

  input.addEventListener('input', debounce(handler, 200));
  form.addEventListener('submit', function (e) {
    e.preventDefault();
    handler();
  });

  showSearchBtn.addEventListener('click', function () {
    searchContainer.classList.add('show');
    adminFilter.classList.add('filter_hide');
    input.focus();
  });

  closeSearchBtn.addEventListener('click', function () {
    searchContainer.classList.remove('show');
    adminFilter.classList.remove('filter_hide');
  });
}

document.addEventListener('turbolinks:load', init);
