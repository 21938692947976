import linkifyIt from 'linkify-it';
import tlds from 'tlds';

const linkify = linkifyIt();
linkify.tlds(tlds);

/**
 * @param {string} txt
 * @returns {*[]|undefined}
 */
export function getLinks(txt) {
  const links = linkify.match(txt);
  if (typeof links !== 'undefined' && links !== null) {
    return links.map(({url}) => url);
  }
  return undefined;
}

/**
 * @typedef {Object} LinkItem
 * @property {string} schema
 * @property {number} index
 * @property {number} lastIndex
 * @property {string} raw
 * @property {string} text
 * @property {string} url
 */

/**
 * @param {string} txt
 *
 * @returns {LinkItem[]|null}
 */
export function getLinksMath(txt) {
  return linkify.match(txt);
}
