import axios from 'axios'
window.axios = axios;

export function setHeaders() {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector( 'meta[name=csrf-token]' ).content;
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
}

//export function resetAxiosHeaders() {
  //let instance = axios.create();
  //let headers = { headers: {} };
  //instance.interceptors.request.use(
    //(config) => (Object.assign(config, headers)),
    //(e) => Promise.reject(e));
  //return instance;
//}
