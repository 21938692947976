import axios from "axios";
import {localize} from '~/blocks/plot/utils'
import {attachDatePicker} from '~/blocks/datepicker'
import {initGraph} from '~/blocks/plot'
import {initTable} from '~/blocks/table'
import './index.sass'
import "./selectMenu"
import initArticlesPopular from './articlesPopular';
const article_views_data_admin_statistics_path = (options = {}) => {
  return `/admin/panel/statistics/article_views_data?${$.param(options)}`;
};

const activity_data_admin_statistics_path = (options = {}) => {
  return `/admin/panel/statistics/activity_data?${$.param(options)}`;
};

const sign_ins_data_admin_statistics_path = (options = {}) => {
  return `/admin/panel/statistics/sign_ins_data?${$.param(options)}`;
};

const admin_statistics_path = (kind, options={}) => {
  return `/admin/panel/statistics/${kind}_data?${$.param(options)}`;
}
const data_admin_statistics_path = (options = {}) => {
  return `/admin/panel/statistics/data${$.param(options)}`;
};

const formatSignIns = (v) => {
  const vs = v.split("-")
  return `${vs[2]}.${vs[1]}`
}

const formatItemSignIns = (datalabel) => {
  return (
    datalabel +
    " " +
    localize(datalabel, ["уникальный пользователь", "уникальных пользователя", "уникальных пользователей"])
  );
}


function initTables() {
  const $root = $(".js-admin-statistics");
  $(".table_03_my").bootstrapTable();
  $(".table_02_my").bootstrapTable();
  setTimeout(function() {
    attachDatePicker("#date_", function(date) { Turbolinks.visit("?date_where=" + date) })
    $("#calendar").click(function() { $("#date_").showDatepicker() });
  }, 500);
  initTable('stat_materials_by_categories')
};


function init() {
  if ($(".js-admin-statistics").length === 0) return;
  initTables();
  initGraph("article_views")
  initGraph("sign_ins", formatItemSignIns, formatSignIns)
  initGraph("activity", formatItemSignIns, formatSignIns)
  initArticlesPopular();
}

$(document).on("turbolinks:load", init);

