import React from 'react';
import PropTypes from 'prop-types';
import debounce from "lodash-es/debounce";

import { tinymce, contentStyle } from '~/tinymce';

const defaultPlugins = [
  'paste',
  'link',
  'lists',
  'placeholder',
  'autoresize',
  'textcolor',
];

const defaultToolbars = [
  ['undo', 'redo'],
  ['bold', 'italic', 'forecolor'],
  ['alignleft', 'aligncenter', 'alignright'],
  ['link'],
  ['bullist', 'numlist'],
  ['removeformat']
];

class TEditor extends React.Component {
  refTextarea = React.createRef()

  componentDidMount() {
    this.initTiny();
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    this.destroyTiny();
  }

  initTiny() {
    const { maxChars, onChange, plugins, toolbar } = this.props;

    // https://stackoverflow.com/questions/18111582/tinymce-4-links-plugin-modal-in-not-editable
    $(document).on('focusin.modal-tinymce', function(e) {
      if ($(e.target).closest(".mce-window").length) {
        e.stopImmediatePropagation();
      }
    });

    tinymce.init({
      skin: false,
      target: this.refTextarea.current,
      content_style: contentStyle.toString(),
      menubar: false,
      language: 'ru',
      statusbar: false,
      paste_as_text: true,
      plugins: [...(plugins || defaultPlugins), maxChars ? 'maxchars' : false].filter(Boolean).join(' '),
      maxChars,
      toolbar: [...(toolbar || defaultToolbars)].map(tools => tools.filter(Boolean).join(' ')).join(' | '),
      default_link_target: "_blank",
      autoresize_bottom_margin: 10,
      setup: (ed) => {
        this.editor = ed;
        ed.on('NodeChange', debounce(() => {
          onChange(ed.getContent());
        }, 50));
      }
    });
  }

  destroyTiny() {
    if (this.editor) {
      tinymce.remove(this.editor);
    }
    $(document).off('focusin.modal-tinymce');
  }

  resetTiny() {
    this.destroyTiny();
    this.initTiny();
  }

  render() {
    const { value, placeholder } = this.props;
    return (
      <textarea
        className="teditor"
        ref={this.refTextarea}
        value={value}
        placeholder={placeholder}
        onChange={() => {}}
      />
    );
  }
}

TEditor.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxChars: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  plugins: PropTypes.arrayOf(PropTypes.string),
  toolbar: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default TEditor;
