import { initGraph } from '~/blocks/plot'
import { localize } from '~/blocks/plot/utils'
const admin_user_statistics_time_data_path = (user_id) => {
  return `/admin/panel/users/${user_id}/statistics_time_data`
}
const admin_user_sign_ins_path = (user_id) => {
  return `/admin/panel/users/${user_id}/sign_ins`
}

const admin_user_statistics_data_path = (user_id) => {
  return `/admin/panel/users/${user_id}/statistics_data`
}

function drawGraph(data) {
  var labels = [],
    items = [];
  for (var i = data.length; i--;) {
    var date = data[i].date.split('-');
    labels.push(date[2] + '.' + date[1]);
    items.push(data[i].time);
  };

  var config_1 = {
    type: 'line',
    data: {
      labels: labels,
      datasets: [{
        backgroundColor: 'rgba(56, 154, 82, 0.16)',
        borderColor: '#389a52',
        data: items,
        fill: true,
        pointRadius: 6,
        pointHoverRadius: 6,
        pointBorderColor: "#389a52",
        pointBackgroundColor: "#ffffff",
        pointBorderWidth: 2,
        pointHoverBackgroundColor: "#389a52"
      }]
    },
    options: {
      responsive: true,
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 8,
          right: 8,
          top: 8,
          bottom: 8
        }
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: ''
          },
          ticks: {
            fontColor: "rgba(0, 0, 0, 0.38)",
            fontSize: 15,
            fontStyle: "normal",
            fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
            padding: 20,
            autoSkip: true,
            maxTicksLimit: (Math.floor(items.length / 2) < 18) ? Math.floor(items.length / 2) : 12
          }
        }],
        yAxes: [{
          display: true,
          afterFit: function(scale) {
            scale.width = 80 //<-- set value as you wish 
          },
          scaleLabel: {
            display: true,
            labelString: ''
          },
          gridLines: {
            display: false
          },
          ticks: {
            fontColor: "rgba(0, 0, 0, 0.38)",
            fontSize: 15,
            fontStyle: "normal",
            fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
            padding: 20,
            autoSkip: true,
            maxTicksLimit: 10
          }
        }]
      },
      tooltips: {
        backgroundColor: "#ffffff",
        titleFontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
        titleFontSize: 12,
        titleFontStyle: 'normal',
        titleFontColor: 'rgba(0, 0, 0, 0.54)',
        bodyFontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
        bodyFontSize: 12,
        bodyFontStyle: 'normal',
        bodyFontColor: 'rgba(0, 0, 0, 0.54)',
        callbacks: {
          title: function(tooltipItem, data) {
            var datalabel = data.datasets[0].data[tooltipItem[0].index];
            var label = data.labels[tooltipItem[0].index];
            return datalabel + ' ' + getNumEnding(datalabel, ['минута', 'минуты', 'минут']);
          },
          label: function(tooltipItem, data) {
            return '';
          }
        }
      }
    }
  };
  if (window.window.myLine_1 !== undefined) {
    window.myLine_1.destroy();
  }
  var ctx_1 = document.getElementById("graph_2").getContext("2d");
  window.myLine_1 = new Chart(ctx_1, config_1);
}

function getRoot() {
  return $('.js-admin-user-statistics')
}

function sendRequestTime() {
  const query = {
    query: {
      date_from_time: $('#date_from_time_field').val(),
      date_till_time: $('#date_till_time_field').val()
    }
  }

  $.ajax({
    url: admin_user_statistics_time_data_path(getRoot().data('selected-user-id')) + "?format=json&limit=50&offset=0&order=desc&date_from_time=" + $('#date_from_time_field').val() + "&date_till_time=" + $('#date_till_time_field').val(),
    type: 'GET',
    success: function(ans) {
      drawGraph(ans);
    }
  })
};
function init1() {
  const $root = getRoot()
  setTimeout(function() {
    $.ajax({
      url: admin_user_statistics_time_data_path($root.data('selected-user-id')) + `?date_where=${$root.data('where')}limit=50&offset=0&order=desc`,
      type: 'GET',
      success: function(ans) {
        drawGraph(ans);
      }
    });
    $('#date_from_time_field, #date_till_time_field').attachDatepicker({
      showAnim: '',
      rangeSelect: false,
      changeYear: false,
      changeMonth: false,
      mandatory: true,
      dateFormat: 'yy-mm-dd',
      maxDate: 0,
      showOtherMonths: true,
      onSelect: function(date) {
        sendRequestTime();
      }
    });
  }, 500)
}


function init2() {
  const $root = getRoot()
  initGraph("user_visits", (datalabel) => {
    return (
      datalabel +
      " " +
      localize(datalabel, ["раз", "раза", "раз"])
    )
  }, (datetime) => {
    return datetime.split("-").slice(1, 3).reverse().join('.')
  })
}

function getNumEnding(iNumber, aEndings) {
  var sEnding, i;
  iNumber = iNumber % 100;
  if (iNumber >= 11 && iNumber <= 19) {
    sEnding = aEndings[2];
  } else {
    i = iNumber % 10;
    switch (i) {
      case (1):
        sEnding = aEndings[0];
        break;
      case (2):
      case (3):
      case (4):
        sEnding = aEndings[1];
        break;
      default:
        sEnding = aEndings[2];
    }
  }
  return sEnding;
}


function init3() {
  setTimeout(function() {
    $('#filter_action_id').selectmenu({
      'position': {
        my: "center top+26",
        at: 'center center'
      },
      create: function(event, ui) {
        $('#' + $(event.target).attr('id') + '-button').removeClass('select');
      },
      open: function(event, ui) {
        var index = $(event.target).find("option:selected").index();
        $('#ui-id-' + (index + 1)).addClass('ui-select');
      },
      select: function(event, ui) {
        console.log(event, ui);
        $(event.toElement).closest('ul').find('.ui-select').removeClass('ui-select');
        $(event.toElement).addClass('ui-select');
        $('#' + $(event.target).attr('id') + '-button').addClass('select');
        $(event.target).closest('form').submit();
      }
    });

    $('#month_visit').selectmenu({
      'position': {
        my: "center top+26",
        at: 'center center'
      },
      create: function(event, ui) {
        $('#' + $(event.target).attr('id') + '-button').removeClass('select');
      },
      open: function(event, ui) {
        var index = $(event.target).find("option:selected").index();
        $('#ui-id-' + (index + 1)).addClass('ui-select');
      },
      select: function(event, ui) {
        $(event.toElement).closest('ul').find('.ui-select').removeClass('ui-select');
        $(event.toElement).addClass('ui-select');
        $('#' + $(event.target).attr('id') + '-button').addClass('select');
        var date_start = ui.item.value,
          finish_date = new Date(date_start),
          date_s = finish_date.getFullYear() + '-' + ('0' + (finish_date.getMonth() + 1)).slice(-2) + '-' + ('0' + finish_date.getDate()).slice(-2);
        finish_date.setMonth(finish_date.getMonth() + 1);
        var date_f = finish_date.getFullYear() + '-' + ('0' + (finish_date.getMonth() + 1)).slice(-2) + '-' + ('0' + finish_date.getDate()).slice(-2);
        $.ajax({
          url: admin_user_sign_ins_path(getRoot().data('selected-user-id')) + "?date_from=" + date_s + "&date_till=" + date_f + "&limit=50&offset=0&order=desc",
          type: 'GET',
          success: function(ans) {
            drawGraphSign(ans);
          }
        });
      },
    });

  }, 500);

  $('#date').change(function() {
    $('#calendar').prop("href", "?date_where=" + $('#date').val())
  });

  $('#date_from_field').change(function() {
    $("#filter_form").submit();
  });
  $('#date_till_field').change(function() {
    $("#filter_form").submit();
  });
  $('#filter_action_id').change(function() {
    $("#filter_form").submit();
  });
};




function init4() {
  $('#admin_user_statistic_time_data').on('mouseenter', function() {
    this.href = admin_user_statistics_time_data_path(getRoot().data('selected-user-id')) + "?export_xlsx=true&date_from_time=" + $('#date_from_time_field').val() + "&date_till_time=" + $('#date_till_time_field').val();
  })
};

function init5() {
  $('.table_user_events_all').bootstrapTable();

  $('.table_user_signins').bootstrapTable();



  // <full user statistic>: when we select filter by event name -> updates table
  $("#filter_action_id").on("selectmenuselect", function(event, ui) {
    var e = document.getElementById("filter_action_id");
    var selected_id = e.options[e.selectedIndex].value;
    sendRequestEventFilter(selected_id);
  });
}

function sendRequestEventFilter(selected) {
  const query = {
    query: {
      action_id: selected,
    }
  }
  $('.table_user_events_all').bootstrapTable('refresh', {
    url: admin_user_statistics_data_path(getRoot().data('selected-user-id')) + "?format=json&action_id=" + selected
  });
};

// <full user statistic>: attaches selected event name to export link
function init6() {
  $('#export_full_statistic').on('mouseenter', function() {
    var e = document.getElementById("filter_action_id");
    var selected_id = e.options[e.selectedIndex].value;
    this.href = admin_user_statistics_data_path(getRoot().data('selected-user-id')) + "?export_xlsx=true&action_id=" + selected_id;
  })
};

function init() {
  if (getRoot().length === 0) return;
  init1()
  init2()
  init3()
  init4()
  init5()
  init6()
}
$(document).on("turbolinks:load", init);
