import axios from 'axios';
import {attachDatePicker} from '~/blocks/datepicker'

const endPoint = '/admin/panel/statistics/articles_popular';
function params() {
  return {
    'q[published_at_gteq]': document.querySelector('.js-date_from_articles_popular_field').value,
    'q[published_at_lteq]': document.querySelector('.js-date_till_articles_popular_field').value,
  }
}

function fetchData() {
  axios.get(endPoint, {params: params()}).then(resp => {
    document.querySelector('.js-articles-popular-list').innerHTML = resp.data;
  })
}
export default function init() {
  const root = document.querySelector('.js-articles-popular');
  if (!root) {
    return;
  }
  attachDatePicker('.js-date_from_articles_popular_field, .js-date_till_articles_popular_field', fetchData);
}
