import axios from 'axios';
import debounce from 'lodash-es/debounce';
import './profile-navigation.sass';

/**
 * @param {jQuery} $root
 */
export default ($root) => {
  const $scrollContainer = $root.find('.profile-navigation__list');
  const $activeMenu = $root.find('.profile-navigation__link.is-active');
  if ($activeMenu.length) {
    $scrollContainer.scrollLeft($activeMenu.offset().left - window.innerWidth * 0.5 + $activeMenu.outerWidth() * 0.5);
  }

  const $search = $('.search--publication');
  const $form = $search.find('form');
  const $formInput = $search.find('.js-user-publication-search');
  const $spin = $search.find('.search__ajax');
  let cancelToken = null;
  const loadPublication = debounce(() => {
    cancelToken = axios.CancelToken.source();
    axios
      .get($form.attr('action'), {
        params: {
          q: $formInput.val(),
        },
        cancelToken: cancelToken.token
      })
      .then((response) => {
        $('#index_list').replaceWith($(response.data).find('#index_list'));
        $spin.hide();
      }).catch((err) => {
        console.warn(err);
        if (!axios.isCancel(err)) {
          $spin.hide();
          alert('Произошла ошибка. Попробуйте позже.');
        }
      });
  }, 300);

  $root.find('.profile-navigation__toggle-search').on('click', function () {
    $search.toggle();
    $search.find('.js-user-publication-search').focus();
  });

  $search.on('submit', function (e) {
    e.preventDefault();
    if (cancelToken) {
      cancelToken.cancel('cancel');
      cancelToken = null;
    }
    $spin.show();
    loadPublication();
  });

  $formInput.on('input', function () {
    $form.trigger('submit');
  });
};
