import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from '~/editor2/components/Modal';
import './ModalDonePublication.sass';
import SharePopup from '~/blocks/share-popup/SharePopup'

class ModalDonePublication extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowShare: false
    };
  }

  renderModerators() {
    const { article } = this.props;
    if (
      article && article.category
      && article.category
      && article.category.moderators
      && article.category.moderators.length
    ) {
      return article.category.moderators
        .map(moder => <a href={moder.url} key={moder.id}>{moder.name}</a>)
        .reduce((prev, curr) => [prev, ', ', curr]);
    }
    return (
      <span style={{ color: '#f00'}}>Модераторы в сообществе отсутсвуют.</span>
    );
  }

  render() {
    const { isShowShare } = this.state;
    const { article, isShow, onClose } = this.props;
    if (isShowShare) {
      return (
        <Modal
          isOpen={isShow}
          withoutWindow
        >
          <SharePopup id={article.id} onClose={onClose} onCancel={onClose} />
        </Modal>
      );
    }

    return (
      <Modal
        isOpen={isShow}
        onRequestClose={onClose}
      >
        <div className="ModalDonePublication">
          <div className="ModalDonePublication__Body">
            <div className="ModalDonePublication__Img"> </div>
            <h3 className="ModalDonePublication__Body-title">
              {article.in_sandbox && "Ожидайте модерацию"}
              {!article.in_sandbox && "Спасибо за публикацию"}
            </h3>
            {article.in_sandbox && (
              <p className="ModalDonePublication__Body-descr ModalWindow__Body-descr--padding">
                {article && article.user && article.user.fname}
                , ваша статья отправлена на модерацию! Вы можете отслеживать статус проверки материала в разделе
                {' '}
                <a href="/materials/moderation" className="bold-main-color">«На модерации»</a>.
                {' '}
                Модерация занимает 1-3 рабочих дня. При возникновении вопросов, вы можете связаться с модератором
                {' '}
                {this.renderModerators()}
              </p>
            )}
            {!article.in_sandbox && (
              <>
                <p className="ModalDonePublication__Body-descr">
                  {article && article.user && article.user.fname}
                  , ваша статья опубликована! Теперь расскажите о ней своим коллегам.
                </p>
                <button
                  className="button button--prime button--outline"
                  type="button"
                  onClick={() => this.setState({ isShowShare: true })}
                >
                  Поделиться
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

ModalDonePublication.propTypes = {
  article: PropTypes.object,
  isShow: PropTypes.bool,
  onClose: PropTypes.func
};

export default ModalDonePublication;
