import axios from 'axios';
import Selectize from 'selectize';
import 'selectize/dist/css/selectize.css';

import './index.scss';

export default $root => {
  const selectOptions = $root.find('.select-menu__options');
  const selectMenuBody = $root.find('.select-menu__body');
  const dropDownButton = $root.find('.second-menu__tab--dropdown');
  const dropDownList = $root.find('.second-menu__list');
  const selectClear = $root.find('.select-menu__clear');

  const $authors = $('.js-select-authors');
  const $community = $('.js-select-community');

  const urlSearchAuthors = $authors.data('url');

  $authors.selectize({
    valueField: 'id',
    labelField: 'name',
    searchField: 'name',
    plugins: ['restore_on_backspace'],
    // onDelete: () => false,
    load(query, callback) {
      if (!query.length) {
        callback();
        return;
      }
      axios
        .get(urlSearchAuthors, {
          params: {
            q: query,
          },
        })
        .then(resp => {
          callback(resp.data);
        })
        .catch(err => {
          console.err(err);
          callback();
        });
    },
  })
  .on('change', function (e) {
    if (!$(this).val()) {
      e.stopPropagation();
    }
  });

  $community.selectize();

  dropDownButton.on('click', function(e) {
    if ($(e.target).closest('.category__tab-drop-selected').length) return;
    const $this = $(this);
    $this.closest('.second-menu__tabs-wrap').toggleClass('second-menu__tabs-wrap--open');
    $this.toggleClass('second-menu__dropdown--active');
    dropDownList.fadeToggle(100);
  });

  $(document)
    .off('click.second-menu__tab')
    .on('click.second-menu__tab', function(e) {
      const t = e.target.classList[0];
      if (t === 'second-menu__tab' || t === 'second-menu__item') return;
      dropDownList.fadeOut();
    });

  selectOptions.on('click', function() {
    $(this).toggleClass('select-menu__options--active');

    selectMenuBody.slideToggle('medium', function() {
      if ($(this).is(':visible')) {
        $(this).css('display', 'flex')
      };
    });
  });

  $([$authors, $community]).on('change', function() {
    const isEmpty = !$(this)
      .find(':selected')
      .text().length;
    if (isEmpty) {
      selectClear.removeClass('select-menu__clear--selected');
    } else {
      selectClear.addClass('select-menu__clear--selected');
    }
  });

  $([$authors, $community]).each(function() {
    const isEmpty = !$(this)
      .find(':selected')
      .text().length;
    if (!isEmpty) {
      selectClear.addClass('select-menu__clear--selected');
    }
  });

  selectClear.on('click', function() {
    $authors[0].selectize.clear();
    $community[0].selectize.clear();
  });

  $(document)
    .off('turbolinks:before-cache.selectize')
    .one('turbolinks:before-cache.selectize', function() {
      $authors.length && $authors[0].selectize.destroy();
      $community.length && $community[0].selectize.destroy();
      $authors.css('display', '');
      $community.css('display', '');
    });

  setTimeout(() => {
    $authors.trigger('resize');
    $community.trigger('resize');
  }, 1500);
};
