import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select'

import './Guide.sass';

import imgTollbar from './edit-tollbar.png';
import imgSidebar from './edit-sidbar.png';
import imgMenuBtn from './edit-menu.png';
import IconPage from './icon-page.r.svg';




const sections = [
  {
    content: (
      <>
        <h2>Статья</h2>
        <p>
          Статья — это текст, в который можно добавить изображения, видеоролики, презентации и цитаты.
          В статье вы можете поделиться опытом, кейсом, достижением или вы хотите высказать своё мнение и
          рассказать целую историю.
        </p>
        <a href="/article/8511">Пример статьи</a>
      </>
    ),
    btn: 'Статья',
  },
  {
    content: (
      <>
        <h2>Выберите сообщество для публикации вашей статьи</h2>
        <p>
          Все материалы на InSberra разделены по тематическим сообществам. Они помогают удобно структурировать
          информацию и формировать ленту пользователя по интересующим его темам.
        </p>
      </>
    ),
    btn: 'Сообщество',
  },
  {
    content: (
      <>
        <h2>Выделите текст, чтобы применить форматирование, добавить заголовки или ссылку</h2>
        <img className="Guide__image" src={imgTollbar} alt="" />
        <p>
          Давным-давно, в
          &thinsp;
          <span>далекой</span>
          -далекой галактике
        </p>
      </>
    ),
    btn: 'Текст',
  },
  {
    content: (
      <>
        <h2>
          Загружайте медиафайлы и&nbsp;цитируйте других авторов.
          {' '}
          Для&nbsp;этого перейдите на&nbsp;новую строку и&nbsp;нажмите плюс
        </h2>
        <img src={imgSidebar} alt="" style={{ marginBottom: 10 }}/>
        <p>
          Вставляйте изображение или видео из любого источника по прямой ссылке. Изображение можно также
          добавлять из буфера обмена.
        </p>
      </>
    ),
    btn: 'Медиа',
  },
  {
    content: (
      <>
        <h2>Всё сохраняется автоматически</h2>
        <img src={imgMenuBtn} alt="" style={{ marginBottom: 10 }}/>
        <p>
          Статьи, которые вы пишите, мгновенно сохраняются с пометкой “Черновик”. Читать черновики может
          только автор. Черновики можно найти в меню редактора и в своем профиле.
        </p>
      </>
    ),
    btn: 'Черновики',
  },
  {
    content: (
      <>
        <h2>Делитесь своим творчеством</h2>
        <p>
          Чтобы другие пользователи смогли прочитать вашу статью, нажмите кнопку «Опубликовать». Перед
          публикацией введите хештеги, для быстрого поиска материалов, а также выберите обложку для статьи.
          Чем привлекательнее обложка и заголовок, тем больше людей захотят прочитать ваш текст.
        </p>
      </>
    ),
    btn: 'Публикации',
  },
];


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    backgroundColor: '#F5F5F5',
    height: 50,
    boxShadow: 'none'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: '#212121',
    '&:hover': {
      color: '#389A52',

    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  }),

  menu: (provided) => ({
    ...provided,
    margin: 0,
  }),
};

const options = sections.map((item, index) => ({value: index, label: item.btn}));


export default class Guide extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isShow: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  state = {
    activeSection: 0,
  };


  slideChangeHandler(direction, screenSize = 'desktop') {
    const {onClose} = this.props;
    const { activeSection } = this.state;
    const iterator = direction === 'right' ? 1 : -1;
    let position = activeSection + iterator;

    if (activeSection ===  sections.length - 1 && direction === 'right' && screenSize !== 'mobile') {
      position = 0
    }
    else if (activeSection === 0 && direction === 'left') {
      position = sections.length - 1
    }

    if (screenSize === 'mobile' && activeSection ===  sections.length - 1) {
      onClose();
      return
    }
    this.setState(() => ({activeSection: position }))
  }

  selectHandler({value}) {
    this.setState({activeSection: value})
  }

  render() {
    const { className, isShow, onClose } = this.props;

    if (!isShow) return null;

    const { activeSection } = this.state;
    const sectionContent = sections[activeSection].content;

    return (
      <div className={classNames('Guide', className)}>
        <div className="Guide__Sections">
            <div className="Guide__Header">
            <span className="Guide__Help">Помощь</span>
          </div>
          <span className="Guide__Arrow Guide__Arrow--Left"
                onClick={() => this.slideChangeHandler('left')}
          />
          <div className="Guide__Section">
              {sectionContent}
          </div>
          <span
            className="Guide__Arrow Guide__Arrow--Right"
            onClick={() => this.slideChangeHandler('right')}
          />
          <div className="Guide__Buttons">
            {activeSection === sections.length - 1 &&
            <a className="Guide__Button Guide__Button--FAQ"
            >
              Страница руководства
              <IconPage className="Guide__icon" />
            </a>
            }

            <Select
              isSearchable={ false }
              options={options}
              menuPlacement="top"
              defaultValue={options[0]}
              styles={customStyles}
              onChange={::this.selectHandler}
                />

            {/*<button*/}
            {/*  className="Guide__Button Guide__Button--Next"*/}
            {/*  type="button"*/}
            {/*  onClick={() => this.slideChangeHandler('right', 'mobile')}*/}
            {/*>*/}
            {/*  {activeSection === sections.length - 1 ? 'Начать писать статью' : 'Далее' }*/}
            {/*</button>*/}
          </div>
        </div>
        <div className="Guide__Nav">
          {sections.map((section, index) => (
            <button
              className={classNames('Guide__NavItem', {
                'Guide__NavItem--Active': activeSection === index,
              })}
              type="button"
              key={index.toString()}
              tabIndex={0}
              onClick={() => this.setState({ activeSection: index })}
            >
              <span className="Guide__NavItemText">{section.btn}</span>
              <span className="Guide__NavItemTextOverlay">{section.btn}</span>
            </button>
          ))}
          <a className="Guide__NavItem" href="/info" target="blank">
            <span className="Guide__NavItemWrap">
              <span className="Guide__NavItemText">Страница руководства</span>
              <span className="Guide__NavItemTextOverlay">Страница руководства</span>
            </span>

          </a>
        </div>
        <button className="Guide__Close" type="button" onClick={onClose}>&times;</button>
      </div>
    );
  }
}
