import React from 'react';

import Button from './Button';
import Block from './Block';
import constants from './constants';
import './styles.sass';

export default {
  title: constants.PLUGIN_NAME,
  type: constants.PLUGIN_TYPE,
  buttonComponent: Button,
  blockComponent: Block,
};
