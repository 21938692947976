import React from 'react';
import ReactDOM from 'react-dom';
import EditorContent from '~/editor2/components/EditorContent';

function init() {
  const $articleContent = $('.js-article-show-content');
  if($articleContent.length) {
    const article = $articleContent.data('article');
    ReactDOM.render(
      React.createElement(EditorContent, {
        article,
        isReadOnly: true,
      }),
      $articleContent[0],
    );
  }
}

$(document).on('turbolinks:load', init);
