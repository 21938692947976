import {drawGraph} from '~/blocks/plot/drawGraph'
import {attachDatePicker} from '~/blocks/datepicker'
import './index.sass'
const admin_statistics_path = (kind, endpoint=`/admin/panel/statistics/${kind}_data`, options={}) => {
  return `${endpoint}?${$.param(options)}`;
}
export function initGraph(kind, formatItem, format) {
  const $root = $(`.js-stats_table_${kind}`);
  const canvas = `.js-graph_${kind}`
  const from = `.js-date_from_${kind}_field`
  const till = `.js-date_till_${kind}_field`
  const endpoint = $root.data('endpoint')
  const query = {limit: 50, offset: 0, order: 'desc'}
  var plot
  function dateBounds() {
    return {
      date_from: $(from).val(),
      date_till: $(till).val(),
    }
  }
  function fetchData() {
    $root.find(".js-export")[0].href = admin_statistics_path(kind, endpoint, {...dateBounds(), export_xlsx: true})
    $.ajax({
      url: admin_statistics_path(kind, endpoint, {...query, ...dateBounds(), format: 'json'}),
      type: "GET",
      success: function(data) {
        if (plot) plot.destroy()
        plot = drawGraph(canvas, data, formatItem, format);
      }
    });
  }
  $.ajax({
    url: admin_statistics_path(kind, endpoint, {
      ...query,
      date_from: $root.data("from"),
      date_till: $root.data("till"),
    }),
    type: "GET",
    success: function(data) {
      if (plot) plot.destroy()
      plot = drawGraph(canvas, data, formatItem, format);
    }
  });

  setTimeout(function() {
    // updates each 500 frame
    attachDatePicker(`${from}, ${till}`, fetchData)
  }, 500);
};
