import './index.sass'
import { attachDatePicker } from '~/blocks/datepicker'

export function initTable(kind) {
  const selector = `.js-stats_table_${kind}`
  const $root = $(selector)
  const from = `.js-date_from_${kind}_field`
  const till = `.js-date_till_${kind}_field`
  const endpoint = $root.data('url')
  function dateBounds() {
    return {
      date_from: $(from).val(),
      date_till: $(till).val(),
    }
  }
  function fetchData() {
    $root.closest('.js-stats-table').find(".js-export")[0].href = endpoint + '?' + $.param({...dateBounds(), export_xlsx: true})
    $root.bootstrapTable("refresh", {
      url: $root.data('url') + '?' + $.param({
        format: 'json',
        date_from: $(from).val(),
        date_till: $(till).val(),
      })
    })
  }
  setTimeout(function() {
    attachDatePicker(`${from}, ${till}`, fetchData)
  }, 0)
  $root.bootstrapTable()
}
