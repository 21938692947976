import React, { PureComponent } from 'react';
import ReactSelect, { components } from 'react-select';
import PropTypes from 'prop-types';
import axios from 'axios';
import flash from '~/blocks/flash';

import { customSelectStyles, customSelectComponents } from '~/editor2/components/Select/styles';
import cloneDeep from "lodash-es/cloneDeep";

const communitySelectStyles = {
  ...customSelectStyles,
  control: (base, state) => ({
    ...customSelectStyles.control(base, state),
    border: 'none',
    borderBottom: state.menuIsOpen ? '1px solid #EBEBEB' : '1px solid #E5E5E5',
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    height: 55,
    ':hover': {
      borderBottom: state.menuIsOpen ? '1px solid #EBEBEB' : '1px solid #E5E5E5',
    }
  }),
  placeholder: (base, state) => ({
    ...customSelectStyles.placeholder(base, state),
    fontSize: state.selectProps.menuIsOpen ? 15 : 18,
  })
};

const communitySelectStylesWithError = {
  ...communitySelectStyles,
  control: (base, state) => ({
    ...communitySelectStyles.control(base, state),
    borderBottom: '1px solid #F0443D',
    ':hover': {
      borderColor: '#F0443D',
    }
  }),
}

class SelectCommunity extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.loadCategories();
  }

  loadCategories() {
    // Сообществ в Сбербанке немного. Async тут не нужен и даже пагинация
    axios.get('/api/v1/categories').then((resp) => {
      this.state.categories = resp.data;
      this.setState({
        categories: resp.data,
        isLoading: false,
      });
    }).catch(err => {
      console.error(err)
      flash({error: 'Произошла ошибка'})
    })
  }

  options() {
    const { categories } = this.state;
    return categories.map(({ id, name }) => ({ label: name, value: id }));
  }

  render() {
    const { menuIsOpen, isLoading } = this.state;
    const { onChange, value, isError, ...rest } = this.props;
    return (
      <ReactSelect
        placeholder={menuIsOpen ? 'Поиск сообществ' : 'Сообщество'}
        styles={isError ? communitySelectStylesWithError : communitySelectStyles}
        components={customSelectComponents}
        tabSelectsValue={false}
        classNamePrefix="select-community"
        openMenuOnFocus
        isLoading={isLoading}
        options={this.options()}
        noOptionsMessage={() => 'Сообщество не найденно'}
        onMenuClose={() => this.setState({ menuIsOpen: false })}
        onMenuOpen={() => this.setState({ menuIsOpen: true })}
        onChange={onChange}
        value={value}
        {...rest}
      />
    );
  }
}

SelectCommunity.propTypes = {
  onChange: PropTypes.func,
};

export default SelectCommunity;
