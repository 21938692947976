import autosize from 'autosize';

import './index.scss';
import './checkbox-toggle.sass';
import './checkbox-group.sass';

/**
 *
 * @param {jQuery} $textarea
 */
function initAutosizeTextarea($textarea){
  if(!$textarea.data('ready-set-autosize')){
    $textarea.data('ready-set-autosize', true);
    autosize($textarea);
  }
}

$(document).off('input.autosizearea').on('input.autosizearea', '.js-autosize-area', function () {
  initAutosizeTextarea($(this));
});

$(document).off('init_autosizearea').on('init_autosizearea', function () {
  $('.js-autosize-area').each(function () {
    initAutosizeTextarea($(this));
  });
});
