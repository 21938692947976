import TimeMe from 'timeme.js'
import axios from 'axios'
var users_update_time_path = (id) => `/users/update_time/${id}`
var lastSentTime = 0;
import { setHeaders } from "~/axios-headers";
var headersSet = false;
var sendTime = function() {
  if (!headersSet) {
    setHeaders()
    headersSet = true;
  }
  if(!window.userId) {
    return;
  }
  if (sendDfd) {
    return sendDfd.then(sendTime);
  }
  const currentTime = TimeMe.getTimeOnPageInSeconds("page")
  const time =  currentTime - lastSentTime;
  //console.log(currentTime, lastSentTime, time)
  const payload = {time}
  const articleID = $('.js-article-show').data('time-id')
  if (articleID) {
    payload.user_article_view_log_id = $('.js-article-show').data('log-id')
  }
  sendDfd = axios.post(users_update_time_path(articleID || 0), payload).then(resp => {
    lastSentTime = currentTime;
    sendDfd = null
  }).catch((err) => {
    console.error("failed to send time", err)
    sendDfd = null
  })
}

var sendDfd = null

var sendTimeIfOver30s = function() {
  const currentTime = TimeMe.getTimeOnPageInSeconds("page")
  const time =  currentTime - lastSentTime;

  if (time > 30) {
    sendTime();
  }
}

var startTimer = function() {
  TimeMe.initialize({
    currentPageName: "page",
    idleTimeoutInSeconds: 120
  });
  sendTime()
}
var timejsInterval = setInterval(sendTimeIfOver30s, 10000); //30000

document.addEventListener('turbolinks:load', startTimer);
document.addEventListener('turbolinks:before-cache', sendTime);
