import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './ButtonIcon.sass';

class ButtonIcon extends PureComponent {
  render () {
    const { className, iconSrc, text, ...restProps } = this.props;
    return (
      <button className={classNames("ButtonIcon", className)} type="button" {...restProps}>
        <img className="ButtonIcon__Icon" src={iconSrc} alt=""/>
        <span className="ButtonIcon__Text">{text}</span>
      </button>
    );
  }
}

ButtonIcon.propTypes = {
  className: PropTypes.string,
  iconSrc: PropTypes.any,
  text: PropTypes.string,
};

export default ButtonIcon;
