import './index.sass';

const scrollPos = {};

export default () => {
  const $menuTabs = $('.menu-tab');
  $menuTabs.each(function () {
    const $menu = $(this);
    const id = $menu.attr('id');
    if (id) {
      if (scrollPos[id]) {
        $menu.scrollLeft(scrollPos[id]);
      }
      $menu.off('scroll').on('scroll', function () {
        scrollPos[id] = $menu.scrollLeft();
      });
    }
  });
};
