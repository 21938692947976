import './user-profile';
import './user-badge';
import initProfileNavigation from './profile-navigation';
import './user-info';
import './user-subs';
import initEditProfile from './edit-profile';
import './edit-settings';
import initInfoPopup from './info-popup';

const init = () => {
  const $editProfile = $('.edit-profile');
  $editProfile.length && initEditProfile($editProfile);

  const $profileNav = $('.profile-navigation');
  $profileNav.length && initProfileNavigation($profileNav);

  const $infoPopup = $('.info-popup');
  $infoPopup.length && initInfoPopup($infoPopup);
};

$(document).on('turbolinks:load', init);
