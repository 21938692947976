import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import './Modal.sass';
import ModalWindow from "./ModalWindow";

ReactModal.setAppElement(document.body);

class Modal extends PureComponent {
  render() {
    const {
      title,
      onRequestClose,
      withoutWindow,
      children,
      ...rest
    } = this.props;
    return (
      <ReactModal
        // onRequestClose={onRequestClose}
        {...rest}
        contentLabel="Modal"
        overlayClassName="ReactModal__Overlay--Custom"
        className="ReactModal__Content--Custom"
        bodyOpenClassName="ReactModalBodyOpen"
        htmlOpenClassName="ReactModalHtmlOpen"
      >
        {withoutWindow && children}
        {!withoutWindow && (
          <ModalWindow title={title} onClose={onRequestClose}>
            {children}
          </ModalWindow>
        )}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  title: PropTypes.node,
  isOpen: PropTypes.bool,
  withoutWindow: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default Modal;
