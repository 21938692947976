import tinymce from 'tinymce/tinymce';

window.tinymce = tinymce;

// A theme is also required
import 'tinymce/themes/modern';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
// import 'tinymce/plugins/autolink';
import './tinymce-autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/textcolor';
// import 'tinymce/plugins/image';
// import 'tinymce/plugins/media';
import 'tinymce/plugins/code';
import 'tinymce/plugins/autoresize';
import 'tinymce-mention/mention/plugin';
import 'tinymce-mention/css/autocomplete.css';
import './tinymce-placeholder';
import './tinymce-ru';
import './tinymce-maxchars';
// import { Editor as TEditor } from '@tinymce/tinymce-react';

import 'style-loader!tinymce/skins/lightgray/skin.min.css';
// Конфликт с extract chunks - не менять расширение файла
import contentStyle from 'raw-loader!./tinymce-content.rawcss';

// Данная тема ломает остальные, в tinymce подключение одновременно несколько тем ломает все
// https://github.com/tinymce/tinymce/issues/4263
// https://github.com/tinymce/tinymce/issues/4224
const loadInliteTheme = () => import(/* webpackChunkName: "tinymce_themes_inlite" */'tinymce/themes/inlite');
const loadMobileTheme = () => import(/* webpackChunkName: "tinymce_themes_mobile" */'tinymce/themes/mobile');

export {
  tinymce,
  contentStyle,
  loadInliteTheme,
  loadMobileTheme,
};
