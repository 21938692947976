import './index.sass';
import React from 'react';
import ReactDOM from 'react-dom';
import SharePopup from './SharePopup';

export default () => {
  $(document).off('click.share').on('click.share', ".js-share-article", function() {
    const id = $(this).data('id');
    const type = $(this).data('type');
    const $countElem = $(`.js-share-article[data-id=${id}][data-type=${type}]`)
      .find('.social__count, .c-social__count, .article__count');
    $.magnificPopup.open({
      mainClass: 'share-popup-wrap',
      items: {
        src: '<div id="share-container"></div>',
        type: 'inline',
      },
      modal: true,
      autoFocusLast: false,
      fixedContentPos: true,
      fixedBgPos: true,
      callbacks: {
        open() {
          const mountContainer = $('#share-container')[0];
          ReactDOM.render(
            React.createElement(SharePopup, {
              id, type,
              onClose: (data) => {
                ReactDOM.unmountComponentAtNode(mountContainer);
                $.magnificPopup.close();
                $countElem.html(data.shares);
              },
              onCancel: () => {
                ReactDOM.unmountComponentAtNode(mountContainer);
                $.magnificPopup.close();
              }
            }),
            mountContainer
          );
        }
      }
    });
  });
};



