import debounce from 'lodash-es/debounce';
import axios from 'axios';
import template from './search_tmpl.pug';
import flash from '~/blocks/flash';

function initSearchSize() {
  // $('#after_header_links').hide();
  if ($(window).outerWidth() <= 767) {
    var searchLeft = $('#logo').offset().left;
    var searchWidth = $(window).outerWidth() - $('.user_block').outerWidth() - 50;
    $('#header_search')
      .css({
        width: searchWidth,
        left: searchLeft,
      })
      .fadeIn(100, function() {
        $('#header_search input[type=text]').focus();
        $('#header_search .form_item').removeClass('active');
      });
  } else {
    var searchLeft = $('.check_search_height').offset().left + 15;
    var searchWidth = $('.check_search_height').outerWidth() - 30 - 64;
    $('#header_search')
      .css({
        width: searchWidth,
        left: searchLeft,
      })
      .fadeIn(100, function() {
        $('#header_search input[type=text]').focus();
        $('#header_search .form_item').removeClass('active');
        $('#header').addClass('search_open');
      });
  }
}

function handleAutocomplete(event) {
  const { value } = event.target;
  axios
    .get('/api/v1/search', { params: { q: value, limit: 3 } })
    .then(resp => {
      $('.js-main-search-suggests').show();
      const popupContent = template({
          ...resp.data,
          all_results_url: `/?q=${value}`,
          all_users_url: `/?author_name=${value}`,
          all_tags_url: `/?tag_name=${value}`,
          searchRegExpReplace: new RegExp(`(${value})`, 'ig')
        });
      $('.js-main-search-suggests').html(popupContent);
    })
    .catch(err => {
      console.error(err);
      flash({ error: 'Произошла ошибка' });
    });
}

function hideSearch() {
  // $('html').removeClass('no_scroll');
  $('#header_search').fadeOut(150, function() {
    $('#header_search input[type=text]').val('');
    $('#header').removeClass('search_open');
    $('#search_popup').hide();
  });
  $('#after_header_links').css('visibility', '');
}

function init() {
  $('.js-main-search').on('click', function(event) {
    event.preventDefault();
    $('#after_header_links').css('visibility', 'hidden');
    $('.js-main-search-form').show();
    $('.js-main-search-suggests').hide();
    $('#header_search input[type=text]').focus();
    // initSearchSize();
  });
  $('.js-hide-main-searh').on('click', function(event) {
    event.preventDefault();
    $('.js-main-search-form').hide();
    $('#after_header_links').css('visibility', '');
  });

  $('.js-main-search-field').on('input', debounce(handleAutocomplete, 200));

  $('.hide_search').click(function() {
    hideSearch();
  });

  $(document)
    .off('mouseup.outside-header-search touchend.outside-header-search')
    .on('mouseup.outside-header-search touchend.outside-header-search', function(e) {
      if (!$(e.target).closest('#header_search').length && !$(e.target).closest('#search_popup').length) {
        hideSearch();
      }
    });

  $(document)
    .off('hide-search')
    .on('hide-search', function() {
      hideSearch();
    });
}

$(document).on('turbolinks:load', init);
