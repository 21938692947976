import './comment.sass';
import axios from 'axios';
import throttle from 'lodash-es/throttle';
import { setHeaders } from '~/axios-headers';
import flash from '~/blocks/flash';

function initDelete() {
  $(document).on('click', '.js-delete-comment', function(event) {
    event.preventDefault();
    axios.delete($(this).attr('href')).then(resp => {
      const {id} = resp.data;
      $(`[data-comment-id='${id}']`).remove();
      $(`[data-answer-id='${id}']`).remove();
    }).catch(err => {
      console.log(err);
    });
  });
}

function initLikes() {
  $(document).on('click', '.js-like-comment', function(event) {
    event.preventDefault();
    axios.post($(this).attr('href'), {}).then(resp => {
      $(this).closest('.answer_likes').html(resp.data);
    }).catch(err => {
      console.log(err);
      flash({error: 'Произошла ошибка'});
    });
  });
}
$(initLikes);
$(initDelete);

function initSelect() {
  $('.js-comments-order-select').on('change', function() {
    const $commentsHolder = $('.js-article-comments');
    $commentsHolder.addClass('is-loading');
    axios.get($(this).val()).then(({data}) => {
      $(document).trigger('comment-editor.destroy');
      $commentsHolder.html(data);
      $commentsHolder.removeClass('is-loading');
      init(false);
      // initEditors();
      $(document).trigger('comment-editor.init');
    }).catch(err => {
      console.error(err);
    });
  });
}

function init(select = true) {
  if (select) {
    initSelect();
  }
  const $root = $('.js-article-show');
  if ($root.length === 0) {
    return;
  }

  const $detail_comments = $('.detail_comments');
      let $comments = null;

  const articleId = $('.js-article-show').data('id');
      let currentPage = 1;
      let fetching = false;

  if ($detail_comments.length) {
    $comments = $detail_comments.find('#comments');
    setHeaders();
    axios.post('/article/comments', {id: articleId}).then((response) => {
      if(window.location.hash){
        const $commentList = $(window.location.hash);
        if($commentList.length) {
          $('html, body').stop().animate({
            scrollTop: $commentList.offset().top
          }, 0);
        }
      }

      const {data} = response;

      showComments(data);

      if(data.next_page) {
        $(window).on("scroll.article_comments", throttle(handleScorll, 200));
      }
      // initEditors();
    });
  }


  function handleScorll() {
    const offset = $(document).height() - $(window).height() - 60;
    if($(window).scrollTop() > offset) {
      currentPage++;
      showMore(currentPage);
    }
  }

  function showMore(page) {
    if(fetching) return;
    fetching = true;
    axios.post('/article/comments', {id: articleId, page}).then((response) => {
      showComments(response.data);
      fetching = false;
    });
  }

  function showComments(data) {
    if(!data.next_page) $(window).off("scroll.article_comments");

    if(data.hat_favorite && !$("div").is("#favorite_div")) {
      $comments.append(data.favorite_comments);
    }
    $comments.append(data.comments);
  }

}
document.addEventListener('turbolinks:load', init);
