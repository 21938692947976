import React from 'react';
import PropTypes from 'prop-types';
import './QuizChangeVote.sass';

export const QuizChangeVote = props => {
  const { handleChangeVote } = props;
  return (
    <button type="button" className="QuizChangeVote" onClick={handleChangeVote}>
      Изменить выбор
    </button>
  );
};

QuizChangeVote.propTypes = {
  handleChangeVote: PropTypes.func,
};

export default QuizChangeVote;
