import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import AsyncCreatable from 'react-select/async-creatable';
import { components } from 'react-select';
import debounce from 'lodash-es/debounce';

import { customSelectStyles } from '~/editor2/components/Select/styles';
import IconRemove from './icon-remove.r.svg';

const { CancelToken } = axios;

const tagsSelectStyles = {
  ...customSelectStyles,
  placeholder: (base, state) => ({
    ...customSelectStyles.placeholder(base, state),
    whiteSpace: 'nowrap',
  }),
  valueContainer: (base, state) => ({
    ...customSelectStyles.valueContainer(base, state),
    paddingRight: 15,
    paddingLeft: 15,
  }),
  multiValue: (base) => ({
    ...base,
    margin: 7,
    backgroundColor: "#fff",
    border: '2px solid #EDEDED',
    borderRadius: 2,
  }),
  multiValueLabel: (base) => ({
    ...base,
    padding: '3px 0px 3px 8px',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 16 / 12,
    color: '#A6A6A6'
  }),
  multiValueRemove: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingRight: 8,
    paddingLeft: 8,
    color: '#212121',
    ':hover': {
      backgroundColor: 'transparent',
      color: '#389A52',
    }
  })
};

const tagsSelectComponents = {
  Control: props => {
    const { children, ...restProps } = props;
    return (
      <components.Control {...restProps}>
        {React.Children.map(children, child => React.cloneElement(child, { isFocused: restProps.isFocused }))}
      </components.Control>
    );
  },
  MultiValueRemove: props => (
    <components.MultiValueRemove {...props}>
      <IconRemove />
    </components.MultiValueRemove>
  ),
  ValueContainer: props => {
    const { children, ...restProps } = props;
    const arrayOfChildren = React.Children.toArray(children).filter(child => child.type !== components.Placeholder);
    const multiValues = arrayOfChildren.filter(child => child.type === components.MultiValue);
    const input = arrayOfChildren.filter(child => child.type === components.Input);
    return (
      <components.ValueContainer {...restProps}>
        {multiValues}
        <div
          style={{
            position: 'relative',
            minWidth: 106
          }}
        >
          {restProps.selectProps.inputValue ? null : (
            <components.Placeholder {...restProps} key="placeholder">
              {restProps.selectProps.placeholder}
            </components.Placeholder>
          )}
          {input}
        </div>
      </components.ValueContainer>
    );
  },
};

class SelectTags extends PureComponent {
  loadOptions = debounce((inputValue, callback) => {
    if (this.cancelToken) {
      this.cacelToken.cancel('cancel request tags');
    }
    this.cacelToken = CancelToken.source();
    axios
      .get('/articles/tags.json', {
        params: {
          input: inputValue
        },
        cancelToken: this.cacelToken.token,
      })
      .then(response => {
        callback(response.data);
      }).catch( err => {
        console.error(err);
      });
    }, 200);

  render () {
    const { id, tags, onChange, disabled, ...rest } = this.props;
    return (
      <AsyncCreatable
        {...rest}
        id={id}
        isMulti
        defaultOptions
        placeholder="Добавить тег..."
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        formatCreateLabel={(inputValue) => `Создать тег "${inputValue}"`}
        getNewOptionData={(inputValue, optionLabel) => ({
          id: inputValue,
          name: optionLabel
        })}
        isValidNewOption={(inputValue, selectValue, selectOptions) => {
          return inputValue && selectValue.id !== inputValue && !selectOptions.some(item => item.id === inputValue);
        }}
        value={tags}
        loadingMessage={() => "Загрузка"}
        noOptionsMessage={() => 'Ничего не найдено'}
        styles={tagsSelectStyles}
        components={tagsSelectComponents}
        loadOptions={this.loadOptions}
        onChange={onChange}
        classNamePrefix='select-tag'
        // menuIsOpen
        isDisabled={disabled}
      />
    );
  }
}

SelectTags.propTypes = {
  id: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SelectTags;
