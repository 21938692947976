import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './TextAreaCounter.sass';

class TextAreaCounter extends PureComponent {
  handlerChange = (e) => {
    const { maxLength, onChange } = this.props;
    const value = e.target.value.slice(0, maxLength);
    onChange && onChange(value);
  }

  render () {
    const { isError, value, maxLength, onChange, ...props } = this.props;
    return (
      <div className="TextAreaCounter">
        <textarea
          className={classNames('TextAreaCounter__Control form-control', {
            'is-error': isError,
          })}
          value={value}
          onChange={this.handlerChange}
          {...props}
        />
        <div className="TextAreaCounter__Counter">
          <span>{(value && value.length) || 0}</span> / {maxLength}
        </div>
      </div>
    );
  }
}

TextAreaCounter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  maxLength: PropTypes.number.isRequired,
  isError: PropTypes.bool,
};

export default TextAreaCounter;
