export default function onVimeoIframeAPIReady() {
  console.log('onVimeoIframeAPIReady')
  $(".vimeo-video").each(function() {
    var $t = $(this)
    var sent = false;
    var sending = false;

    function sendViewedTillEnd(articleId) {
      console.log("local video is sending message: article " + articleId + " is viewed till end");
      sending = true;
      $.ajax({
        type: "post",
        url: "/article/viewed-till-end/update/" + articleId,
        data: {},
        success: function() {
          sent = true;
        },
        error: function(error) {
          console.error(error)
          sending = false;
        }
      });
    }

    function vm_onPlayerPlay(e) {
      //console.log(e)
      //console.log("player state change", $t, e, e.target.getDuration(), e.target.getCurrentTime())
      if ( !sent && !sending && (e.percent > 0.95) ) {
        var articleId = $t.data('article-id')
        sendViewedTillEnd(articleId)
      }
    }

    var player = new Vimeo.Player($t[0], {
      width: $t.width(),
      id: $t.data("id"),
    });
    player.on ('timeupdate', vm_onPlayerPlay)
  })
}
