import axios from 'axios';
import throttle from 'lodash-es/throttle'
import empty_template from './empty.pug';
import layout_template from './layout.pug';

document.addEventListener('turbolinks:load', function() {
  let $notification = $('#notification'),
      $notificationButton = $('.notification_onboard'),
      $notificationList = null,
      fetching = false,
      $header = $('#header'),
      $content = $('#content');

  let notificationLink = '/notifications';
  let currentPage = 1;

  $notificationButton.on('click', function(e) {
    e.preventDefault();
    $('html').toggleClass('no_scroll')

    axios.post(notificationLink).then((response) => {
      let data = response.data;
      currentPage = 1;
      if(data) {
        if($notification.find('#notification_inner').length == 0 || data.is_next_page) {
          if(data.notifications == ' ' || !data.notifications.length) {
            $notification.html(empty_template());
            $('html').addClass('no_scroll');
            $notification.find('#notification_inner ul').css({
              'overflow-y': 'hidden'
            })
          } else {
            // var maxHeight = $(window).outerHeight() - $notification.offset().top - 5;
            $notification.html(layout_template());
            $notificationList = $notification.find('#notification_inner ul');

            if(data.next_page) {
              $notificationList.on('scroll', throttle(handleScorll, 200));
            }

            showNotifications(data);

            if(data.next_page) {
              if ($notificationList[0].scrollHeight <= $notificationList.outerHeight()) {
                currentPage++;
                showMore(currentPage);
              }
            }
          }
        } else {
          $notification.empty();
        }

        // if($(window).outerWidth() <= 767) {
        //   $(window).off('resize.notifications').on('resize.notifications', function(){
        //     if($notificationList) {
        //       $notificationList.css({'max-height': $(window).outerHeight() - $notification.offset().top - 5});
        //     }
        //   })
        // }
      }
    })
  });

  function handleScorll() {
    var offset = $notificationList[0].scrollHeight - $notificationList.height() - 100;
    if($notificationList.scrollTop() > offset) {
      currentPage++;
      showMore(currentPage);
    }
  }

  function showMore(page) {
    if(fetching) return;
    fetching = true;
    $notification.find('.pagination').html(loader());
    axios.post(notificationLink, {page: page}).then((response) => {
      showNotifications(response.data);
      fetching = false;
    });
  }

  function showNotifications(data) {
    $notificationList.append(data.notifications);
    if(!data.next_page) $notificationList.off('scroll')
    $header.css('z-index', '10');
    $content.css('z-index', '1');
    function mark(id){
      axios.post(data.notification_change_path, {id: id}).then((response) => {
        $(`#notification_${id}`).removeClass("active notification_clickable");
        $("#new_notifications_count").html(response.data.count > 0 ? '<em>' + response.data.count + '</em>' : '');
      });
    }

    $('.active.notification_clickable').off('click').on('click', function(e) {
      e.preventDefault();
      var id = $(this).attr('data-id');
      mark(id);
    })

    $('.js-notification_clickable_link').on('click', function(e) {
      e.stopPropagation();
      var id = $(this).closest('.active.notification_clickable').data('id')
      mark(id);
    })
  }
})

function init() {
  $(document).on('click', '.js-nofication-check-all-btn', function(event) {
    event.preventDefault();
    axios.post($(this).attr('href'), {}).then(resp => {
      $('.notification_clickable').removeClass('notification_clickable')
      $('#new_notifications_count').html('')
    }).catch(err => {
      console.error(err)
      flash({error: 'Ошибка сервера'})
    })
  })
}

$(init)
