$(document).on('turbolinks:load', init);
function init() {
  $('.filter_user #find_user, .filter_user #find_user_close').click(function(event){
      $(this).closest('#admin_search_users').toggleClass('show_search_');

  });
  $('.filter_user #find_user_close').click(function(){
      $('#form_find input[type=text]').val('')
      $('#form_find').submit()
  })
}

