import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import axios from 'axios';
import './ModalCommunity.sass';
import ModalWindow from '~/editor2/components/Modal/ModalWindow';
import CustomCheckbox from './CustomCheckbox';
import { CropCommunity } from '../crop';
import Dropzone, { TypeDropzone } from '~/editor2/components/Dropzone';
import PopoverError from '~/editor2/components/Popovers/Error';
import { preloader } from '~/blocks/infinity-scroll';
import flash from '~/blocks/flash/index';
import TEditor from "~/editor/TEditor";

const DESCRIPTION_LENGTH = 500;

function stripTags(str) {
  const div = document.createElement("div");
  div.innerHTML = str;
  return div.textContent || div.innerText || "";
}

/* eslint-disable camelcase */
class ModalCommunity extends PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      step: 1,
      original_image: undefined,
      img_circle_small: undefined,
      img_circle_medium: undefined,
      img_square: undefined,
      img_rect: undefined,
      isFetch: !!props.categoryId,
      isFetchError: false,
      isProcessing: false,

      categoryNewId: undefined,
      category: {
        name: '',
        description: '',
        is_private: false,
        no_sandbox: false,
      },
      errors: {
        name: null,
        description: null,
      }
    };
  }

  componentDidMount () {
    const {categoryId} = this.props;
    if (categoryId) {
      axios
        .get(`/api/v1/categories/${categoryId}`)
        .then((response) => {
          const { name, description, is_private, no_sandbox } = response.data;
          this.setState({
            isFetch: false,
            category: {
              name,
              description,
              is_private,
              no_sandbox,
            }
          });
        })
        .catch(() => {
          this.setState({
            isFetchError: true,
          });
        });
    }
  }

  setProcessing(isProcessing) {
    this.setState({
      isProcessing
    });
  }

  setStep(step) {
    this.setState({
      step
    });
  }

  setCroppedImages = (responseData) => {
    /* eslint-disable no-shadow */
    const {
      original_image,
      img_circle_small,
      img_circle_medium,
      img_square,
      img_rect
    } = responseData;
    this.setState({
      original_image,
      img_circle_small,
      img_circle_medium,
      img_square,
      img_rect,
    });
  }

  checkField = () => {
    const { category } = this.state;
    const { name, description } = category;

    if (!name) {
      this.setState({
        errors: {
          name: "Не добавлено название"
        }
      });
      return true;
    }

    if (!description) {
      this.setState({
        errors: {
          description: "Не добавлено описание"
        }
      });
      return true;
    }

    if (stripTags(description).length > DESCRIPTION_LENGTH) {
      this.setState({
        errors: {
          description: "Слишком длинное описание"
        }
      });
      return true;
    }

    return false;
  }

  showErrorFields(errors, defaultMessage) {
    if (errors) {
      const { name, description } = errors;
      if (name) {
        this.setState({
          errors: {
            name: name.join('\n')
          }
        });
      }
      if (description) {
        this.setState({
          errors: {
            description: description.join('\n')
          }
        });
      }
    } else {
      alert(defaultMessage);
    }
  }

  handlerCloseModal = () => {
    const { step } = this.state;
    const { onClose, categoryId } = this.props;
    if (step === 2) {
      if (categoryId) {
        this.handlerClickFinishEdit();
      } else {
        this.handlerClickFinishCreate();
      }
    } else {
      onClose && onClose();
    }
  }

  handlerClickNextStepCreate = () => {
    const { category } = this.state;

    if (this.checkField()) return;

    this.setProcessing(true);
    axios
      .post('/api/v1/categories', { category })
      .then(response => {
        const { id } = response.data;
        this.setState({
          categoryNewId: id,
        });
        this.setProcessing(false);
        this.setStep(2);
      })
      .catch(error => {
        console.log(error.response);
        const { errors } = error.response.data;
        this.showErrorFields(errors, "Ошибка создания. Не удалось создать сообщество.");
        this.setProcessing(false);
      });
  }

  handlerClickFinishCreate = () => {
    const { categoryNewId } = this.state;
    const { onClose } = this.props;
    onClose && onClose();
    flash({ success: 'Сообщество создано' });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  handlerClickNextStepEdit = () => {
    const { categoryId } = this.props;
    const { category } = this.state;

    if (this.checkField()) return;

    this.setProcessing(true);
    axios
      .put(`/api/v1/categories/${categoryId}`, { category })
      .then((response) => {
        const { original_image } = response.data;
        this.setState({
          original_image,
        });
        this.setStep(2);
        this.setProcessing(false);
      })
      .catch((error) => {
        console.log(error.response);
        const { errors } = error.response.data;
        this.showErrorFields(errors, 'Ошибка сохранения. Обратитесь к администратору.');
        this.setProcessing(false);
      });
  }

  handlerClickFinishEdit = () => {
    const { onClose } = this.props;
    const {
      original_image,
      img_circle_small,
      img_circle_medium,
      img_square,
      img_rect,
      category,
    } = this.state;
    onClose && onClose({
      category,
      original_image,
      img_circle_small,
      img_circle_medium,
      img_square,
      img_rect,
    });
  }

  handlerUploadComplete = (responseData) => {
    this.setCroppedImages(responseData);
    this.setProcessing(false);
  }

  handlerUploadStart = () => {
    this.setProcessing(true);
  }

  handlerChangeFile = event => {
    const { categoryId } = this.props;
    const { categoryNewId } = this.state;
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('category[image]', file, file.name);
      this.setProcessing(true);
      axios
        .put(`/api/v1/categories/${categoryId || categoryNewId}`, formData)
        .then(response => {
          const { original_image } = response.data;
          this.setState({
            original_image
          });
          this.setProcessing(false);
        })
        .catch(err => {
          console.log(err.response);
          alert('Ошибка изменения аватара. Обратитесь к администратору.');
          this.setProcessing(false);
        });
    }
  }

  changeCategoryData = (field, value) => {
    this.setState(state => ({
      ...state,
      category: {
        ...state.category,
        [field]: value,
      }
    }));
  }

  handleChangeEditor = value => {
    this.changeCategoryData('description', value);
    this.setState({ errors: { description: null } });
  }

  messageImage() {
    return (
      <>
        Рекомендуемый размер  изображения: <b>500x500&nbsp;px</b>
      </>
    );
  }

  renderStep1() {
    const { category, errors } = this.state;
    return (
      <>
        <div className="form-group">
          <label htmlFor="community_name">Название сообщества</label>
          <PopoverError error={errors.name} isShow={errors.name}>
            <input
              className={classNames('form-control',{ 'is-error': errors.name })}
              id="community_name"
              type="text"
              placeholder="Добавить название"
              name="name"
              value={category.name}
              autoComplete="off"
              onChange={event => {
                this.changeCategoryData('name', event.target.value);
                this.setState({ errors: { name: null } });
              }}
            />
          </PopoverError>
        </div>
        <div className="form-group">
          <label htmlFor="community_descr">Описание сообщества</label>
          <PopoverError error={errors.description} isShow={!!errors.description}>
            {/* <TextAreaCounter
              id="community_descr"
              maxLength={250}
              name="description"
              placeholder="Добавить описание"
              value={category.description}
              onChange={value => {
                this.changeCategoryData('description', value);
                this.setState({ errors: { description: null } });
              }}
              isError={errors.description}
            /> */}
            <div>
              <TEditor
                placeholder="Добавить описание"
                value={category.description}
                maxChars={DESCRIPTION_LENGTH}
                plugins={[
                  'paste',
                  'link',
                  'placeholder',
                  'autoresize',
                ]}
                toolbar={[
                  ['undo', 'redo'],
                  ['bold', 'italic','underline'],
                  ['link'],
                  ['removeformat']
                ]}
                onChange={this.handleChangeEditor}
              />
            </div>
          </PopoverError>
        </div>
        <div className="form-group">
          <label htmlFor="">Настройки сообщества</label>
          <div className="ModalCommunity__Settings">
            <div className="ModalCommunity__SettingsItem">
              <div className="ModalCommunity__Setting">
                <div className="ModalCommunity__SettingLabel">
                  Сделать сообщество закрытым?
                </div>
                <div className="ModalCommunity__SettingValue">
                  <CustomCheckbox
                    checked={category.is_private}
                    onChange={event => {
                      this.changeCategoryData('is_private', event.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="ModalCommunity__SettingsItem">
              <div className="ModalCommunity__Setting">
                <div className="ModalCommunity__SettingLabel">
                  Без модерации
                </div>
                <div className="ModalCommunity__SettingValue">
                  <CustomCheckbox
                    checked={category.no_sandbox}
                    onChange={event => {
                      this.changeCategoryData('no_sandbox', event.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderStep2() {
    const { categoryId } = this.props;
    const {
      categoryNewId,
      original_image,
      img_circle_small,
      img_circle_medium,
      img_square,
      img_rect,
    } = this.state;
    if (!original_image) {
      return (
        <Dropzone
          className="ModalCommunity__Dropzone"
          type={TypeDropzone.IMAGE_CATEGORY}
          categoryId={categoryId || categoryNewId}
          fieldName="category[image]"
          onUploadComplete={this.handlerUploadComplete}
          onUploadStart={this.handlerUploadStart}
          onError={() => this.setProcessing(false)}
          subMsg={this.messageImage()}
        />
      );
    }
    return (
      <>
        <div className="ModalCommunity__Image">
          <CropCommunity
            id={categoryId || categoryNewId}
            image={original_image}
            onCropStart={() => this.setProcessing(true)}
            onCropEnd={(responseData) => {
              this.setCroppedImages(responseData);
              this.setProcessing(false);
            }}
            onCropError={() => {
              this.setProcessing(false);
            }}
          />
        </div>
        <div className="ModalCommunity__ImagePreviews">
          <div className="ModalCommunity__PreviewCircleSmall" style={{ backgroundImage: `url('${img_circle_small}')`}} />
          <div className="ModalCommunity__PreviewCircleMedium" style={{ backgroundImage: `url('${img_circle_medium}')`}} />
          <div className="ModalCommunity__PreviewSquare" style={{ backgroundImage: `url('${img_square}')`}} />
          <div className="ModalCommunity__PreviewRect" style={{ backgroundImage: `url('${img_rect}')`}} />
        </div>
      </>
    );
  }

  renderActionsEdit() {
    const { step, isProcessing } = this.state;
    return (
      <>
        {step === 1 && (
          <button
            type="button"
            className="button button--prime"
            onClick={this.handlerClickNextStepEdit}
            disabled={isProcessing}
          >
            Перейти к аватару
          </button>
        )}
        {step === 2 && (
          <>
            <div className="ModalCommunity__ActionsDescription">{this.messageImage()}</div>
            {this.renderButtonChangeImage()}
            <button
              type="button"
              className="button button--prime"
              onClick={this.handlerClickFinishEdit}
              disabled={isProcessing}
            >
              Сохранить изменения
            </button>
          </>
        )}
      </>
    );
  }

  renderActionsCreate() {
    const { step, isProcessing, original_image } = this.state;
    return (
      <>
        {step === 1 && (
          <button
            type="button"
            className="button button--prime"
            onClick={this.handlerClickNextStepCreate}
            disabled={isProcessing}
          >
            Продолжить
          </button>
        )}
        {step === 2 && (
          <>
            {original_image && (
              <div className="ModalCommunity__ActionsDescription">
                {this.messageImage()}
              </div>
            )}
            {this.renderButtonChangeImage()}
            <button
              type="button"
              className={classNames("button", {
                'button--prime': original_image,
                'button--default-fill2': !original_image,
              })}
              onClick={this.handlerClickFinishCreate}
              disabled={isProcessing || !original_image}
            >
              Создать сообщество
            </button>
          </>
        )}
      </>
    );
  }

  renderButtonChangeImage() {
    const { isProcessing, original_image } = this.state;
    if (!original_image) return null;
    return (
      <label
        className="ModalCommunity__ChangeImage button button--default-fill3"
        role="button"
        disabled={isProcessing}
      >
        {!isProcessing && (
          <input className="ModalCommunity__ChangeImageInput" type="file" onChange={this.handlerChangeFile} />
        )}
        Заменить аватар
      </label>
    );
  }

  render () {
    const {categoryId} = this.props;
    const {step, isFetch, isFetchError} = this.state;
    return (
      <div className="ModalCommunity">
        <ModalWindow
          title={categoryId ? 'Редактировать сообщество' : 'Создание сообщества'}
          onClose={this.handlerCloseModal}
          headerWithLine
        >
          <div className="ModalCommunity__Content">
            {isFetch ? (
              <>
                {isFetchError ? (
                  <span style={{"color": "red"}}>Ошибка редактирования</span>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: preloader }} />
                )}
              </>
            ) : (
              <>
                <div className="ModalCommunity__ContentWrapper">
                  {step === 1 && this.renderStep1()}
                  {step === 2 && this.renderStep2()}
                </div>
                <div className="ModalCommunity__Actions">
                  {categoryId ? this.renderActionsEdit() : this.renderActionsCreate()}
                </div>
              </>
            )}
          </div>
        </ModalWindow>
      </div>
    );
  }
}

ModalCommunity.propTypes = {
  categoryId: PropTypes.number,
  onClose: PropTypes.func,
};

export default ModalCommunity;
