import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ProgressCircle.sass';

class ProgressCircle extends PureComponent {
  render() {
    const { className, size, progress } = this.props;
    return (
      <div className={classNames('ProgressCircle', className)} style={{ width: size, height: size }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 60 60"
          fill="none"
        >
          <circle
            cx="30"
            cy="30"
            r="27"
            strokeWidth="6"
            fill="none"
            stroke="currentColor"
            strokeDashoffset={170 - 170 * progress}
            strokeDasharray="170"
          />
        </svg>
      </div>
    );
  }
}

ProgressCircle.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
};

export default ProgressCircle;
