import './header';
import './competition';
import './datepicker';
import './dropzone';
import './errors';
import './filter';
import './left_block';
import './left_menu';
import './loader';
import './modal';
import './search';
import './stats';
import './article-stat';
import './pagination';
import './tag-editor';
import './button';
import './tooltipster';
import './forms';
import './new-article';
import './cable';
import './notification';
import './avatar';
import './comment-article';
import './shadowable';
import './subscribe-form';
import './badge';
import './moderate';
import './admin';
import './info';
import './top-menu';
import initSelect from './select';
import initSecondMenu from './second-menu';
import './post';
import initPopup from './popup_main';
import initSharePopup from './share-popup';
import './dashboard';
import './voting';
import './options-menu';
import './infinity-scroll';
import './community.scss';
import initUserArticle from './user-articles';
import './user-comments';
import {cBanner as InitCBanner} from './competition-banners';
import './c-post';
import initAnchor from './anchor';
import './empty-data.sass';
import './import-users-modal';
import initMenuTab from './menu-tab';
import initCommunityRec from './community-recommendation';
import initScopeSwitchNoty from  './switch-scope-notify';
import './empty-filtered-data.sass';
import iniMoreActions from './more_actions';

export default () => {
  initSelect();
  initSharePopup();
  initUserArticle();
  initAnchor();
  initMenuTab();
  initCommunityRec();
  initScopeSwitchNoty();

  const $secondMenu = $('.second-menu');
  if ($secondMenu.length) {
    initSecondMenu($secondMenu);
  }

  const $initPopup = $('.popup-main');
  if ($initPopup.length) {
    initPopup($initPopup);
  }

  const $initCBanner = $('.c-banner');
  if($initCBanner) {
    InitCBanner();
  }

  iniMoreActions();
};
