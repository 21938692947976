import React, {PureComponent} from 'react';
import classNames from 'classnames';
import axios from 'axios';
import debounce from "lodash-es/debounce";
import PropTypes from 'prop-types';
import pluralize from 'pluralize-ru';
import Modal from '~/editor2/components/Modal';
import './index.sass';

const { CancelToken } = axios;
const MEMBER_PLACEHOLDER_COUNT = 7;


export class ModalQuiz extends PureComponent {

  state = {
    members: null,
    activeTab: null,
    isLoading: true
  };

  axios_source = null;

  debouncedGettingMember = debounce(() => {
    this.getMembers()
      .finally(() => this.setState({isLoading: false}));
  }, 150)

  componentDidMount() {
    const {activeAnswer} = this.props;
    this.activeAnswer && this.requestData(activeAnswer);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {activeAnswer, isOpen} = this.props;
    if(prevProps.isOpen === false && isOpen === true) {
      this.requestData(activeAnswer);
    }
  }

  cancelToken = () => {
    if (this.axios_source) {
      this.axios_source.cancel('new request');
    };
    this.axios_source = CancelToken.source();
  };


  handleChangeTab = (event, activeTab) => {
    event.preventDefault();
    this.requestData(activeTab);
  };

  renderTabs = () => {
    const {answers: tabs} = this.props;
    const {activeTab} = this.state;
    return tabs.map((tab, index) => (
        <span
          key={index.toString()}
          className={classNames("Modal-Quiz__Tab", {
            "Modal-Quiz__Tab--Active": activeTab && activeTab.id == tab.id
          })}
          onClick={e => this.handleChangeTab(e, tab)}
        >
          { tab.answer }
        </span>
      ));
  };

  renderMembers = () => {
    const { members, isLoading } = this.state;

    if(isLoading) {
      return this.renderMembersPlaceholder();
    }
      return members?.length
        ? members.map(({name, url, avatar_tag}, index) => (
            <a href={url} className="Modal-Quiz__Member" key={index.toString()}>
              <div className="Modal-Quiz__Member-Avatar" dangerouslySetInnerHTML={{ __html: avatar_tag }} />
              <div className="Modal-Quiz__Member-Name">{name}</div>
            </a>
          ))
        : <div style={{ textAlign: "center", flexGrow: 1 }}>За этот вариант еще не голосовали</div>;

  };

  renderMembersPlaceholder = () => Array(MEMBER_PLACEHOLDER_COUNT).fill(null).map((_, index) => (
      <div className="Modal-Quiz__Member ph-item" key={index.toString()}>
        <div className="ph-col-12">
          <div className="ph-avatar" />
          <div className="ph-row">
            <div className="ph-col-12" />
            <div className="ph-col-12" />
          </div>
        </div>
      </div>
    ));

  getMembers = () => {
    const { activeTab } = this.state;
    return axios.get(
      `/api/v1/quiz/answers/${activeTab.id}/members.json`,
      {
        cancelToken: this.axios_source.token
      },
      )
      .then(resp => {
        this.setState({
          members: resp.data
        });
      })
      .catch(err => {
        !axios.isCancel(err) && console.log(err, 'Ошибка получения пользователей');
      });
  };

  requestData = (activeTab) => {
    this.cancelToken();
    this.setState(
      {
        activeTab: typeof activeTab !== "undefined"
          ? activeTab
          : this.state.activeTab,
        isLoading: true,
        members: null,
      },
      this.debouncedGettingMember
    );
  };

  render() {
    const {quiz, isOpen, onClose} = this.props;
    const { activeTab } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        portalClassName="Modal-Quiz"
      >
        <div className="Modal-Quiz__Head">
          <h3 className="Modal-Quiz__Title">
            {quiz.question}
          </h3>
        </div>
        <div className="Modal-Quiz__Body">
          <div className="Modal-Quiz__Tabs">
            { this.renderTabs() }
          </div>
          {activeTab && (
            <div className="Modal-Quiz__Statistic">
              <div className="Modal-Quiz__Statistic-Head">
                <div className="Modal-Quiz__Statistic-Label">{activeTab.answer}</div>
                <div className="Modal-Quiz__Statistic-Value">{activeTab.percent} %</div>
              </div>
              <div className="Modal-Quiz__Statistic-Range">
                <span style={{width: `${ activeTab?.percent }%`}} />
              </div>
              <div className="Modal-Quiz__Statistic-Footnote">
                {activeTab.amount}
                {' '}
                {pluralize(activeTab.amount, 'голосов', 'голос', 'голоса', 'голосов')}
              </div>
            </div>
          )}
          <div className="Modal-Quiz__Members">
            { this.renderMembers() }
          </div>
        </div>
      </Modal>
    );
  }
}

ModalQuiz.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  quiz: PropTypes.any,
  answers: PropTypes.array,
  activeAnswer: PropTypes.object,
};

export default ModalQuiz;
