import slick from 'slick-carousel';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './longgrea-slider.sass'


$(document).on('turbolinks:load', function() {

    $('.longread-slider').slick({
        infinite: false,
        dots: true,
    })

});