import './index.sass'
import axios from 'axios'
import { loader } from './loader'
import debounce from 'lodash-es/debounce'

function refreshArticles() {
  axios.get('/').then(response => {
    $('#index_list').html(response.data)
  })
}

$(document).ready(function () {
  if ($('.js-greet-popup').length === 0 ) return
  $.magnificPopup.open({
    fixedBgPos: true,
    fixedContentPos: true,
    closeOnBgClick: false,
    enableEscapeKey: false,
    showCloseBtn: false,
    mainClass: 'greet-wrap',
    items: [
      {
        src: '/greet',
        type: 'ajax'
      }
    ],
    callbacks: {
      beforeOpen(){
        $('html, body').css({
          height: '100%',
          overflow: 'hidden'
        });
      },

      afterClose(){
        $('html, body').css({
          height: '',
          overflow: ''
        });
      },

      ajaxContentAdded() {
        loader('#js-react-greet', () => {
         axios.post('/users/greeting_watched').then(() => {
            $.magnificPopup.close()
          }).catch(err => {
            alert('Ошибка')
            console.error(err)
          })
        }, debounce(refreshArticles, 200))

      }
    }
  });

});
