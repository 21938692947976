import React, {useCallback, useEffect, useState} from 'react';
import PropsType from 'prop-types';
import classNames from 'classnames';
import {format, isFuture, isPast} from "date-fns";
import ru from 'date-fns/locale/ru';
import pluralize from 'pluralize-ru';

import './QuizShow.sass';
import {QuizOption} from './QuizOption';
import {QuizProgressBar} from './QuizProgressBar';
import {QuizChangeVote} from './QuizChangeVote';
import {QuizFormat, QuizStyles} from "./QuizEdit";
import {RCTooltipCustom} from '../../components/RCTooltip';
import {ModalQuiz} from "../../components/Modal/ModalQuiz";

const getPercentage = (votes, totalVotes) => {
  if (totalVotes === 0 || totalVotes == null) {
    return  0;
  }
  return +((votes / totalVotes) * 100).toFixed(2);
};

function useForceUpdate(){
  const [_, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

export const QuizShow = (props) => {
  const {quiz, answers, users, isVoting, isReVote, isEditable, onReVote, onVote, onEdit} = props;

  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showAnswerIndex, setShowAnswerIndex] = useState(0);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const forceUpdate = useForceUpdate();

  const isAnonymous = QuizFormat.Anonymous === quiz.format;
  const isMulti = quiz.is_multi;
  const isCanVote = (!quiz.is_voted && (!quiz.date_end || isFuture(quiz.date_end)))
    || isReVote
    || isEditable;

  const modalAnswer = answers.map(({id, answer, total_votes}) => ({
    id,
    answer,
    percent: getPercentage(total_votes, quiz.total_votes),
    amount: total_votes,
  }));

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (onVote) {
      onVote(selectedAnswers);
    }
  }, [quiz, selectedAnswers]);

  const handleChangeAnswer = event => {
    const {checked, value} = event.target;
    const newValue = +value;
    if (isMulti) {
      if (checked) {
        setSelectedAnswers([...new Set([...selectedAnswers, newValue])]);
      } else {
        setSelectedAnswers(selectedAnswers.filter(id => id !== newValue));
      }
    } else {
      setSelectedAnswers([newValue]);
    }
  };

  const checkShowChangeVote = () => quiz.is_voted &&
      !quiz.is_disallow_revote &&
      (!quiz.date_end || !isPast(quiz.date_end));

  const isShowChangeVote = checkShowChangeVote();

  const handleChangeVote = useCallback(() => {
    if (checkShowChangeVote()) {
      onReVote();
    } else {
      forceUpdate();
    }
  }, [checkShowChangeVote]);

  useEffect(() => {
    if (isReVote) {
      setSelectedAnswers([]);
    }
  }, [isReVote]);

  return (
    <>
      <div className={classNames('QuizShow', {
        'QuizShow--ThemeLight': quiz.style === QuizStyles.Light,
      })}>
        <form className="QuizShow__form" onSubmit={handleSubmit}>
          <div className="QuizShow__heading">
            <div className="QuizShow__type">
              {isAnonymous ? <span>Анонимный опрос</span> : <span>Публичный опрос</span>}
              <span className="QuizShow__circle"/>
              {isMulti ? <span>Множественный выбор</span> : <span>1 вариант ответа</span>}
            </div>
            <div className="QuizShow__title">
              {quiz.question}
            </div>
            {quiz.date_end && (
              <>
                {isPast(quiz.date_end) ? (
                  <div className="QuizShow__finish">Опрос завершен</div>
                ) : (
                  <div className="QuizShow__finish">
                    Опрос завершится
                    {' '}
                    <span>{format(quiz.date_end, 'dd MMMM в HH:mm', { locale: ru })}</span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="QuizShow__body">
            {isCanVote ? (
              <div className="QuizShow__QuestionList">
                {answers.map(item => (
                  <QuizOption
                    key={item.id}
                    value={item.id}
                    label={item.answer}
                    name={isMulti ? `answer[${item.id}]` : "single_answer"}
                    type={isMulti ? "checkbox" : "radio"}
                    checked={selectedAnswers.includes(item.id)}
                    disabled={isEditable || isVoting}
                    onChange={handleChangeAnswer}
                  />
                ))}
              </div>
            ) : (
              answers.map(({id, answer, total_votes, is_choice}, index) => (
                <QuizProgressBar
                  key={id}
                  label={answer}
                  votes={total_votes}
                  progressValue={getPercentage(total_votes, quiz.total_votes)}
                  isChoice={is_choice}
                  isAnonymous={isAnonymous}
                  onClick={() => {
                    setShowAnswerIndex(index);
                    setShowAnswerModal(true);
                  }}
                />
              ))
            )}
          </div>
          {isCanVote ? (
            <div className="QuizShow__footer">
              {isEditable ? (
                <button
                  className={classNames("button button--lg button--wide", {
                    "button--prime": quiz.style === QuizStyles.Light,
                    "button--default": quiz.style === QuizStyles.Green,
                  })}
                  onClick={onEdit}
                >
                  Редактировать
                </button>
              ) : (
                <RCTooltipCustom
                  overlay={
                    <>
                      Выберите хотя бы один из вариантов ответа,<br/>
                      чтобы продолжить голосование
                    </>
                  }
                  placement="top"
                  trigger={selectedAnswers.length === 0 || isVoting ? ['hover'] : []}
                >
                  <div style={{ width: '100%' }}>
                    <button
                      className="QuizShow__btn"
                      disabled={selectedAnswers.length === 0 || isVoting}
                    >
                      Проголосовать
                    </button>
                  </div>
                </RCTooltipCustom>
              )}
            </div>
          ) : (
            <div className={classNames('QuizShow__footer', {
              'QuizShow__footer--space--between': isShowChangeVote,
            })}>
              <div className="QuizShow__UsersList">
                {!isAnonymous && users && users.length > 0 && (
                  <div className="QuizShow__UsersWrap">
                    {users
                      .slice(0, 3)
                      .reverse()
                      .map(({avatar_tag}, index) => (
                        <div
                          className="QuizShow__user"
                          key={index.toString()}
                          dangerouslySetInnerHTML={{ __html: avatar_tag }}
                        />
                      ))}
                  </div>
                )}
                <div className="QuizShow__total">
                  {quiz.total_users === 1 ? "Проголосовал" : "Проголосовали"}
                  {' '}
                  {quiz.total_users || 0}
                  {' '}
                  {pluralize(quiz.total_users || 0, 'человек', 'человек', 'человека', 'человек')}
                </div>
              </div>
              {isShowChangeVote && (
                <QuizChangeVote handleChangeVote={handleChangeVote}/>
              )}
            </div>
          )}
        </form>
      </div>

      <ModalQuiz
        isOpen={showAnswerModal}
        quiz={quiz}
        answers={modalAnswer}
        activeAnswer={modalAnswer[showAnswerIndex]}
        onClose={() => {
          setShowAnswerModal(false);
        }}
      />
    </>
  );
};

QuizShow.propTypes = {
  quiz: PropsType.any,
  answers: PropsType.any,
  users: PropsType.any,
  isEditable: PropsType.bool,
  isVoting: PropsType.bool,
  isReVote: PropsType.bool,
  onVote: PropsType.func,
  onEdit: PropsType.func,
  onReVote: PropsType.func,
};

export default QuizShow;
