import "./index.sass"

function singleLineString(strings, ...values) {
  // Interweave the strings with the
  // substitution vars first.
  let output = '';
  for (let i = 0; i < values.length; i++) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  let lines = output.split(/(?:\r\n|\n|\r)/);

  // Rip out the leading whitespace.
  return lines.map((line) => {
    return line.replace(/^\s+/gm, '');
  }).join(' ').trim();
}

const template = singleLineString`<div class="presentation" allowfullscreen>
  <canvas class="presentation__pdfjs"></canvas>
  <div class="presentation__progress_bar">
    <div class="presentation__status"></div>
  </div>
  <div class="presentation__navigation">
    <a target="_blank" class="presentation__download">Скачать</a>
    <button class="presentation__button presentation__prev">назад</button>
    <span class="presentation__current-page"></span> / <span class="presentation__total-pages"></span>
    <button class="presentation__button presentation__next">вперед</button>
    <button class="presentation__button presentation__fullscreen">Во весь экран</button>
    <button class="presentation__button presentation__fullscreen-exit">Выйти из полноэкранного режима</button>
  </div>
</div>
`

function fullScreenChange() {
  if (document.fullscreenElement !== null ) {
    //console.log("enter fullscreen", document.fullscreenElement)
    var $el = $(document.fullscreenElement)
    if ($el.hasClass("presentation")) {
      $el.addClass("presentation--fullscreen")
    }
  } else {
    //console.log("exit fullscreen")
    var $el = $(".presentation--fullscreen")
    $el.removeClass("presentation--fullscreen")
  }
}

export function initPdf(render, $el, src) {
  $el.html(template)
  var $pel = $el.find(".presentation")

  $pel.find('.presentation__fullscreen').on("click", function() {
    document.addEventListener("fullscreenchange", fullScreenChange)

    var el = $pel[0]
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  });

  $pel.find('.presentation__fullscreen-exit').on("click", function() {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  });

  $pel.find(".presentation__download").attr("href", src)

  render($pel, src)
}

export function loadPdfjs() {
  return import(/* webpackChunkName: "pdf" */ './index.es6').then(PImport => {
    return Promise.resolve(PImport.default)
  })
}

document.addEventListener("turbolinks:load", function() {
  document.removeEventListener("fullscreenchange", fullScreenChange)

  var $pdfs = $(".js-pdf").not(".done")
  if ($pdfs.length > 0) {
    loadPdfjs().then((render) => {
      $pdfs.each(function() {
        var $t = $(this)
        $t.addClass("done")
        initPdf(render, $t, $t.data("src"))
      })
    })
  }
})
