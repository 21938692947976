var readyApp = function() {
    initLables();
    initFixedBlock();
    //initEditor();
    initTags();

    setTimeout(function(){
        initLables();
    },2000);


    // $('html.no_scroll').removeClass('no_scroll');

    $('.product_text_equal').matchHeight();

    $('.toggle_block').each(function() {
        initToggleBlock($(this));
    })

    $('.show_search').click(function() {
        hideNotifications();
        hideUserBlock();

    })

    function hideNotifications(){
      $('html').removeClass('no_scroll');
      $('#notification').empty();
    }

    function hideUserBlock(){
      // $('html').removeClass('no_scroll');
      $('#header .user_block').removeClass('open');
    }

    $('.competition_ a.make_').on('click', function(){
        if($(window).outerWidth() <= 767) {
            return false;
        }
    })
    $('.competition_ a.make_').hover(function() {
        $(this).closest('.competition_').addClass('hover');
    }, function() {
        $(this).closest('.competition_').removeClass('hover');
    })

    $('.alert').on('click', function() {
        $(this).remove();
    });

    $('.left_menu_mobile_fixed a.active').on('click', function(){
        if($(window).outerWidth() <= 767) {
            var overlay = $(this).closest('.left_menu_mobile_fixed').prev('.left_menu_mobile_fixed_overlay');
            if(overlay.hasClass('open')) {
                overlay.removeClass('open');
            } else {
                overlay.addClass('open');
            }
            return false;
        }
    });

    $('.left_menu_mobile_fixed_overlay').on('click touchend', function(){
        $(this).removeClass('open');
    });


    $('#user_email_domain, #article_category_id').selectmenu({
        'width': 180,
        'position': {
            my: "center top+30",
            at: 'center center'
        },
        create: function(event, ui) {
            if ($(event.target).val() != '') {
                $('#' + $(event.target).attr('id') + '-button').addClass('select');
                if ($(event.target).attr('id') == 'article_category_id') {
                    $('#' + $(event.target).attr('id') + '-button').find('.ui-selectmenu-text').addClass('green');
                }
            } else {
                $('#' + $(event.target).attr('id') + '-button').removeClass('select');
            }
        },
        open: function(event, ui) {
            var index = $(event.target).find("option:selected").index();
            if($('#ui-id-' + (index + 1)).text().toLowerCase().indexOf("выберите") >= 0) {
                $('#ui-id-' + (index + 1)).closest('.ui-menu-item').next('.ui-menu-item').addClass('ui-select');
                $('#ui-id-' + (index + 1)).hide();
            } else {
                $('#ui-id-' + (index + 1)).addClass('ui-select');
            }
        },
        select: function(event, ui) {
            $(event.toElement).closest('ul').find('.ui-select').removeClass('ui-select');
            $(event.toElement).addClass('ui-select');
            if ($(ui.item.element).val() != '') {
                $('#' + $(event.target).attr('id') + '-button').addClass('select');
                if ($(event.target).attr('id') == 'article_category_id') {
                    $('#' + $(event.target).attr('id') + '-button').find('.ui-selectmenu-text').addClass('green');
                }
            } else {
                $('#' + $(event.target).attr('id') + '-button').removeClass('select');
            }
            var form = $(ui.item.element).closest('form');
            if (form.hasClass('editable_form')) {
                form.ajaxSubmit(window.formSubmitConfig());
            }
        }
    });



    //$(document).on("mouseup touchend", function(e) {
        //var s_container = $('#search_popup');
        //if (s_container.length) {
            //if (!s_container.is(e.target) && s_container.has(e.target).length === 0) {
                //$('#search_popup').fadeOut(150, function() {
                    //$(this).remove();
                //})
            //}
        //}
    //});

    $(document).on('mouseup touchend', function (e) {
      if(!$(e.target).closest('#header .user_block').length) {
        hideUserBlock();
      }
      if(!$(e.target).closest('#notification').length && !$(e.target).closest('.notification_onboard').length && !$(e.target).closest('#after_header_menu').length){
        
        hideNotifications();
      }
    });

    $('#header .notification_onboard').on('click', function () {
      $(document).trigger('hide-search');
      hideUserBlock();
    })


    $('#header .user_block .avatar').on('click', function(event){
      $(document).trigger('hide-search');
       $(this).closest('.user_block').toggleClass('open');
       $('html').toggleClass('no_scroll');
    });



    if ($("#article_description")) {
        var str = $("#article_description").text().split("\n")
        str.forEach(function(data) {
            $("#article_description_with_newline").append("<p class='black size15 normal'>" + data + "</p>")
        })
    }

    $('.tag_edit input[type=text]').each(function() {
        resizeInputWidth($(this), $(this).prev('em'));
    });

    //$('.author_tooltip.left_t').tooltipster(initTooltip('left'));

    //$('.author_tooltip.top_t').tooltipster(initTooltip('top'));

    //$('.author_tooltip.bottom_t').tooltipster(initTooltip('bottom'));

    //$('.author_tooltip.right_t').tooltipster(initTooltip('right'));

    $('.contenteditable').each(function() {
        contenteditableInit($(this));
    });
    $('.content_edit').each(function() {
        contenteditInit($(this));
    });

    $('[contenteditable]').on('paste', function(e) {
        var cd = (e.originalEvent || e).clipboardData
        var text
        if (cd) {
          e.preventDefault();
          text = cd.getData('text/plain') || prompt('Paste something..');
          window.document.execCommand('insertText', false, text);
        } else {
          // for IE 11 do nothing
          //text = window.clipboardData.getData('Text')
        }
    });

    $('form#new_user').submit(function() {
        var errors = 2,
            form = $(this),
            email = form.find('input#user_email').val(),
            pass = form.find('input#user_password').val();
        form.find('.form_error').removeClass('form_error');
        if (email != '') {
            errors--;
        } else {
            form.find('input#user_email').closest('.form_item').addClass('form_error');
        }

        if (pass != '') {
            errors--;
        } else {
            form.find('input#user_password').closest('.form_item').addClass('form_error');
        }

        if (errors == 0) {
            return true;
        } else {
            return false;
        }
    });

    $('form#new_user_reset').submit(function() {
        var errors = 1,
            form = $(this),
            email = form.find('input#user_email').val();
        form.find('.form_error').removeClass('form_error');
        if (email != '') {
            errors--;
        } else {
            form.find('input#user_email').closest('.form_item').addClass('form_error');
        }

        if (errors == 0) {
            return true;
        } else {
            return false;
        }
    });

    $('form#new_user_register').submit(function() {
        var errors = 5,
            form = $(this),
            lname = form.find('input#user_lname').val(),
            fname = form.find('input#user_fname').val(),
            email = form.find('input#user_email').val(),
            pass = form.find('input#user_password').val();
        repass = form.find('input#user_password_confirmation').val();
        form.find('.form_error').removeClass('form_error');

        if (lname != '') {
            errors--;
        } else {
            form.find('input#user_lname').closest('.form_item').addClass('form_error');
        }
        if (fname != '') {
            errors--;
        } else {
            form.find('input#user_fname').closest('.form_item').addClass('form_error');
        }

        if (email != '') {
            errors--;
        } else {
            form.find('input#user_email').closest('.form_item').addClass('form_error');
        }

        if (pass != '') {
            errors--;
        } else {
            form.find('input#user_password').closest('.form_item').addClass('form_error');
        }

        if (repass != '') {
            errors--;
        } else {
            form.find('input#user_password_confirmation').closest('.form_item').addClass('form_error');
        }

        if (errors == 0) {
            return true;
        } else {
            return false;
        }
    });

    $('.subscribe_user_header button').on('click', function() {
        var form = $('form.subscribe_user_header'),
            text = (!form.hasClass('unsubscribe')) ? 'Подписка оформлена' : 'Подписка отменена';
            form.first()[0].submit();

        $('.subscribe_user_header').each(function() {
            $(this).replaceWith('<span class="subscribe_user_status">' + text + '</span>');
        })
        setTimeout(function() {
            $('span.subscribe_user_status').addClass('ready');
            location.reload();
        }, 500);
    });

    $('a.photogrid_modal').on('click', function(){
        var img = $(this).attr('href');
        showImgModal(img);
        return false;
    });

    $('.video-tip').on('click', function(){
        var video = $(this).data('video');
        showVideoModal(video);
        return false;
    })
    $(window).resize(function() {
        initFixedBlock();
        $('.product_text_equal').matchHeight();
    })

    $(window).scroll(function() {
        if ($('.fixed_edit_block').length) {
            if ($(window).scrollTop() > $('.fixed_edit_block_pseudo').offset().top) {
                $('.fixed_edit_block').addClass('fixed');
            } else {
                $('.fixed_edit_block').removeClass('fixed');
            }
        }
    });

  $('.title-wrap').autoEllipsisText ({
    multiline: true
  });
}

function msieversion() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0) {
        return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)));
    } else {
        return 0;
    }
}
$(document).ready(function(){
    if(msieversion() == 9) {
      readyApp();
    }
});
$(document).on('turbolinks:load', readyApp);
