import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";

import Icon from './icon.r.svg';
import constants from './constants';
import insertDataBlock from '~/editor2/utils/draftjs/insertDataBlock';
import {withEditorContentContext} from "~/editor2/components/EditorContent/EditorContentContext";

class Button extends PureComponent {
  onClick = () => {
    const { article = {}, onChange, editorState } = this.props;
    const { id } = article;

    axios.post('/api/v1/quizzes', { article_id: id }).then(resp => {
      return axios
        .put(`/api/v1/quizzes/${resp.data.id}`, {
          quiz: {answers_attributes: [{answer: ''},{answer: ''}]}
        })
        .then(() => {
          const data = {
            type: constants.PLUGIN_TYPE,
            id: resp.data.id,
            isNew: true,
            isFilled: false,
          };
          onChange(insertDataBlock(editorState, data));
        });
    }).catch(err => {
      console.error(err);
      alert('Не удалось создать опрос.');
    });
  }

  render() {
    const { className } = this.props;

    return (
      <button
        className={className}
        type="button"
        onClick={this.onClick}
        title={constants.PLUGIN_TITLE_BUTTON}
      >
        <Icon />
      </button>
    );
  }
}

Button.propTypes = {
  article: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.any,
  editorState: PropTypes.any,
};

export default withEditorContentContext(Button);
