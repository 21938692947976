import React, {useContext} from 'react';

export const EditorContentContext = React.createContext(null);

export const useEditorContent = () => {
  const ctx = useContext(EditorContentContext);
  if (!ctx) {
    throw new Error('EditorContentContext not provided');
  }
  return ctx;
};

export const withEditorContentContext = (WrappedComponent) => {
  return function withEditorContentContextWrapper(props) {
    const ctx = useEditorContent();
    return <WrappedComponent {...ctx} {...props} />;
  };
};
