import axios from 'axios'
import flash from '~/blocks/flash'
import debounce from 'lodash-es/debounce'
import userTemplate from './user.pug';
import Turbolinks from 'turbolinks'
import './user_roles';

function autocomplete(event) {
  axios.get('/api/v1/search/users', {params: {
    category_id: $(this).data('category-id'),
    q: $(this).val()
  }}).then(resp => {
    $('.js-admin-new-user-role-user-list').html(resp.data.list.map(user=>{
      return userTemplate(user)
    }).join("\n"))
    console.log(resp.data)
  }).catch(err => {
    console.log(err)
    flash({error: 'Произошла ошибка'})
  })
}

function initUserSearchForm() {
  $('.js-admin-user-roles-new-search').on('input', debounce(autocomplete, 200))
  $('.js-admin-user-roles-new-search-form').on('submit', function(e) {
    e.preventDefault()
  })
  window.initLables()
}

function initUserSubmit() {
  $('#add_users_to_category').on('submit', function(event) {
    event.preventDefault();
    axios.post($(this).attr('action'), $(this).serialize()).then(resp => {
      Turbolinks.visit(window.location)
      window.closeModal()
    }).catch(err => {
      console.log(err)
      flash({error: 'Произошла ошибка'})
    })
  })
}

function initAddUser() {
  $('.js-admin-category-add-user').on('click', function(event) {
    event.preventDefault();
    axios.get($(this).attr('href')).then(resp => {
      window.showModal(resp.data)
      initUserSearchForm()
      initUserSubmit();
    }).catch(err => {
      console.error(err);
      flash({error: 'Произошла ошибка'})
    })
  })
}

function definationEmpty(resp){
  return resp.data.indexOf('subscribe_author_item') > -1 ? true : false;
};

function filterUsers() {
  const $root = $('#form_search');
  if ($root.length === 0) return;
  const $searchInput = $('#search_param');
  const $initialList = $('#users')[0].outerHTML;
  const $searchBtn = $('#find_user');
  const $searchBtnClose = $('#find_user_close');

  $searchBtn.on('click', function(e){
    e.stopPropagation();
    $(this).closest('#admin_search_users').addClass('show_search_');
    $('#search_param').focus()
  });

  $searchBtnClose.on('click', function(e){
    e.stopPropagation();
    $(this).closest('#admin_search_users').removeClass('show_search_');
    $searchInput.val('');
    $('#users').replaceWith($initialList)
  });

  $root.on('submit', function(event) {
    console.log('submit');
    event.preventDefault()
    if ($searchInput.val() === '') {
      $('#users').replaceWith($initialList);
      return false;
    }
    axios.get($root.attr('action'), {
      params: {
          q: $searchInput.val()
        }
      })
      .then(resp => {
        if(definationEmpty(resp)){
          $('#users').replaceWith(resp.data);
        }else{
          $('#users').replaceWith("<div id='users'><div class='subscribe_author_list' id='category_users_infinity_scroll'><span class='user-filter__notification'>Извините, по вашему запросу ничего не найдено!</span></div></div>")
        }

      })
      .catch(err => {
        console.error(err)
      });
    return false
  });
}


function init() {
  initAddUser();
  filterUsers();
}

$(document).on('turbolinks:load', init);
