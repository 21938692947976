import React, {Component} from 'react';
import ReactCrop, {getPixelCrop} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './crop.sass';
import PropTypes from 'prop-types';

class Crop extends Component {
  state = {
    updating: false,
    updated: false,
    crop: {
      aspect: this.props.aspect,
      ...this.props.crop,
    }
  }

  submit() {
    this.props.onSubmit(this.state.pixelCrop);
  }

  renderButton() {
    const { submitBtn, onSubmitComplete } = this.props;
    return <button className="editor__update" onClick={() => {
      this.submit();
      onSubmitComplete && onSubmitComplete(this.state.pixelCrop);
    }}>{submitBtn}</button>;
  }

  componentDidUpdate() {
    if (!this.state.pixelCrop) return;
    if (this.state.updated) return;
    if (+this.props.crop.width > 0) return;
    if (this.state.updating) return;
    this.setState({updating: true});
    this.props.onSubmit(this.state.pixelCrop).then(
      () => this.setState({updated: true})
    ).catch(err=>{
      console.error(err);
    }).then(()=>this.setState({updating: false}));
  }

  onImageLoaded = (image, pixelCrop) => {
    if (+this.props.crop.height > 0) {
      return;
    }
    const h = image.naturalHeight;
    const w = image.naturalWidth;
    const a = this.props.aspect;
    let crop;
    if (h * a > w) {
      const height = (w/a)/h * 100;
      crop = {
        aspect: a,
        x: 0,
        y: (100-height)/2,
        height,
        width: 100,
      };
    } else {
      const width = h*a/w * 100;
      crop = {
        aspect: a,
        x: (100 - width)/2,
        y: 0,
        height: 100,
        width,
      };
    }
    this.setState({crop, pixelCrop: {
      ...getPixelCrop(image, crop),
      naturalHeight: h,
      naturalWidth: w,
    }});
  }

  handlerChangeCrop = (crop, pixelCrop) => {
    const { pixelCrop: statePixelProp } = this.state;
    this.setState({crop, pixelCrop: { ...statePixelProp, ...pixelCrop } });
  };

  render() {
    return (
      <div
        className="editor__crop"
      >
        <ReactCrop
          src={this.props.image}
          circularCrop={this.props.circularCrop}
          disabled={this.props.disabled}
          onChange={this.handlerChangeCrop}
          onComplete={() => this.props.autoUpdate && this.submit()}
          crop={this.state.crop}
          onImageLoaded={this.onImageLoaded}
        />
        {this.props.error && (
          <div style={{ color: 'red', marginTop: 10 }}>
            Не удалось обрезать картинку. Результат обрезки не сохранен.
          </div>
        )}
        {this.props.autoUpdate ? '' : this.renderButton() }
      </div>
    );
  }
}

Crop.propTypes = {
  submitBtn: PropTypes.string,
  image: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func,
  autoUpdate: PropTypes.bool,
  aspect: PropTypes.number.isRequired,
  crop: PropTypes.object.isRequired,
  circularCrop: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

Crop.defaultProps = {
  aspect: 16/9,
  crop: {},
  submitBtn: 'Обновить',
};

export default Crop;
