import axios from 'axios';
import flash from '~/blocks/flash';

import './button-voting.sass';

import { updateStars } from '~/pages/articles/show/ratings';

function updateNumberFor($elem, number, isActive = false) {
  $elem.text(number);
  const urlGroup = $elem.closest('.js-voting-action').data('url');
  $(`[data-url="${urlGroup}"]`)
    .toggleClass('button-voting--active', isActive)
    .find('.js-voting-counters-likes, .js-voting-counters-dislikes')
    .text(number);
}


function updateStarsFor($elem, stars) {
  $(`.star-ratings__stars[data-group="${$elem.data('rating-group')}"]`).each(function() {
    updateStars($(this), stars);
  });
}

function initVoting() {
  $(document).on('click', '.js-voting-action', function handlerClickVoting(event) {
    event.preventDefault();
    const $this = $(this);
    const url = $this.data('url');
    axios
      .post(url, {})
      .then(resp => {
        const { likes, dislikes, stars } = resp.data;
        const $votingActions = $this.parent().find('.js-voting-action');
        const isActiveCurr = $this.hasClass('button-voting--active');
        $votingActions.removeClass('button-voting--active');
        $this.toggleClass('button-voting--active', !isActiveCurr);
        const isActiveLikes = $this.hasClass('button-voting--like') && $this.hasClass('button-voting--active');
        const isActiveDislikes = $this.hasClass('button-voting--dislike') && $this.hasClass('button-voting--active');
        updateNumberFor($votingActions.find('.js-voting-counters-likes'), likes, isActiveLikes);
        updateNumberFor($votingActions.find('.js-voting-counters-dislikes'), dislikes, isActiveDislikes);
        updateStarsFor($(this).closest('.js-voting'), stars);
      })
      .catch(err => {
        if (err.response) {
          flash({error: err.response.data.error || 'Произошла ошибка'})
        } else {
          console.error(err);
          flash({error: 'Произошла ошибка'});
        }
      });
  });
}

function init() {
  initVoting();
}

$(init);
