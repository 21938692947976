import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-popover';

import './PopoverError.sass';

class PopoverError extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeWindow);
  }

  handleResizeWindow = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  }

  render () {
    const { windowWidth } = this.state;
    const { error, isShow, ...props } = this.props;
    return (
      <Popover
        className="PopoverError"
        isOpen={isShow}
        place={windowWidth < 1279 ? 'below' : 'left'}
        body={<div>{error}</div>}
        style={{ zIndex: 2000 }}
        enterExitTransitionDurationMs={0}
        tipSize={10}
        appendTarget={document.body}
        {...props}
      />
    );
  }
}

PopoverError.propTypes = {
  error: PropTypes.any,
  isShow: PropTypes.bool,
};


export default PopoverError;
