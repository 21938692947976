import parse from 'date-fns/parse';
import toDate from 'date-fns/toDate';

export function old() {

  $('#competition_article_type_id, #competition_category_id').selectmenu({
    classes: {
      "ui-selectmenu-menu": "competitions-selectmenu-menu",
      "ui-selectmenu-button": 'competitions-select-button'
    },
    open: function( event, ui ) {
      var index = $(event.target).find("option:selected").index();
      $('#ui-id-'+(index+1)).addClass('ui-select');
    },
    select: function( event, ui ) {
      $(event.toElement).closest('ul').find('.ui-select').removeClass('ui-select');
      $(event.toElement).addClass('ui-select');
      var form = $(ui.item.element).closest('form');
      if(form.hasClass('editable_form')){
        form.ajaxSubmit(window.formSubmitConfig());
      }
    }
  });

  $('.js-competitions-select').selectmenu({
    'width' : 104,
    classes: {
      "ui-selectmenu-menu": "competitions-selectmenu-date",
      "ui-selectmenu-button": 'competitions-select-button-date'
    },
  }).on('selectmenuchange', function () {
    $(this).trigger('change_date');
  });

  $('.js-competitions-datepicker').attachDatepicker({
        showAnim:'',
        rangeSelect: false,
        changeYear: false,
        changeMonth: false,
        mandatory: true,
        dateFormat: 'dd.mm.yy',
        minDate: new Date(),
        showOtherMonths: true,
        onSelect: function(date) {
          const $this = $(this);
          const newDate = toDate(parse(date, 'dd.MM.yyyy', new Date()));
          $this.trigger('change_date');
          if ($this.hasClass('js-competitions-datepicker-start')) {
            $('.js-competitions-datepicker-end').changeDatepicker('minDate', newDate);
          }
          if ($this.hasClass('js-competitions-datepicker-end')) {
            $('.js-competitions-datepicker-start').changeDatepicker('maxDate', newDate);
          }
          window.initLables();
        }
    });

}
