import * as ActionCable from '@rails/actioncable';

try {
  // eslint-disable-next-line no-extend-native
  String.prototype.include_value = function(arr) {
    let str = this.concat();
    arr.forEach(function(element) {
      str = str.replace("?", element);
    });
    return str;
  };
} catch(e) {
  console.error(e);
}

(function() {
  try {
    this.App || (this.App = {});
    if (!process.env.NO_CABLE) {
      this.App.cable = ActionCable.createConsumer();
    }
  } catch (e) {
    console.error(e);
  }
}).call(window);

function initOnlineCable() {
  try {
    if (!App.cable) {
      return;
    }
    const a = App.cable.subscriptions.subscriptions.some(function (v) {
      return v.identifier == '{"channel":"OnlineChannel","room":"online"}'; // Filter out the appropriate one
    });
    if (!a) {
      App.cable.subscriptions.create(
        {
          channel: 'OnlineChannel',
          room: 'online'
        },
        {}
      );
    }
  } catch (e) {
    console.error(e);
  }
}

function initCable() {
  try {
    if (process.env.NO_CABLE) {
      return;
    }
    const user_id = window.userId;

    const a = App.cable.subscriptions.subscriptions.some(function(v) {
      return v.identifier == '{"channel":"NotificationChannel","room":"notification"}'; // Filter out the appropriate one
    });
    if (a) {
      return;
    }
    App.cable.subscriptions.create(
      {
        channel: 'NotificationChannel',
        room: 'notification'
      },
      {
        // Вызывается, когда подписка готова на сервере для использования.
        // connected: function() {
        //   console.log("connect")
        // },

        // Вызывается, когда закрывается соединения WebSocket.
        // disconnected: function() {
        //   console.log("disconnect")
        // },

        // Вызывается, когда подписка отвергается сервером.
        // rejected: function() {
        //   console.log("reject")
        // },

        // Вызывается, когда приходит уведомление
        received(data) {
          const {notification} = data;

          // так как канал уведомления 1, а пользователей много, то проверяем кому пришло сообщение
          if (notification.user_id != user_id) {
            return;
          }

          let text = '';
          if (notification.user_cause_lname && notification.user_cause_fname) {
            text += '<span class="avatar">??</span>'.include_value([notification.user_cause_lname[0], notification.user_cause_fname[0]]);
          }
          text += '<span class="notification_text">';
          if (notification.notification_type == 1) {
            text += "<strong>? ?</strong> прокомментировал(а) ваш материал <a href = '/article/?'>?</a><span class = 'time'>?</span>".include_value([notification.user_cause_lname, notification.user_cause_fname, notification.article_id, notification.article_title, moment(notification.created_at).format("D MMMM YYYY")]);
          }
          if (notification.notification_type == 2) {
            text += "<strong>? ?</strong> ответил(а) на ваш комментарий в материале <a href = '/article/?'>?</a><span class = 'time'>?</span>".include_value([notification.user_cause_lname, notification.user_cause_fname, notification.article_id, notification.article_title, moment(notification.created_at).format("D MMMM YYYY")]);
          }
          if (notification.notification_type == 3) {
            text += "<strong>? ?</strong> оценил(а) ваш <a href = '/article/?'>материал</a> на ? <span class = 'time'>?</span>".include_value([notification.user_cause_lname, notification.user_cause_fname, notification.article_id, notification.event_value, moment(notification.created_at).format("D MMMM YYYY")]);
          }
          if (notification.notification_type == 4) {
            text += "<strong>? ?</strong> одобрил(а) ваш <a href = '/article/?'>материал</a><span class = 'time'>?</span>".include_value([notification.user_cause_lname, notification.user_cause_fname, notification.article_id, notification.article_title, moment(notification.created_at).format("D MMMM YYYY")]);
          }
          if (notification.notification_type == 5) {
            text += "<strong>? ?</strong> подписался(лась) на ваши обновления <span class = 'time'>?</span>".include_value([notification.user_cause_lname, notification.user_cause_fname, moment(notification.created_at).format("D MMMM YYYY")]);
          }
          if (notification.notification_type == 6) {
            text += "<strong>? ?</strong> поделился(лась) с вами материалом <a href = '/article/?'>?</a><span class = 'time'>?</span>".include_value([notification.user_cause_lname, notification.user_cause_fname, notification.article_id, notification.article_title, moment(notification.created_at).format("D MMMM YYYY")]);
          }
          if (notification.notification_type == 7) {
            text += "Ваше предложение о создании сообщества ? было отклонено. <span class = 'time'>?</span>".include_value([notification.category_name, moment(notification.created_at).format("D MMMM YYYY")]);
          }
          if (notification.notification_type == 8) {
            text += "Ваша статья <a href = 'javascript:;'>?</a> отправлена в архив по решению администратора. ? Копию статьи вы найдете в черновиках. <span class = 'time'>?</span>".include_value([notification.article_title, notification.event_value, moment(notification.created_at).format("D MMMM YYYY")]);
          }
          if (notification.notification_type == 9) {
            text += "Ваша статья <a href = 'javascript:;'>?</a> отклонена по решению администратора. ? Копию статьи вы найдете в черновиках. <span class = 'time'>?</span>".include_value([notification.article_title, notification.event_value, moment(notification.created_at).format("D MMMM YYYY")]);
          }
          if (notification.notification_type == 10) {
            text += "<strong>? ?</strong> оценил(а) ваш <a href = '/article/?'>комментарий</a> <span class = 'time'>?</span>".include_value([notification.user_cause_lname, notification.user_cause_fname, notification.article_id, moment(notification.created_at).format("D MMMM YYYY")]);
          }
          text += '</span>';

          const div = $('<li/>', {
            id: `notification_${notification.id}`,
            class: notification.is_new == true ? 'active notification_clickable' : '',
            data_id: notification.id,
            html: text
          });

          // if ($('div').is('#notification_inner') == true) {
          //   $("#notification ul").css({'max-height': $(window).outerHeight() - $('#notification_inner').offset().top}).prepend(div)
          // }
          $("#new_notifications_count").html(`<em>${data.new_notification_count}</em>`);

          $(".active.notification_clickable").on("click", function() {
            const id = $(this).attr("data_id");
            $.post("/notification/change", {"id":id});
          });
        },

      }
    );
  }  catch (e) {
    console.error(e);
  }
}

function init() {
  initCable();
  initOnlineCable();
}

$(document).on('turbolinks:load', init);
