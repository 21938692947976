import React, { PureComponent, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import pluralize from 'pluralize-ru';

import { DndProvider , useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';


import './CarouselEditor.sass';

import ProgressCircle from '~/editor2/components/ProgressCircle';

import IconAddImg from './icon-add-img.r.svg';

let idCounter = 0;
function generateId(prefix = 'id') {
  idCounter += 1;
  return `${prefix}${idCounter}`;
}

// crop cover
function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {

  if (arguments.length === 2) {
    x = y = 0;
    w = ctx.canvas.width;
    h = ctx.canvas.height;
  }

  // default offset is center
  offsetX = typeof offsetX === "number" ? offsetX : 0.5;
  offsetY = typeof offsetY === "number" ? offsetY : 0.5;

  // keep bounds [0.0, 1.0]
  if (offsetX < 0) offsetX = 0;
  if (offsetY < 0) offsetY = 0;
  if (offsetX > 1) offsetX = 1;
  if (offsetY > 1) offsetY = 1;

  const iw = img.width;
    const ih = img.height;
    const r = Math.min(w / iw, h / ih);
    let nw = iw * r;   // new prop. width
    let nh = ih * r;   // new prop. height
    let cx; let cy; let cw; let ch; let ar = 1;

  // decide which gap to fill
  if (nw < w) ar = w / nw;
  if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
  nw *= ar;
  nh *= ar;

  // calc source rectangle
  cw = iw / (nw / w);
  ch = ih / (nh / h);

  cx = (iw - cw) * offsetX;
  cy = (ih - ch) * offsetY;

  // make sure source rectangle is valid
  if (cx < 0) cx = 0;
  if (cy < 0) cy = 0;
  if (cw > iw) cw = iw;
  if (ch > ih) ch = ih;

  // fill image in dest. rectangle
  ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
}

const ImagItem = ({ img, index, moveCard, onRemove }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id: img.id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.25 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} className="CarouselEditor__ImageItem" style={{ opacity }}>
      <div
        className={classNames('CarouselEditor__Image', {
          'CarouselEditor__Image--Uploading': img.isLoading,
        })}
        style={{ backgroundImage: `url('${img.result || img.url}')` }}
      >
        {img.isLoading && (
          <div className="CarouselEditor__ImageProgress">
            <ProgressCircle size={38} progress={img.progress} />
          </div>
        )}
      </div>
      {!img.isLoading && (
        <button
          className="CarouselEditor__ImageRemove"
          type="button"
          onClick={() => onRemove(img.id)}
        >
          &times;
        </button>
      )}
    </div>
  );
};

class CarouselEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.handlerChangeFiles = ::this.handlerChangeFiles;
    this.handlerClickSave = ::this.handlerClickSave;

    this.state = {
      canAddImages: true,
      images: [...props.images],
    };
  }

  handlerClickSave() {
    const { images } = this.state;
    const { onSave } = this.props;
    const newImages = images.map(({id, url}) => ({id, url}));
    onSave && onSave(newImages);
  }

  handlerChangeFiles(event) {
    const { files } = event.target;
    if (files.length) {
      this.setState({ canAddImages: false });
      const uploadingImages = Array.from(files).map((file) => {
        const id = generateId();
        this.setState((state) => ({
          ...state,
          images: [
            ...state.images,
            {
              id,
              result: '', // (URL && URL.createObjectURL && URL.createObjectURL(file)) || '',
              progress: 0,
              isLoading: true,
            },
          ],
        }));

        let fileReader = null;
        fileReader = new FileReader();
        fileReader.onload = (eventFileReader) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = 200;
            canvas.height = 200;
            const ctx = canvas.getContext('2d');
            drawImageProp(ctx, img, 0, 0, canvas.width, canvas.height);
            const data = ctx.canvas.toDataURL('image/jpg');
            this.setState((state) => ({
              ...state,
              images: state.images.map((uFile) => {
                if (uFile.id === id) {
                  return {
                    ...uFile,
                    result: data,
                  };
                }
                return uFile;
              }),
            }));
          };
          img.src = eventFileReader.target.result;
        };
        fileReader.readAsDataURL(file);

        const formData = new FormData();
        formData.append('file', file, file.name);
        return axios
          .post(`/api/v1/articles/${this.props.articleId}/images`, formData, {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = progressEvent.loaded / progressEvent.total;
              this.setState((state) => ({
                ...state,
                images: state.images.map((uFile) => {
                  if (uFile.id === id) {
                    return {
                      ...uFile,
                      progress: percentCompleted,
                    };
                  }
                  return uFile;
                }),
              }));
            },
          })
          .then((response) => {
            // if (fileReader) fileReader.onload = undefined;
            this.setState((state) => ({
              ...state,
              images: state.images.map((img) => {
                if (id === img.id) {
                  return {
                    ...img,
                    ...response.data,
                    isLoading: false,
                  };
                }
                return  img;
              }),
            }));
          });
      });
      Promise.all(uploadingImages).then(() => {
        this.setState({ canAddImages: true });
      });
    }
  }

  handlerClickRemove = (imgId) => {
    const { images } = this.state;
    this.setState({
      images: images.filter((img) => img.id !== imgId),
    });
  };

  renderImages() {
    const { images } = this.state;
    return images.map((img, index) => {
      return (
        <ImagItem
          key={img.id}
          index={index}
          img={img}
          moveCard={(dragIndex, hoverIndex) => {
            const dragCard = images[dragIndex];
            const newOrderImages = [...images];
            newOrderImages.splice(dragIndex, 1)
            newOrderImages.splice(hoverIndex, 0, dragCard)
            this.setState({
              images: newOrderImages
            });
          }}
          onRemove={this.handlerClickRemove}
        />
      );
    });
  }

  render() {
    const { images, canAddImages } = this.state;
    const { onCancel } = this.props;
    return (
      <div
        className="CarouselEditor"
        // onDragEnd={(e) => e.stopPropagation()}
        // onDragEnter={(e) => e.stopPropagation()}
        // onDragLeave={(e) => e.stopPropagation()}
        // onDragOver={(e) => e.stopPropagation()}
        // onDragStart={(e) => e.stopPropagation()}
        onDragStart={undefined}
        onInput={(e) => e.stopPropagation()}
      >
        <div className="CarouselEditor__Head">
          <div className="CarouselEditor__HeadTitle">Редактирование карусели</div>
          <div className="CarouselEditor__HeadActions">
            <div className="CarouselEditor__HeadAction">
              <div className="CarouselEditor__Counter">
                {images.length} {pluralize(images.length, 'фотографий', 'фотография','фотографии', 'фотографий')}
              </div>
            </div>
            <div className="CarouselEditor__HeadAction">
              <button
                className="button button--prime button--md"
                type="button"
                onClick={this.handlerClickSave}
                disabled={!canAddImages}
              >
                Сохранить
              </button>
            </div>
            <div className="CarouselEditor__HeadAction">
              <button
                className="button button--default button--md"
                type="button"
                onClick={onCancel}
                disabled={!canAddImages}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
        <div className="CarouselEditor__ImagesWrap">
          <div className="CarouselEditor__Images">
            <DndProvider backend={HTML5Backend}>
              {this.renderImages()}
            </DndProvider>
            <div className="CarouselEditor__ImageItem" key="add-img">
              <label className="CarouselEditor__AddImg">
                <div className="CarouselEditor__AddImgInner">
                  <IconAddImg />
                  <br />
                  Добавить фото
                </div>
                {canAddImages && (
                  <input
                    className="CarouselEditor__AddInput"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={this.handlerChangeFiles}
                  />
                )}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CarouselEditor.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CarouselEditor;
