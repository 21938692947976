var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (community, title, users) {
      pug_html = pug_html + "\u003Cdiv class=\"subscribers-popup\"\u003E\u003Cdiv class=\"subscribers-popup__header\"\u003E\u003Cspan class=\"subscribers-popup__title\"\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cbutton class=\"mfp-close\"\u003E×\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"subscribers-popup__community\"\u003E\u003Cdiv class=\"subscribers-popup__wrapper\"\u003E\u003Ca" + (" class=\"subscribers-popup__image-link\""+pug.attr("href", community.url, true, true)) + "\u003E\u003Cimg" + (" class=\"subscribers-popup__image\""+pug.attr("src", community.img, true, true)) + "\u003E\u003C\u002Fa\u003E\u003Cdiv class=\"subscribers-popup__text\"\u003E\u003Ca" + (" class=\"subscribers-popup__head\""+pug.attr("href", community.url, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = community.category_name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cdiv class=\"subscribers-popup__policy\"\u003E" + (pug.escape(null == (pug_interp = community.policy) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"subscribers-popup__subscriber-list\"\u003E";
// iterate users
;(function(){
  var $$obj = users;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var userItem = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"subscribers-popup__subscriber\"\u003E\u003Ca" + (" class=\"subscribers-popup__avatar\""+pug.attr("href", userItem.user.url, true, true)) + "\u003E" + (null == (pug_interp = userItem.user.avatar) ? "" : pug_interp) + "\u003C\u002Fa\u003E\u003Ca" + (" class=\"subscribers-popup__name\""+pug.attr("href", userItem.user.url, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = userItem.user.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var userItem = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"subscribers-popup__subscriber\"\u003E\u003Ca" + (" class=\"subscribers-popup__avatar\""+pug.attr("href", userItem.user.url, true, true)) + "\u003E" + (null == (pug_interp = userItem.user.avatar) ? "" : pug_interp) + "\u003C\u002Fa\u003E\u003Ca" + (" class=\"subscribers-popup__name\""+pug.attr("href", userItem.user.url, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = userItem.user.name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "community" in locals_for_with ?
        locals_for_with.community :
        typeof community !== 'undefined' ? community : undefined, "title" in locals_for_with ?
        locals_for_with.title :
        typeof title !== 'undefined' ? title : undefined, "users" in locals_for_with ?
        locals_for_with.users :
        typeof users !== 'undefined' ? users : undefined));
    ;;return pug_html;};
module.exports = template;