import axios from 'axios';
import { render } from './render';
import flash from '~/blocks/flash';
import './index.sass';
import './competitions.sass';
import './competition-card.sass';

function initSaving() {

  const id_category = $('#q_category_id_eq option:selected').val();

  if (id_category) {
    $('#competition_category_id').val(id_category).selectmenu('refresh');
  }

  $('.js-competitions-date').on('change_date', function () {
    const $fieldDateWrap = $(this);
    const $currField = $fieldDateWrap.find('[type=hidden]');
    const date = $fieldDateWrap.find('.js-competitions-datepicker').val();
    const hour = $fieldDateWrap.find('.js-competitions-select-hours').val();
    const minutes = $fieldDateWrap.find('.js-competitions-select-minutes').val();
    $currField.val(`${date} ${hour}:${minutes} +0300`);
  });

  $('.js-admin-competition-form').on('submit', function(event) {
    event.preventDefault();
    if ($(this).data('submitting')) {
      return;
    }
    $(this).data('submitting', 'true');
    axios[$(this).data('method')]($(this).attr('action'), $(this).serialize()).then(resp => {
      window.location.reload();
    }).catch(err => {
      console.error(err);
      if (err.response.status === 422) {
        flash({danger: err.response.data.errors});
      }
    }).then(() => {
      $(this).data('submitting', '');
    });
  });
}

let editor;

function init() {
  const $root = $('.js-new-competition');

  if ($root.length === 0) {
    return;
  }

  const isNew = !!$('.js-new-competition--new').length;

  $root.on('click', function(event) {
    event.preventDefault();
    const $t = $(this);
    if ( $t.data('loading') ) {
      return;
    }
    if (editor) {
      editor.remove();
    }
    $t.data('loading', 'true');
    axios.get($t.attr('href')).then(resp => {
      render(resp.data, isNew).then(e => {
        editor = e && e[0];
        initSaving();
      });
    }).catch(err => {
      console.error(err);
    }).then(() => {
      $t.data('loading', '');
    });
  });

  $('.js-competitions-form-filter').on('change', function () {
    const $form = $(this);
    Turbolinks.visit(`?${$form.serialize()}`);
  });
}
$(document).on("turbolinks:load", init);
