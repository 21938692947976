import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import iconDivider from './icon-divider.svg';

class Block extends PureComponent {
  render() {
    return <div className="Divider"><img src={iconDivider} alt=""/></div>;
  }
}

Block.propTypes = {
  container: PropTypes.any,
  data: PropTypes.any,
};

export default Block;
