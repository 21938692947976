import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import axios from 'axios';
import debounce from 'lodash-es/debounce';

import './SelectMaterial.sass';
import './SelectMaterialOption.sass';
import './Material.sass';

import { customSelectStyles, customSelectComponents } from '~/editor2/components/Select/styles';

import iconInfo from './icon-info.svg';
import iconViews from './icon-views.svg';
import iconComments from './icon-commnets.svg';
import iconLikes from './icon-likes.svg';
import AsyncCreatable from 'react-select/async-creatable/dist/react-select.esm'

const CancelToken = axios.CancelToken;

const materialSelectStyles = {
  ...customSelectStyles,
  option: (base, state) => ({
    ...customSelectStyles.option(base, state),
    padding: '0 25px'
  })
};

const materialSelectComponents = {
  ...customSelectComponents,
  Option: props => {
    const {
      isSelected,
      value: { category_name, title, image },
    } = props;
    // console.log('Option', props);
    return (
      <components.Option {...props}>
        <div className="SelectMaterialOption">
          <div className="SelectMaterialOption__PreviewWrap">
            <img className="SelectMaterialOption__Preview" src={image} alt="" width={35} height={35} />
          </div>
          <div className="SelectMaterialOption__InfoWrap">
            <div className="SelectMaterialOption__Info">
              <div className="SelectMaterialOption__InfoTitle">{category_name}</div>
              <div className="SelectMaterialOption__InfoDescr">{title}</div>
            </div>
          </div>
          <div className="SelectMaterialOption__CheckedWrap">
            <div
              className={className('SelectMaterialOption__Checkbox', {
                'SelectMaterialOption__Checkbox--Selected': isSelected,
              })}
            />
          </div>
        </div>
      </components.Option>
    );
  },
  Control: props => {
    const { children, ...restProps } = props;
    return (
      <components.Control {...restProps}>
        {React.Children.map(children, child => React.cloneElement(child, { isFocused: restProps.isFocused }))}
      </components.Control>
    );
  },
  ValueContainer: props => {
    const { children, ...restProps } = props;
    return (
      <customSelectComponents.ValueContainer {...restProps}>
        {restProps.selectProps.inputValue ? null : (
          <components.Placeholder {...restProps} key="placeholder">
            {restProps.selectProps.placeholder}
          </components.Placeholder>
        )}
        {React.Children.toArray(children)
          .filter(child => child.type !== components.MultiValue)
          .filter(child => child.type !== components.Placeholder)}
      </customSelectComponents.ValueContainer>
    );
  },
};

class SelectMaterial extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isIniting: true,
      isFocused: false,
      lastRequestedMaterial: [],
    };
  }

  componentDidMount () {
    this.loadOptions();
  }

  getDefaultOptions(){
    const { lastRequestedMaterial } = this.state;
    const { materials } = this.props;
    return materials
      .concat(
        lastRequestedMaterial
          .filter(
            lastRequestedMaterialItem =>
              !materials.some(selectedMaterial => selectedMaterial.id === lastRequestedMaterialItem.id)
          )
      );
  }

  handlerFocusSelect = () => {
    this.setState({ isFocused: true });
  };

  handlerBlurSelect = () => {
    if (this.cancelToken) {
      this.cancelToken.cancel('new request articles');
    }
    this.setState({ isFocused: false });
  };

  handlerChangeSelect = (selectedValue) => {
    const { onChange } = this.props;
    //console.log('selectedValue', selectedValue);
    // this.setState(state => ({
    //   ...state,
    //   selectedMaterials: selectedValue
    // }));
    onChange && onChange(selectedValue || []);
  }

  handlerClickRemove = (material) => {
    //console.log('handleClickRemove', material);
    // this.setState(state => ({
    //   ...state,
    //   selectedMaterials: state.selectedMaterials
    //     .filter(selectedMaterial => selectedMaterial.value.id !== material.id),
    // }));
    const { onChange, materials } = this.props;
    onChange && onChange(
      materials.filter(selectedMaterial => selectedMaterial.id !== material.id),
    );
  }

  loadOptions = debounce((inputValue, callback) => {
    if (this.cancelToken) {
      this.cancelToken.cancel('new request articles');
    }
    this.cancelToken = CancelToken.source();
    axios.get('/api/v1/search/articles', {
      params: {
        q: inputValue,
        user_id_eq: this.props.userId,
      },
      cancelToken: this.cancelToken.token,
    }).then((response => {
      // console.log('response.data', response.data);
      // const materials = response.data.map(material => ({ label: material.title, value: material }));
      this.setState({ lastRequestedMaterial: response.data, isIniting: false });
      callback && callback(response.data);

      if (!this.isFirstLoad) {
        this.isFirstLoad = true;
        if (response.data.length < 3) {
          this.setState({
            isCantSelect: true,
          });
        }
      }
    })).catch((err) => {
      if (axios.isCancel(e)) {
        console.error('Request canceled', e.message);
      } else {
        console.error(e);
      }
    });
  }, 300);

  render() {
    const { id, isFocused, isCantSelect, isIniting } = this.state;
    const { materials, disabled: disabledFromProps } = this.props;
    const disabled = disabledFromProps || materials && materials.length >= 3 || isCantSelect;
    if (isIniting) {
      return null;
    }

    return (
      <div className="SelectMaterial">
        <div className="SelectMaterial__ControlWrap">
          <AsyncSelect
            id={id}
            className="SelectMaterial__Control"
            defaultOptions={this.getDefaultOptions()}
            placeholder={
              isFocused
                ? 'Введите название материала'
                : `Связать статьи${materials.length ? ` (${materials.length})` : '' }`
            }
            styles={materialSelectStyles}
            components={materialSelectComponents}
            value={materials}
            isMulti
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            menuIsOpen={disabled ? false : undefined}
            loadingMessage={() => 'Загрузка'}
            noOptionsMessage={() => 'Ничего не найдено'}
            loadOptions={this.loadOptions}
            onFocus={this.handlerFocusSelect}
            onBlur={this.handlerBlurSelect}
            onChange={this.handlerChangeSelect}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option}
            isDisabled={disabled}
          />
          <div className="SelectMaterial__ControlInfo">
            <img src={iconInfo} alt="" />
            { isCantSelect ?
              'Необходимо опубликовать 3 или больше статьи, чтобы связывать материалы'
              :
              'Выберите материалы которые Вы хотите связать. Максимум 3 материала'
            }
          </div>
        </div>
        {materials && !!materials.length && (
          <div className="SelectMaterial__Materials">
            {materials.map(material => (
              <div className="SelectMaterial__Material" key={material.id}>
                <div className="Material">
                  <div className="Material__Community">
                    <div className="Material__CommunityPreviewWrap">
                      <img
                        className="Material__CommunityPreview"
                        src={material.category_image}
                        alt=""
                        width={40}
                        height={40}
                      />
                    </div>
                    <div className="Material__CommunityInfo">
                      <div className="Material__CommunityTitle">{material.category_name}</div>
                      <div className="Material__DatePublication">{material.published_at}</div>
                    </div>
                  </div>
                  <div className="Material__Title">{material.title}</div>
                  <div className="Material__Counters">
                    <div className="Material__Counter">
                      <img className="Material__CounterIcon" src={iconViews} alt="" />
                      <span className="Material__CounterValue">{material.counters.visited}</span>
                    </div>
                    <div className="Material__Counter">
                      <img className="Material__CounterIcon" src={iconComments} alt="" />
                      <span className="Material__CounterValue">{material.counters.comments}</span>
                    </div>
                    <div className="Material__Counter">
                      <img className="Material__CounterIcon" src={iconLikes} alt="" />
                      <span className="Material__CounterValue">{material.counters.likes}</span>
                    </div>
                  </div>
                  <div className="Material__Remove" onClick={() => this.handlerClickRemove(material)} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

SelectMaterial.propTypes = {
  id: PropTypes.string,
  materials: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
};

export default SelectMaterial;
