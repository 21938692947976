import './help-info-menu.sass'


document.addEventListener("turbolinks:load", function () {

  $('.help-info-menu').each(function () {

    let $this = $(this);
    let $body = $(document.body);
    let $selected = $this.find('.help-info-menu__selected')
    let $list = $this.find('.help-info-menu__list');
    let isOpen = false;

    $selected.on('click', function () {
      if(isOpen) {
        isOpen = false
        $list.appendTo($this)
        $list.css({
          top: '',
          left: '',
          width: ''
        });
        $list.removeClass('is-open');
        $selected.removeClass('is-open');
      } else {
        isOpen = true
        $list.appendTo($body)
        $list.css({
          top: `${$selected.offset().top + $selected.outerHeight() + 10}px`,
          left: `${$selected.offset().left}px`,
          width: `${$selected.outerWidth()}px`
        });
        $list.addClass('is-open');
        $selected.addClass('is-open');
      }
    })

    $(window).on('resize', function () {

      if(isOpen && window.innerWidth < 768) {
        $list.css({
          top: `${$selected.offset().top + $selected.outerHeight() + 10}px`,
          left: `${$selected.offset().left}px`,
          width: `${$selected.outerWidth()}px`
        });
      }

    })

  })

})
