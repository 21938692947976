import {localize} from './utils'
const defaultFormat = (v) => {
  const vs = v.split("/")
  return `${vs[0]}.${vs[1]}`
}

const defaultFormatItem = (datalabel) => {
  return (
    datalabel +
    " " +
    localize(datalabel, ["просмотр", "просмотра", "просмотров"])
  )
}
export function drawGraph(selector, data, formatItem=defaultFormatItem, format=defaultFormat) {
  var labels = [], items = [];

  if ($(selector).length == 0) {
    console.error(`no elemenent with selector ${selector} found`)
    return null
  }

  for (var i = data.length; i--; ) {
    labels.push(format(data[i].datetime));
    items.push(data[i].value);
  }
  var maxY = Math.max.apply(Math, items), minY = Math.min.apply(Math, items);

  maxY = maxY + 1;
  minY = minY != 0 ? minY - 1 : 0;
  var config = {
    type: "line",
    data: {
      labels: labels,
      datasets: [
        {
          backgroundColor: "rgba(56, 154, 82, 0.16)",
          borderColor: "#389a52",
          data: items,
          fill: true,
          pointRadius: 6,
          pointHoverRadius: 6,
          pointBorderColor: "#389a52",
          pointBackgroundColor: "#ffffff",
          pointBorderWidth: 2,
          pointHoverBackgroundColor: "#389a52"
        }
      ]
    },
    options: {
      responsive: true,
      legend: {
        display: false
      },

      ticks: {
        min: 0, // it is for ignoring negative step.
        beginAtZero: true,
        callback: function(value, index, values) {
          if (Math.floor(value) === value) {
            return value;
          }
        }
      },

      layout: {
        padding: {
          left: 8,
          right: 8,
          top: 8,
          bottom: 8
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: ""
            },
            ticks: {
              fontColor: "rgba(0, 0, 0, 0.38)",
              fontSize: 15,
              fontStyle: "normal",
              fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
              padding: 20,
              autoSkip: true,
              maxTicksLimit: 20
            }
          }
        ],
        yAxes: [
          {
            display: true,
            //afterFit: function(scale) {
              //scale.width = 80; //<-- set value as you wish
            //},
            scaleLabel: {
              display: true,
              labelString: ""
            },
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: "rgba(0, 0, 0, 0.38)",
              fontSize: 15,
              fontStyle: "normal",
              fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
              padding: 20,
              min: minY,
              max: maxY,
              autoSkip: true,
              maxTicksLimit: 10
            }
          }
        ]
      },
      tooltips: {
        backgroundColor: "#ffffff",
        titleFontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
        titleFontSize: 14,
        titleFontStyle: "normal",
        titleFontColor: "rgba(0, 0, 0, 0.54)",
        bodyFontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
        bodyFontSize: 12,
        bodyFontStyle: "normal",
        bodyFontColor: "rgba(0, 0, 0, 0.54)",
        callbacks: {
          title: function(tooltipItem, data) {
            var datalabel = data.datasets[0].data[tooltipItem[0].index];
            var label = data.labels[tooltipItem[0].index];
            return formatItem(datalabel)
          },
          label: function(tooltipItem, data) {
            return "";
          }
        }
      }
    }
  };
  return new Chart($(selector)[0].getContext("2d"), config)
}
