import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { InfiniteLoader, List, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import axios from 'axios';
import throttle from 'lodash-es/throttle';
import debounce from 'lodash-es/debounce';
import './index.sass';
import closeIcon from './close_main.svg';
import flash from '~/blocks/flash/index';

class SharePopup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sName: '',
      sEmail: '',
      userAmount: 50,
      userList: [],
      userListSelected: [],
      startIndex: 0,
      stopIndex: 0,
      scrollToIndex: -1,
      rowHeight: window.innerHeight <= 780 ? 55 : 70
    };
    window.addEventListener('resize', this.handlerResize);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handlerResize);
  }

  handlerResize = throttle(() => {
    this.setState({
      rowHeight: window.innerHeight <= 780 ? 55 : 70
    });
  }, 100)

  isRowLoaded = ({ index }) => {
    const { userList } = this.state;
    return !!userList[index];
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    return new Promise(resolve => {
      this.setState({ startIndex, stopIndex }, () => {
        this.loadData(resolve);
      });
    });
  };

  loadData = debounce(resolveCB => {
    const { sName, startIndex, stopIndex, userList, scrollToIndex } = this.state;
    return axios
      .get('/api/v1/search/users', {
        params: {
          q: sName,
          offset: startIndex,
          limit: stopIndex - startIndex + 1,
        },
      })
      .then(response => {
        return new Promise(resolve => {
          let newUserList = [...userList];
          if (scrollToIndex === -1) {
            if (newUserList.length < startIndex) {
              newUserList.length = startIndex;
            }
            newUserList.splice(startIndex, response.data.list.length, ...response.data.list);
          } else {
            newUserList = [...response.data.list];
          }
          this.setState(
            {
              userAmount: response.data.amount,
              userList: newUserList,
            },
            () => {
              resolve();
              resolveCB && resolveCB();
            },
          );
        });
      });
  }, 300);

  rowRenderer = ({ key, index, style }) => {
    if (!this.isRowLoaded({ index })) {
      return (
        <div className="share-popup__user ph-item" style={style} key={key}>
          <div style={{ maxWidth: 35, padding: 0 }}>
            <div className="ph-avatar" style={{ minWidth: 35 }} />
          </div>
          <div>
            <div className="ph-row">
              <div className="ph-col-6" />
            </div>
          </div>
        </div>
      );
    }
    const { userList, userListSelected } = this.state;
    const { avatar, name, id } = userList[index];
    return (
      <div key={key} className="share-popup__user" style={style} onClick={() => this.handlerClickUser(id)}>
        <div className="share-popup__logo" dangerouslySetInnerHTML={{ __html: avatar }} />
        <span className="share-popup__name">{name}</span>
        <div
          className={`share-popup__checkbox ${userListSelected.includes(id) ? 'share-popup__checkbox--checked' : ''}`}
        />
      </div>
    );
  };

  handlerClickUser = userId => {
    const { userListSelected } = this.state;
    if (userListSelected.includes(userId)) {
      const newUserListSelected = [...userListSelected];
      newUserListSelected.splice(userListSelected.indexOf(userId), 1);
      this.setState({
        userListSelected: newUserListSelected,
      });
    } else {
      this.setState({
        userListSelected: [...userListSelected, userId],
      });
    }
    this.refList && this.refList.forceUpdateGrid();
  };

  handlerChangeName = e => {
    this.setState(
      {
        sName: e.target.value,
        scrollToIndex: 0,
        startIndex: 0,
        stopIndex: 10,
      },
      () => {
        this.refInfinityLoader && this.refInfinityLoader.resetLoadMoreRowsCache(true);
        this.loadData(() => {
          this.setState({
            scrollToIndex: -1,
          });
        });
      },
    );
  };

  handlerChangeEmail = e => {
    this.setState({
      sEmail: e.target.value,
    });
  };

  handlerClickCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  handlerClickSend = () => {
    const { onClose } = this.props;
    const { userListSelected, sEmail } = this.state;
    if (userListSelected.length || !!sEmail) {
      const data = {};
      if (userListSelected.length) {
        data.users = userListSelected.map(id => ({ id }));
      } else {
        data.email = sEmail;
      }
      axios
        .post(this.backendUrl(), data)
        .then(resp => {
          flash({ success: 'Вы поделились!' });
          onClose && onClose(resp.data);
        })
        .catch(() => {
          flash({ error: 'Произошла ошибка!' });
        });
    } else {
      flash({ error: 'Укажите почту или выберите пользователя!' });
    }
  };

  backendUrl() {
    const { id, type } = this.props;
    return `/${type || 'articles'}/${id}/shares`;
  }

  render() {
    const { sName, sEmail, userAmount, scrollToIndex, rowHeight } = this.state;
    return (
      <div className="share-popup">
        <div className="share-popup__header">
          <h3 className="share-popup__title">Поделиться</h3>
          <img src={closeIcon} className="share-popup__close" alt="Закрыть" onClick={this.handlerClickCancel} />
        </div>
        <div className="share-popup__field">
          <label className="share-popup__label" htmlFor="email">
            Отправить на почту
          </label>
          <input
            className="share-popup__input"
            type="email"
            name="email"
            id="email"
            value={sEmail}
            placeholder="example@sberbank.ru"
            autoComplete="off"
            onChange={this.handlerChangeEmail}
          />
        </div>

        <div className="share-popup__field share-popup__field--name">
          <label className="share-popup__label" htmlFor="name">
            Отправить пользователю
          </label>
          <div className="share-popup__wrapper">
            <input
              className="share-popup__input share-popup__input--name"
              type="text"
              name="name"
              id="name"
              value={sName}
              placeholder="Напишите имя"
              autoComplete="off"
              onChange={this.handlerChangeName}
            />
          </div>
        </div>
        <div className="share-popup__users-wrap">
          <InfiniteLoader
            ref={ref => {
              this.refInfinityLoader = ref;
            }}
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowCount={userAmount}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    ref={ref => {
                      this.refList = ref;
                      registerChild(ref);
                    }}
                    scrollToIndex={scrollToIndex}
                    className="share-popup__users"
                    rowCount={userAmount}
                    rowHeight={rowHeight}
                    rowRenderer={this.rowRenderer}
                    height={height}
                    width={width}
                    overscanColumnCount={10}
                    onRowsRendered={onRowsRendered}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
        <div className="user-popup__buttons">
          <button
            className="user-popup__button user-popup__button--close"
            type="button"
            onClick={this.handlerClickCancel}
          >
            Отмена
          </button>
          <button className="user-popup__button user-popup__button--send" type="button" onClick={this.handlerClickSend}>
            Отправить
          </button>
        </div>
      </div>
    );
  }
}

SharePopup.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};

export default SharePopup;
