import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import sanitizeHtml from 'sanitize-html';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import findWithRegex from 'find-with-regex';
import Typograf from 'typograf';
import DraftJS from 'draft-js';
import { MegadraftEditor, editorStateFromRaw, editorStateToJSON, Toolbar, Sidebar } from 'megadraft-with-plugins';
import createCorePlugins from 'megadraft-with-plugins/lib/createCorePlugin';
import 'megadraft-with-plugins/dist/css/megadraft.css';
import createMentionPlugin from 'draft-js-mention-plugin2';
// import defaultRegExpMention from 'draft-js-mention-plugin2/lib/defaultRegExp';
import 'draft-js-mention-plugin2/lib/plugin.css';
import Immutable from 'immutable';
import debounce from 'lodash-es/debounce';
import memoize from 'lodash-es/memoize';
import axios from 'axios';
import Textarea from 'react-textarea-autosize';
import icons from './icons';
import { getLinksMath } from '~/editor2/utils';
import insertDataBlock from '~/editor2/utils/draftjs/insertDataBlock';

import './EditorContent.sass';

// examples for plugins https://www.npmjs.com/search?q=megadraft%20plugin
// https://github.com/globocom?utf8=%E2%9C%93&q=megadraft&type=&language=
// https://www.npmjs.com/package/megadraft-video-plugin
// https://www.npmjs.com/package/megadraft-video-embed-plugin
// https://www.npmjs.com/package/megadraft-embed
// https://www.npmjs.com/package/@webedia/megadraft-embed-plugin
// https://www.npmjs.com/package/outduu-md-image-plugin
// https://github.com/globocom/generator-megadraft-plugin/tree/master/generators/app/templates/src
import ImagesPlugin from '~/editor2/plugins/images';
import imageConstants from '~/editor2/plugins/images/constants';
import VideoPlugin from '~/editor2/plugins/videos';
import videoConstants from '~/editor2/plugins/videos/constants';
import DocsPlugin from '~/editor2/plugins/docs';
import DividerPlugin from '~/editor2/plugins/divider';
import BlockquotePlugin from '~/editor2/plugins/blockquote';
import Quiz from '~/editor2/plugins/quiz';

import PopoverError from '~/editor2/components/Popovers/Error';
import { Sizes } from '~/editor2/components/Popovers/Size';
import MentionComponent from '~/editor2/components/EditorContent/MentionComponent';
import {EditorContentContext} from "~/editor2/components/EditorContent/EditorContentContext";

const corePlugin = createCorePlugins();

// ломают мегадрафт
delete corePlugin.blockRendererFn;
delete corePlugin.blockStyleFn;

const tp = new Typograf({locale: ['ru', 'en-US']});
tp.disableRule('*'); // Отключить все правила
tp.enableRule('common/punctuation/quote'); // Включить правило

const i18n = {
  'ru-RU': {
    'Type the link and press enter': 'Введите ссылку и нажмите Enter',
    'Invalid Link': 'Неправильная ссылка',
    "Can't show atomicBlock, component {{type}} not found":
      'Не удается показать блок, компонент {{type}} не найден.',
    'Block List': 'Список блоков',
    "Can't show atomicBlock, component not found.":
      "'Не удается показать блок, компонент не найден.",
    "Something went wrong in component '{{type}}'. {{error}}":
      "Что-то пошло не так в компоненте '{{type}}'. {{error}}",
    "Something went wrong with the component type.":
      "Что-то пошло не так с типом компонента."
  },
};

const mentionPlugin = createMentionPlugin({
  mentionComponent: MentionComponent,
  supportWhitespace: true,
});

const { MentionSuggestions, decorators } = mentionPlugin;

// decorators[1].strategy = mentionSuggestionsStrategy('@', true, defaultRegExpMention);

const styleMap = {
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
};

class CustomToolbar extends PureComponent {
  render() {
    const { ...props } = this.props;
    return (
      <Toolbar
        classNmae="EditorContent__Toolbar"
        {...props}
        // для того чтоб не скрывался меню форматирования (не забудь обратно закоментировать)
        // shouldDisplayToolbarFn={() => true}
      />
    );
  }
}

const isSelectedMention = (editorState, callbackRange) => {
  let isActive = false;
  const selection = editorState.getSelection();
  const start = selection.getStartOffset();
  const end = selection.getEndOffset();
  const anchorKey = selection.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentBlock = currentContent.getBlockForKey(anchorKey);
  decorators[0].strategy(
    currentBlock,
    (startEntity, endEntity) => {
      if (
        start <= startEntity && end >= startEntity
        || start <= endEntity && end >= startEntity
      ) {
        isActive = true;
      }
      callbackRange && callbackRange(startEntity, endEntity);
    },
    currentContent
  );
  return isActive;
};

const rulesReplace = [
  { rule: /->/gi, replace: '→'},
  { rule: /\(c\)/gi, replace: '©'},
  { rule: /\^2/gi, replace: '²'},
  { rule: /!=/gi, replace: '≠'},
  // для кавычек
  // { rule: /"(?=[А-Яа-яёйі'])/gi, replace: '«'},
  // { rule: /(?<=(«|")[А-Яа-яёйі'][\wА-Яа-яёйі']+((\s[\wА-Яа-яёйі']+)+)?[А-Яа-яёйі'])"/gi, replace: '»'},
];

class CheckRenderAtomicBlock extends React.Component {
  render() {
    const { children } = this.props;
    if (children) {
      if (children[0]?.props?.children?.props
        && children[0].props.children.props.blockProps
        && children[0].props.children.props.block
      ) {
        const isReadonly = children[0].props.children.props.blockProps.getInitialReadOnly();
        const data = children[0].props.children.props.block.getData().toJS();
        if (isReadonly && data.type === 'quiz' && !data.isFilled) {
          return null;
        }
      }
    }
    return children;
  }
}

class EditorContent extends PureComponent {

  debounceChangeEditorState = debounce(() => {
    const { editorState } = this.state;
    const { onChange } = this.props;
    const newContent = JSON.parse(editorStateToJSON(editorState));
    this.setState({
      prevContent: newContent
    }, () => {
      onChange && onChange({
        content: newContent,
      });
    });
  }, 200);

  debouncedHandleMentionSearchChange = debounce(({value}) => {
    this.setState({
      mentionSuggestions: [{ id: 'search', name: 'Поиск...', disabled: true }]
    });
    axios.get('/api/v1/search/users', { params: { q: value } }).then(resp => {
      const newMentionSuggestions = (resp.data.list && resp.data.list.length > 0)
        ? resp.data.list
        : [{ id: 'disabled', name: 'Пользователь не найден', disabled: true }];
      this.setState({
        mentionSuggestions: newMentionSuggestions
      });
    });
  }, 200)

  plugins = [mentionPlugin, corePlugin]

  constructor(props) {
    super(props);
    this.state = {
      prevContent: cloneDeep(props.article.content),
      editorState: editorStateFromRaw(props.article.content),
      mentionSuggestions: [],
      editorBlocked: false,
    };

    const self = this;

    this.editorActions = [
      { type: 'block', label: 'H1', style: 'header-one', icon: icons.IconH1 },
      { type: 'block', label: 'H2', style: 'header-two', icon: icons.IconH2 },

      { type: 'separator' },

      { type: 'inline', label: 'B', style: 'BOLD', icon: icons.IconBold },
      { type: 'inline', label: 'I', style: 'ITALIC', icon: icons.IconItalic },
      { type: 'inline', label: 'U', style: 'UNDERLINE', icon: icons.IconUnderline },
      { type: 'inline', label: 'S', style: 'STRIKETHROUGH', icon: icons.IconStrike },

      { type: 'separator' },

      // { type: 'block', label: 'QT', style: 'blockquote', icon: icons.IconQuote },
      { type: 'block', label: 'UL', style: 'unordered-list-item', icon: icons.IconUl },
      { type: 'block', label: 'OL', style: 'ordered-list-item', icon: icons.IconOl },
      {
        type: 'custom', label: 'AT', style: 'at', icon: icons.IconAt,
        action(editorState, onChange) {
          const selection = editorState.getSelection();
          const anchorKey = selection.getAnchorKey();
          const currentContent = editorState.getCurrentContent();
          const currentBlock = currentContent.getBlockForKey(anchorKey);
          // Then based on the docs for SelectionState -
          const start = selection.getStartOffset();
          const end = selection.getEndOffset();
          const positionEntities = [];
          const isSelectMention = isSelectedMention(editorState, (pStartEntity, pEndEntity) => {
            positionEntities.push({
              startEntity: pStartEntity,
              endEntity: pEndEntity,
            });
          });
          if (isSelectMention) {
            const indexEntity = positionEntities.findIndex(entity => (
                start <= entity.startEntity && end >= entity.startEntity
                || start <= entity.endEntity && end >= entity.endEntity
              ));
            const entitySelection = selection.merge({
              anchorOffset: positionEntities[indexEntity].startEntity,
              focusOffset: positionEntities[indexEntity].endEntity
            });
            const newContentState = DraftJS.Modifier.applyEntity(
              currentContent,
              entitySelection,
              null
            );
            const newEditorState = DraftJS.EditorState.push(
              editorState,
              newContentState,
              'apply-entity'
            );
            onChange(newEditorState);
          } else {
            const selectedText = currentBlock.getText().slice(start, end);
            const newContentState = DraftJS.Modifier.replaceText(currentContent, selection, `@${  selectedText}` );
            const newEditorState = DraftJS.EditorState.push(editorState, newContentState);
            self.setState({
              mentionSuggestions: []
            });
            onChange(newEditorState);
          }
        },
        active(editorState) {
          return isSelectedMention(editorState);
        }
      },

      { type: 'separator' },

      { type: 'entity', label: 'Link', style: 'link', entity: 'LINK', icon: icons.IconLink },
    ];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { editorState, prevContent, megadraft_videos, megadraft_images } = this.state;
    if (!isEqual(nextProps.article.content, prevContent)) {
      const selection = editorState.getSelection();
      this.setState({
        prevContent: cloneDeep(nextProps.article.content),
        editorState: DraftJS.EditorState.forceSelection(
          editorStateFromRaw(nextProps.article.content),
          selection
        )
      });
    }
    if (!isEqual(nextProps.article.megadraft_videos, megadraft_videos)) {
      this.setState({
        megadraft_videos: cloneDeep(nextProps.article.megadraft_videos),
      });
    }
    if (!isEqual(nextProps.article.megadraft_images, megadraft_images)) {
      this.setState({
        megadraft_images: cloneDeep(nextProps.article.megadraft_images),
      });
    }
  }

  componentDidMount() {
    $(document).trigger('rendered:editor-content');
  }

  setTitle(title) {
    const { onChange } = this.props;
    onChange &&
      onChange({
        title: sanitizeHtml(title, {
          allowedTags: [],
          allowedAttributes: {},
        }),
      });
  }

  handlerChangeTitle = e => {
    this.setTitle(e.target.value);
  };

  handlerBlurTitle = e => {
    this.setTitle(e.target.innerHTML);
  };

  handlerKeyDonwTitle = e => {
    this.setTitle(e.target.innerHTML);
  };

  handlerKeyUpTitle = e => {
    this.setTitle(e.target.innerHTML);
  };

  checkMediaLink = editorState => {
    let nextState = editorState;

    const shouldFilterPaste =
      nextState.getCurrentContent() !== this.state.editorState.getCurrentContent() &&
      nextState.getLastChangeType() === "insert-fragment";

    if (shouldFilterPaste) {
      const blockMap = nextState.getCurrentContent().getBlockMap();
      blockMap.forEach((contentBlock) => {
        if (contentBlock.getType() !== 'atomic') {
          const linksMatch = getLinksMath(contentBlock.getText());
          if (linksMatch) {
            linksMatch.forEach((link) => {
              new Promise((resolve) => {
                axios.post(`/api/v1/articles/${this.props.article.id}/link_checks`, { link_check: {link: link.url} } )
                  .then(resp => {
                    resolve(resp.data);
                  }).catch(err => {
                    console.error(err);
                    resolve(null);
                  });
              }).then((linkData) => {
                if (linkData) {
                  const data = {
                    caption: '',
                    size: Sizes.CONTAINS,
                  };
                  const object = {
                    id: linkData.id,
                    url: linkData.url
                  };

                  if (linkData.type === 'video') {
                    data.type = videoConstants.PLUGIN_TYPE;
                    data.video = object;
                  } else if (linkData.type === 'image') {
                    data.type = imageConstants.PLUGIN_TYPE;
                    data.isCarusel = false;
                    data.images = [object];
                  }

                  const block = nextState
                    .getCurrentContent()
                    .getBlockMap()
                    .find(blockItem => blockItem.getText().indexOf(link.raw) > -1);

                  if (block) {
                    const start = block.getText().indexOf(link.raw);
                    nextState = insertDataBlock(
                      nextState,
                      data,
                      DraftJS
                        .SelectionState
                        .createEmpty(block.getKey())
                        .merge({
                          anchorOffset: start,
                          focusOffset: start + link.raw.length,
                        })
                    );
                    this.setState({
                      editorState: nextState,
                    }, this.debounceChangeEditorState);
                  }
                }
              });
            });
          }
        }
      });
    }
  }

  handlerChangeEditor = editorState => {
    const selectionsToReplace = [];
    const blockMap = editorState.getCurrentContent().getBlockMap();
    const selectionState = editorState.getSelection();
    blockMap.forEach((contentBlock) => {
      const createSelection = (start, end, replace) => {
        const blockKey = contentBlock.getKey();
        const blockSelection = DraftJS.SelectionState
          .createEmpty(blockKey)
          .merge({
            anchorOffset: start,
            focusOffset: end,
          });
        selectionsToReplace.push({
          selection: blockSelection,
          replace,
        });
      };
      rulesReplace.forEach(ruleReplace => {
        findWithRegex(ruleReplace.rule, contentBlock, (start, end) => createSelection(start, end, ruleReplace.replace));
      });
      const text = contentBlock.getText();
      const text2 = tp.execute(text);
      const left = '«';
      const right = '»';
      for(let i=0; i < text2.length; i+=1) {
        if (text[i] !== text2[i]) {
          if (text2[i] === left) createSelection(i, i + 1, left);
          if (text2[i] === right) createSelection(i, i + 1, right);
        }
      }
    });
    let contentState = editorState.getCurrentContent();
    selectionsToReplace.forEach(({ selection, replace }) => {
      contentState = DraftJS.Modifier.replaceText(
        contentState,
        selection,
        replace,
      );
    });

    const editorStateWithReplace = DraftJS.EditorState.push(
      editorState,
      contentState,
    );

    const newEditorState = selectionsToReplace.length > 0
      ? DraftJS.EditorState.forceSelection(editorStateWithReplace, selectionState)
      : editorStateWithReplace;

    this.checkMediaLink(newEditorState);

    this.setState({
      editorState: newEditorState,
    }, this.debounceChangeEditorState);
  };

  blockEditor() {
    this.setState({editorBlocked: true});
  }

  unblockEditor() {
    this.setState({editorBlocked: false});
  }

  atomicBlocks = [
    ImagesPlugin,
    VideoPlugin,
    DocsPlugin,
    Quiz,
    DividerPlugin,
    BlockquotePlugin,
  ]

  handlePastedText = (text, html, editorState) => {}

  handlePastedFiles = (files, pluginFunctions, selection) => {
    // console.log('handle Pasted Files', files, selection);
    const images = files.filter(file => file.type.match(/^image/));
    if (images.length === 0) {
      return;
    }
    this.blockEditor();
    const promises = images.map((file) => new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.set('file', file);
        axios.post(`/api/v1/articles/${this.props.article.id}/images`, formData).then(resp => {
          resolve(resp.data);
        }).catch(err => {
          console.error(err);
          resolve(null);
        });
      }));
    let editorState = null;
    Promise.all(promises).then((values) => {
      editorState = this.state.editorState;
      values.filter(value => value).forEach(item => {
        const data = {
          caption: '',
          size: Sizes.CONTAINS,
          type: imageConstants.PLUGIN_TYPE,
          isCarusel: false,
          images: [
            {
              id: item.id,
              url: item.url,
            }
          ]
        };
        editorState = insertDataBlock(editorState, data, selection);
      });
      this.handlerChangeEditor(editorState);
      this.unblockEditor();
    });
  }

  handleDroppedFiles = (selection, files, pluginFunctions) => {
    this.handlePastedFiles(files, pluginFunctions, selection);
  }

  tryCloseSideBar = () => {
    // черная магия
    if (this.refSidebar) {
      const sidebarDom = ReactDOM.findDOMNode(this.refSidebar);
      if (sidebarDom) {
        const sidebarMenu = sidebarDom.querySelector('.sidemenu');
        const isOpen = sidebarMenu.classList.contains('sidemenu--open');
        if (isOpen) {
          const sidebarToggleBtnDom = sidebarDom.querySelector('.sidemenu > .sidemenu__button');
          sidebarToggleBtnDom && sidebarToggleBtnDom.click();
        }
      }
    }
  };

  renderMentionItem = (props) => {
    const {
      mention,
      theme,
      searchValue,
      className,
      ...parentProps
    } = props;
    return (
      <div className={classNames(className, "MentionItem")} {...parentProps}>
        <span
          className="MentionItem__Avatar"
          dangerouslySetInnerHTML={{ __html: mention.get('avatar')}}
        />
        <span className={theme.mentionSuggestionsEntryText}>{mention.get('name')}</span>
      </div>
    );
  }

  renderSideBar = (props) => {
    const { onChange, ...restProps } = props;
    return (
      <Sidebar
        ref={ref => this.refSidebar = ref}
        onChange={(newEditorState) => {
          onChange(newEditorState);
          this.tryCloseSideBar();
        }}
        {...restProps}
      />
    );
  }

  handleDrop = () => 'handled'

  handleErrorContent = () => {

  }

  contextValue = () => {
    const { article, isLoading, isReadOnly, onError } = this.props;
    return {
      article, isLoading, isReadOnly, onError
    };
  }

  render() {
    const { article, isLoading, isReadOnly, isErrorTitle, isErrorContent } = this.props;
    const { editorState, editorBlocked, mentionSuggestions } = this.state;

    return (
      <EditorContentContext.Provider value={this.contextValue()}>
      <div className="EditorContent">
        {isLoading && <div className="EditorContent__loading">Загрузка...</div>}
        {!isLoading && (
          <>
            <PopoverError
              error={
                <>
                  Дайте название
                  <br />
                  вашей статье
                </>
              }
              isShow={isErrorTitle}
            >
              <div className="EditorContent__TitleWrap">
                {isReadOnly && <div className="EditorContent__Title">{article.title}</div>}
                {!isReadOnly && (
                  <Textarea
                    className="EditorContent__Title EditorContent__TextArea"
                    value={article.title || ''}
                    onChange={this.handlerChangeTitle}
                    placeholder="Придумайте заголовок"
                  />
                )}
              </div>
            </PopoverError>
            <div
              className="EditorContent__Content"
              // костыль
              onFocus={() => clearTimeout(this.idTimeoutBlur)}
              onBlur={() =>
                this.idTimeoutBlur = setTimeout(() => this.tryCloseSideBar(), 200)
              }
            >
              <MegadraftEditor
                readOnly={isReadOnly || editorBlocked}
                editorState={editorState}
                onChange={this.handlerChangeEditor}
                placeholder={isReadOnly ? "" : "И просто начните писать"}
                handleDrop={this.handleDrop}
                i18n={i18n}
                Toolbar={CustomToolbar}
                softNewLines
                resetStyleNewLine
                sidebarRendererFn={this.renderSideBar}
                language="ru-RU"
                actions={this.editorActions}
                customStyleMap={styleMap}
                plugins={this.plugins}
                handlePastedText={this.handlePastedText}
                handlePastedFiles={this.handlePastedFiles}
                handleDroppedFiles={this.handleDroppedFiles}
                atomicBlocks={this.atomicBlocks}
                hideSidebarOnBlur
                blockRenderMap={Immutable.Map({
                  atomic: {
                    element: 'figure',
                    wrapper: <CheckRenderAtomicBlock />,
                  },
                })}
              />
              <MentionSuggestions
                onSearchChange={this.debouncedHandleMentionSearchChange}
                suggestions={mentionSuggestions}
                entryComponent={this.renderMentionItem}
              />
            </div>
            <div style={{ maxWidth: 770, margin: '0 auto' }}>
              <PopoverError
                error={
                  <>
                    Начните писать
                    <br />
                    вашу статью
                  </>
                }
                isShow={isErrorContent}
                place="below"
              >
                <div style={{ maxWidth: 250 }} />
              </PopoverError>
            </div>
          </>
        )}
      </div>
      </EditorContentContext.Provider>
    );
  }
}

EditorContent.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.object,
    megadraft_images: PropTypes.array,
    megadraft_videos: PropTypes.array,
    category: PropTypes.any,
  }),
  isReadOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  isErrorTitle: PropTypes.bool,
  isErrorContent: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};

EditorContent.defaultProps = {
  isReadOnly: false,
};

export default EditorContent;
