import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';
import {withEditorContentContext} from "~/editor2/components/EditorContent/EditorContentContext";

class Block extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  handlerChangeQuote = e => {
    this.setQuote(e.target.value);
  };

  handlerChangeQuiteInner = e => {
    this.setQuote(e.target.innerHTML);
  };

  handlerChangeAuthor = e => {
    this.setAuthor(e.target.value);
  };

  handlerChangeAuthorInner = e => {
    this.setAuthor(e.target.innerHTML);
  };

  setQuote(quote) {
    const { container } = this.props;
    container.updateData({
      quote: sanitizeHtml(quote, {
        allowedTags: [],
        allowedAttributes: {},
      }),
    });
  }

  setAuthor(author) {
    const { container } = this.props;
    container.updateData({
      author: sanitizeHtml(author, {
        allowedTags: [],
        allowedAttributes: {},
      }),
    });
  }

  handlerFocus = () => {
    this.setState({ isFocused: true });
  }

  handlerBlur = () => {
    this.setState({ isFocused: false });
  }

  isCanEdit() {
    const { isFocused } = this.state;
    const { isReadOnly } = this.props;
    return !isReadOnly || isFocused;
  }

  render() {
    const { data } = this.props;
    const { quote, author } = data;
    return (
      <div
        className="Blockquote"
        onFocus={this.handlerFocus}
        onBlur={this.handlerBlur}
        onInput={(e) => e.stopPropagation()}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className="Blockquote__InnerWrap">
          {!this.isCanEdit() && (
            <>
              <div className="Blockquote__Quote">{quote}</div>
              <div className="Blockquote__Author">{author}</div>
            </>
          )}
          {this.isCanEdit() && (
            <>
              <div>
                <ContentEditable
                  className="Blockquote__Quote"
                  placeholder="Введите текст цитаты"
                  onChange={this.handlerChangeQuote}
                  onBlur={this.handlerChangeQuiteInner}
                  onKeyDown={this.handlerChangeQuiteInner}
                  onKeyUp={this.handlerChangeQuiteInner}
                  html={quote}
                  spellCheck={false}
                />
              </div>
              <div>
                <ContentEditable
                  className="Blockquote__Author"
                  placeholder="Укажите автора"
                  onChange={this.handlerChangeAuthor}
                  onBlur={this.handlerChangeAuthorInner}
                  onKeyDown={this.handlerChangeAuthorInner}
                  onKeyUp={this.handlerChangeAuthorInner}
                  html={author}
                  spellCheck={false}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

Block.propTypes = {
  container: PropTypes.any,
  data: PropTypes.any,
  isReadOnly: PropTypes.bool,
};

export default withEditorContentContext(Block);
