// Неа, https://github.com/iamceege/tooltipster/issues/566
// import 'tooltipster'
// а 3-ки нет в npm
import 'tooltipster/js/jquery.tooltipster.min';
import 'tooltipster/css/tooltipster.css';
import 'tooltipster/css/themes/tooltipster-shadow.css';

import './index.scss'
import template from './template.pug'
import subscribeTemplate from './subscribe.pug'
import axios from 'axios'


function setHandler(origin, root, id, endpoint, text) {
  root.on('click', function() {
    $(this).attr('disabled', true)
    axios.post(endpoint, {
      author_id: id
    }).then(resp=>{
      root.each(function() {
        $(this).replaceWith(subscribeTemplate({id, text}))
      })
      origin.data('follows', !origin.data('follows'))
      setTimeout(function(){
        $(`span.subscribe_user_${id}`).addClass('ready')
      }, 500)
    }).catch(err => {
      console.error(err)
    }).then(()=>{
      $(this).attr('disabled', false)
    })
  })
}

function config(content, position, id) {
  return {
    theme: 'tooltipster-rs',
    trigger: 'hover',
    content: '',
    animation: 'fade',
    contentAsHTML: true,
    minWidth: 420,
    maxWidth: 480,
    position: position,
    speed: 0,
    interactive: true,
    functionBefore: function(origin, continueToolTip) {
      if($(window).outerWidth() > 767) {
        continueToolTip();
        origin.tooltipster('content', content)
        setHandler(origin, $(`.js-tooltip-subscribe-${id}`), id, '/subscription/users/create', 'Подписка оформлена')
        setHandler(origin, $(`.js-tooltip-unsubscribe-${id}`), id, `/subscription/users/destroy_1`, 'Подписка отменена')
      }
    },
    functionReady: function(origin) {
      $('img').on("load", function() {
        origin.tooltipster('reposition');
      });
      if($(window).outerWidth() <= 767) {
        origin.tooltipster('destroy');
      }
    },
    functionAfter: function(origin) {
      origin.tooltipster('content', '');
    }
  }
}

function init() {
  $('.js-tooltip').tooltipster({
    theme: 'tooltipster-shadow'
  });

  // TODO: проверить нули этот код
  const $root = $('.author_tooltip')
  $root.each((_, e) => {
    const content = template($(e).data());
    $(e).tooltipster(config(content, $(e).data('position'), $(e).data('user').id))
  });
}


$(document).on('turbolinks:load', init);
