import axios from 'axios';
import flash from '~/blocks/flash';

function initImport() {
  $('.js-admin-user-roles-import-form').on('submit', function(event) {
    event.preventDefault()
    const formData= new FormData();
    formData.append('file', $(this).find('input[name="file"]')[0].files[0]);
    axios.post($(this).attr('action'), formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(resp => {
      flash({success: 'Пользователи добавлены'})
    }).catch(error => {
      flash({error: error.response.data.errors || 'Произошла ошибка'})
    }).then(() => {
      window.closeModal()
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    })
  })
}

function closeImportModal() {
  let $userImportCloseBtn = $('.import-user__close');
  $userImportCloseBtn.on('click', function () {
    window.closeModal();
  });
}

function init() {
  let $userImportOpenBtn = $('.js-admin-category-import-user');
  $userImportOpenBtn.on('click', function(event) {
    event.preventDefault()
    axios.get($(this).attr('href')).then(resp => {
      window.showModal(resp.data)
      initImport()
      closeImportModal();
    }).catch(error => {
      console.error(error)
      flash({error: 'Ошибка загрузки формы'})
    })
  })
}

$(document).on('turbolinks:load', init);
