import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import debounce from 'lodash-es/debounce';
import isEqual from "lodash-es/isEqual";
import Modal from '~/editor2/components/Modal';
import Dropzone, { TypeDropzone } from '~/editor2/components/Dropzone';
import TextAreaCounter from '~/editor2/components/TextAreaCounter';
import SelectMaterial from '~/editor2/components/Select/SelectMaterial';
import SelectTags from '~/editor2/components/Select/Tags';
import Crop from '~/editor/Crop';
import ButtonIcon from '~/editor2/components/ButtonIcon';

import './ModalPublication.sass';
import iconChangeImg from './icon-change-img.svg';
import PopoverError from '~/editor2/components/Popovers/Error';
import { EditorContext } from '~/editor2/context';

const { CancelToken } = axios;

/* eslint-disable camelcase */
class ModalPublication extends PureComponent {
  static contextType = EditorContext;

  constructor(props) {
    super(props);
    this.debouncedPersistChanges = debounce(this.persistChanges, 500);
    this.state = {
      background_image: props.article.background_image,
      description: props.article.description,
      recommendations: props.article.recommendations,
      article_tags: props.article.article_tags,
      crop: props.article.crop,
      error_tag: false,
      error_descr: false,
      saved: true,
      selected_bg_image: '',
      isDisabledActionCoverList: false,
    };
  }

  componentWillReceiveProps(newProps) {
    if (!isEqual(this.props, newProps)) {
      this.setState({
        background_image: newProps.article.background_image,
        description: newProps.article.description,
        article_tags: isEqual(newProps.article.article_tags, this.state.article_tags)
          ? this.state.article_tags
          : newProps.article.article_tags,
        recommendations: newProps.article.recommendations,
        crop: newProps.article.crop,
      });
    }
  }

  cancelToken = () => {
    if (this.axios_source) {
      this.axios_source.cancel('new request');
    }
    this.axios_source = CancelToken.source();
  }

  cancelResizeToken() {
    if (this.axios_resize_source) {
      this.axios_resize_source.cancel('new request');
    }
    this.axios_resize_source = CancelToken.source();
  }

  debouncedPersistChangesWithCancelToken = () => {
    this.cancelToken();
    this.debouncedPersistChanges();
  }

  persistChanges = () => {
    const { article, onChange } = this.props;
    const { description, article_tags } = this.state;
    // TODO сюда не должны приходит tag.label и tag.value вообще. Баг в другом месте
    if (article_tags) {
      article_tags.forEach(tag => {
        tag.name || (tag.name = tag.value);
        tag.id || (tag.id = tag.value);
      });
    }
    this.setState({ saving: true });
    axios
      .put(
        `/articles/${article.id}`,
        {
          article: { description, article_tags },
        },
        { cancelToken: this.axios_source.token },
      )
      .then(resp => {
        this.setState({ saved: true });
        onChange(resp.data);
      })
      .catch(err => {
        console.error(err);
      })
      .then(() => {
        this.setState({ saving: false });
      });
  }

  handlerChangeDescription = value => {
    this.setState(
      {
        description: value,
        error_descr: false,
        saved: false,
      },
      this.debouncedPersistChangesWithCancelToken,
    );
  };

  handlerChangeTags = (article_tags) => {
    const dd = {
      article_tags: article_tags || [],
      error_tag: false,
      saved: false,
    };
    this.setState(
      dd,
      this.debouncedPersistChangesWithCancelToken,
    );
  };

  handlerUploadComplete = imgUrl => {
    this.setState({
      background_image: imgUrl,
      forceShowUploader: false,
    });
  };

  handlerChangeBg = e => {
    const file = e.target.files[0];
    if (file) {
      this.setState({ background_image: '' }, () => {
        this.dropzoneRef.startUploadFile(file);
      });
    }
  };

  handlerCropPhoto = crop => {
    const { article, onChange } = this.props;
    this.cancelResizeToken();
    return new Promise(resolve => {
      this.setState(
        {
          saved: false,
          isResizing: true
        },
        resolve,
      );
    }).then(() => axios
        .put(`/articles/${article.id}/resize`, crop, { cancelToken: this.axios_resize_source.token })
        .then(resp => {
          this.setState({
            crop,
            saved: true,
            isResizing: false,
            background_image: resp.data.background_image,
          });
          return resp;
        })
        .then(resp => {
          onChange(resp.data);
        })
        .catch(err => {
          console.error(err);
          this.setState({
            saved: true,
            isResizing: false,
          });
        }));
  };

  handlerChangeMaterial = recommendations => {
    const { article, onChange } = this.props;
    this.setState(
      {
        recommendations,
        saved: false,
      },
      () => {
        axios
          .put(`/articles/${article.id}`, {
            article: {
              recommended_article_ids: recommendations.map(material => material.id),
            },
          })
          .then(resp => {
            this.setState({saved: true});
            onChange(resp.data);
          })
          .catch((err) => {
            console.error(err);
            this.setState({saved: true});
          });
      },
    );
  };

  handlerChangeCompetable = event => {
    const competable = event.target.checked;
    const { article, onChange } = this.props;
    this.setState(
      {
        competable,
        saved: false,
      },
      () => {
        axios
          .put(`/articles/${article.id}`, {
            article: { competable },
          })
          .then(resp => {
            this.setState({saved: true});
            onChange(resp.data);
          })
          .catch((err) => {
            console.error(err);
            this.setState({saved: true});
          });
      },
    );
  }

  handlerSetCoverFromList = megadraft_image_id => {
    const { article, onChange } = this.props;
    const { isDisabledActionCoverList } = this.state;
    if (isDisabledActionCoverList) return;
    this.setState(
      {
        selected_bg_image: megadraft_image_id,
        isDisabledActionCoverList: true,
        saved: false,
      },
      () => {
        axios
          .put(`/articles/${article.id}`, {
            article: { megadraft_image_id },
          })
          .then(resp => {
            onChange(resp.data);
            this.setState({
              isDisabledActionCoverList: false,
              saved: true,
            });
          });
      },
    );
  };

  handlerClickComplete = () => {
    const { article_tags, description, recommendations } = this.state;
    const { onComplete } = this.props;
    if (article_tags.length) {
      if (description.length) {
        onComplete &&
          onComplete({
            article_tags,
            description,
            recommendations,
          });
      } else {
        this.setState({ error_descr: true });
      }
    } else {
      this.setState({ error_tag: true });
    }
  };

  deleteImage() {
    this.setState({ background_image: '' });
  }

  renderBarButtons() {
    const { isDisabledActionCoverList, selected_bg_image } = this.state;
    const { article } = this.props;
    return (
      <>
        <button
          className="PublicationCover__BarButton button button--prime button--md"
          type="button"
          onClick={() => this.setState({ forceShowUploader: true })}
          disabled={isDisabledActionCoverList}
        >
          Загрузить свою
        </button>
        {selected_bg_image && (
          <button
            className="PublicationCover__BarButton button button--default-fill2 button--md"
            type="button"
            onClick={() => this.setState({ forceShowUploader: false, background_image: article.background_image })}
            disabled={isDisabledActionCoverList}
          >
            Отмена
          </button>
        )}
      </>
    );
  }

  renderCover() {
    const { crop, background_image, forceShowUploader, selected_bg_image } = this.state;
    const { article } = this.props;
    if (!background_image) {
      // TODO Сохранять в блок id картинки и посылать запрос на удаление картинок на бэкенд
      if (article.megadraft_images && article.megadraft_images.length && !forceShowUploader) {
        const imageInBlockUrls = article.content.blocks
          .filter(({data: {type}}) => type == 'images')
          .map(({data: {images}}) => images)
          .reduce( (all, next) => [...all, ...next], []);
        const images = article.megadraft_images.filter(({ id }) => imageInBlockUrls.findIndex(img => img.id === id) !== -1);
        if (images.length) {
          return (
            <div className="PublicationCover">
              <div className="PublicationCover__List">
                <div className="PublicationCover__Bar">
                  <div className="PublicationCover__BarText">Выберите обложку в статье</div>
                  <div className="PublicationCover__BarButtons">{this.renderBarButtons()}</div>
                </div>
                <div className="PublicationCover__ImageList">
                  <div className="PublicationCover__ImageListWrap">
                    {images.map(({ id, url }) => (
                      <div className="PublicationCover__ImageItemWrap" key={id}>
                        <div
                          className={classNames('PublicationCover__ImageItem', {
                            'PublicationCover__ImageItem--Check': selected_bg_image === id || selected_bg_image === url,
                          })}
                          onClick={() => this.handlerSetCoverFromList(id)}
                          style={{
                            backgroundImage: `url(${url})`,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="PublicationCover__ListBottom">{this.renderBarButtons()}</div>
              </div>
            </div>
          );
        }
      }
      return (
        <div className="PublicationCover">
          <Dropzone
            className="PublicationCover__Uploader"
            articleId={article.id}
            fieldName="article[background_image]"
            type={TypeDropzone.COVER}
            onUploadComplete={this.handlerUploadComplete}
            onUploadStart={() => {}}
            ref={ref => {
              this.dropzoneRef = ref;
            }}
          />
        </div>
      );
    }
    return (
      <div className="PublicationCover">
        <>
          <Crop aspect={16 / 9} autoUpdate onSubmit={this.handlerCropPhoto} crop={crop} image={background_image} />
          <div className="PublicationCover__ChnagerImage">
            <ButtonIcon iconSrc={iconChangeImg} text="Заменить обложку" onClick={::this.deleteImage} />
            <input
              type="file"
              accept="image/*"
              onChange={this.handlerChangeBg}
              ref={ref => {
                this.inputFileRef = ref;
              }}
            />
          </div>
        </>
      </div>
    );
  }

  onClose() {
    const { background_image } = this.props.article;
    this.setState({ forceShowUploader: false, background_image }, () => {
      this.props.onClose();
    });
  }

  showRecommendations() {
    return this.props.article.other_publications_count > 2;
  }

  canChangeCompetable() {
    // На случай, если клиент одумается, код оставляю
    return false;
    if(!this.context || !this.context.me) {
      return false;
    }
    if(this.context.me.is_admin) {
      return true;
    }
    const { category_id } = this.props.article;
    if (category_id && this.context.me.own_category_ids.find(cat_id => cat_id == category_id)) {
      return true;
    }
    return false;
  }


  render() {
    const { isOpen, isPublishing } = this.props;
    const { recommendations, description, article_tags, error_descr, error_tag, isResizing, competable } = this.state;
    return (
      <Modal title="Подготовка к публикации" isOpen={isOpen} onRequestClose={::this.onClose}>
        <div className="form-group">
          <label htmlFor="article-cover">Обложка статьи</label>
          {this.renderCover()}
        </div>
        <div className="form-group">
          <label htmlFor="description">
            Кратко опишите ваш материал &ensp;
            <span>— обязательно к заполнению</span>
          </label>
          <PopoverError error="Добавьте описание" isShow={error_descr}>
            <TextAreaCounter
              id="description"
              value={description}
              maxLength={250}
              placeholder="Добавить описание"
              onChange={this.handlerChangeDescription}
              isError={error_descr}
              disabled={isPublishing || isResizing}
            />
          </PopoverError>
        </div>
        {
          this.showRecommendations() &&
          <div className="form-group">
            <label htmlFor="select-material">Связать свои материалы</label>
            <SelectMaterial
              id="select-material"
              userId={this.props.article.user.id}
              disabled={isPublishing || isResizing}
              materials={recommendations}
              onChange={this.handlerChangeMaterial}
            />
          </div>
        }
        <div className="form-group">
          <label htmlFor="select-tags">
            Добавьте хотя бы 1 тег и нажмите Enter &ensp;
            <span>— обязательно к заполнению</span>
          </label>
          <PopoverError error="Добавьте хотя бы 1 тег" isShow={error_tag}>
            <SelectTags
              id="select-tags"
              disabled={isPublishing || isResizing}
              tags={article_tags}
              onChange={this.handlerChangeTags}
              createOptionPosition="first"
              maxMenuHeight={120}
            />
          </PopoverError>
        </div>
        {this.canChangeCompetable() ?
          <div className="form-group">
            <label htmlFor="competable-checkbox">
              Участвует к конкурсах
            </label>
              <input
                type="checkbox"
                id="competable-checkbox"
                style={{'WebkitAppearance': 'checkbox', 'MozAppearance': 'checkbox', 'Appearance': 'checkbox'}}
                value={competable}
                onChange={this.handlerChangeCompetable}
                disabled={isPublishing || isResizing}
              />
          </div>
        : null }
        <div className="form-btns text-center">
          <button
            className="button button--prime"
            type="button"
            disabled={!this.state.saved || isPublishing || isResizing}
            onClick={this.handlerClickComplete}
          >
            Опубликовать сейчас
          </button>
        </div>
      </Modal>
    );
  }
}

ModalPublication.propTypes = {
  article: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default ModalPublication;
