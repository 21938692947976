import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';

import PopoverError from '~/editor2/components/Popovers/Error';
import Dropzone, { TypeDropzone } from '~/editor2/components/Dropzone';
import OrDivider from '~/editor2/components/OrDivider';
import Info from '~/editor2/components/Info';
import Modal from '~/editor2/components/Modal';
import { urlVideoParser } from '~/editor2/plugins/videos/helpers';


class ModalAddVideo extends PureComponent {
  constructor (props) {
    super(props);

    this.handlerUploadStart = ::this.handlerUploadStart;
    this.handlerUploadComplete = ::this.handlerUploadComplete;
    this.handlerChangeName = ::this.handlerChangeName;
    this.handlerChangeUrl = ::this.handlerChangeUrl;
    this.handlerClickAcceptFile = ::this.handlerClickAcceptFile;
    this.handlerClickAcceptUrl = ::this.handlerClickAcceptUrl;
    this.handlerCloseModal = ::this.handlerCloseModal;

    this.state = {
      canEdit: true,
      isSowAcceptFile: false,
      errorInputName: undefined,
      errorInputUrl: undefined,
      video: {},
      inputUrl: '',
      inputName: '',
    };
  }

  handlerUploadStart() {
    this.setState({ canEdit: false, errorInputName: undefined });
  }

  handlerUploadComplete(video) {
    this.setState({
      canEdit: true,
      video,
    }, () => {
      this.sendNameVideo();
    });
  }

  handlerChangeName(e) {
    this.setState({ inputName: e.target.value, errorInputName: undefined });
  }

  handlerChangeUrl(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ inputUrl: e.target.value, errorInputUrl: undefined });
  }

  handlerClickAcceptFile() {
    this.sendNameVideo();
  }

  handlerClickAcceptUrl() {
    const { inputUrl } = this.state;
    const urlInfo = urlVideoParser.parse(inputUrl);
    if (urlInfo) {
      this.setState({
        video: {
          external_url: inputUrl
        },
        errorInputUrl: undefined,
      }, () => {
        this.completeAddVideo();
      });
    } else {
      this.setState({
        errorInputUrl: true
      });
    }
  }

  sendNameVideo(){
    const { inputName, video } = this.state;
    const { articleId } = this.props;
    if (inputName) {
      if (video.id) {
        axios
          .put(`/api/v1/articles/${articleId}/videos/${video.id}`, {
            name: inputName
          })
          .then(() => this.completeAddVideo());
      } else {
        alert("Загрузите видео!");
      }
    } else {
      this.setState({
        errorInputName: true,
        isSowAcceptFile: true,
      });
    }
  }

  handlerCloseModal(){
    const { onClose } = this.props;
    if (onClose) {
      onClose();
      this.resetFileds();
    }
  }

  completeAddVideo() {
    const { onCompleteVideo } = this.props;
    const { video } = this.state;
    this.resetFileds();
    onCompleteVideo && onCompleteVideo(video);
  }

  resetFileds(){
    this.setState({
      canEdit: true,
      isSowAcceptFile: false,
      errorInputName: false,
      errorInputUrl: false,
      video: {},
      inputUrl: '',
      inputName: '',
    });
  }

  render () {
    const { showModal, articleId } = this.props;
    const {
      canEdit,
      inputUrl,
      inputName,
      errorInputName,
      errorInputUrl,
      isSowAcceptFile,
    } = this.state;

    return (
      <Modal
        title="Загрузка видео"
        isOpen={showModal}
        onRequestClose={this.handlerCloseModal}
      >
        <div className="form-group">
          <label htmlFor="img-url">
            Название видео
            &ensp;
            <span>— обязательно к заполнению</span>
          </label>
          <div className="form-control-group">
            <PopoverError
              error={
                <>
                  Дайте название видео,
                  <br />
                  которое вы загружаете.
                </>
              }
              isShow={errorInputName}
            >
              <input
                className={classNames('form-control', { 'is-error': errorInputName })}
                id="img-url"
                type="text"
                placeholder="Введите название"
                disabled={!canEdit}
                value={inputName}
                onChange={this.handlerChangeName}
              />
            </PopoverError>
            {isSowAcceptFile && (
              <button
                className="button button--prime"
                type="button"
                disabled={!canEdit}
                onClick={this.handlerClickAcceptFile}
              >
                Прикрепить видео
              </button>
            )}
          </div>
        </div>
        <div className="form-group">
          <Dropzone
            type={TypeDropzone.VIDEO}
            onUploadStart={this.handlerUploadStart}
            onUploadComplete={this.handlerUploadComplete}
            onError={() => {
              this.setState({ canEdit: true });
            }}
            articleId={articleId}
          />
        </div>
        <OrDivider />
        <div className="form-group">
          <label htmlFor="img-url">
            Вставить ссылку на видео
          </label>
          <div className="form-control-group">
            <PopoverError
              error={
                <>
                  Проверьте правильность
                  <br />
                  вставленной ссылки.
                  <br />
                  Поддерживается Youtube и Vimeo.
                </>
              }
              isShow={errorInputUrl}
            >
              <input
                className={classNames('form-control', { 'is-error': errorInputUrl })}
                id="img-url"
                type="text"
                placeholder="Ссылка"
                disabled={!canEdit}
                value={inputUrl}
                onChange={this.handlerChangeUrl}
              />
            </PopoverError>
            <button
              className="button button--prime"
              type="button"
              disabled={!canEdit}
              onClick={this.handlerClickAcceptUrl}
            >
              Прикрепить видео
            </button>
          </div>
          <Info text="Могут возникнуть проблемы с воспроизведением при просмотре видео с Омега или Альфа" />
        </div>
      </Modal>
    );
  }
}

ModalAddVideo.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onCompleteVideo: PropTypes.func,
};

export default ModalAddVideo;
