import axios from 'axios'
import debounce from 'lodash-es/debounce'
import template from './recommendation.pug'
import flash from '~/blocks/flash'

function suggest(event) {
  const params = {q: event.target.value}
  const ids = $('.js-article-destroy-recommendation').map(function() { return $(this).data('id') }).toArray();
  ids.push($('.js-editor').data('id'));
  params.id_not_in = ids.join(',');
  axios.get('/api/v1/search/articles', {params}).then(resp => {
    $('.js-recommendation-list').html(
      resp.data.map(item => template(item)).join("\n")
    )
  }).catch(err => {
    console.error(err)
  })
}

function init() {
  $(document).on('input', '.js-article-recommendation-autocomplete', debounce(suggest, 200))
  $(document).on('click', '.js-article-destroy-recommendation', function(event) {
    event.preventDefault()
    axios.delete($(this).attr('href'), {params: {id: $(this).data('id')}}).then(resp => {
      $(this).closest('.js-linked-article').remove()
    }).catch(err => {
      console.error(err)
      flash({error: 'Произошла ошибка'})
    })
  })
  $(document).on('click', '.js-submit-recommendation-btn', function(event) {
    event.preventDefault()
    const $form = $('.js-recommendation-form')
    axios.post($form.attr('action'), $form.serialize()).then(resp => {
      window.closeModal()
      $('#recommendation_material').append(resp.data)
    })
  })
}


document.addEventListener("turbolinks:load", init)
