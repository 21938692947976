import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './QuizOption.sass';

export const QuizOption = props => {
  const { name, label, onChange, checked = false, type, value, readonly, disabled } = props;

  const optionClass = classNames('QuizOption__label', {
    'QuizOption__label--type--checkbox': type === 'checkbox',
    'QuizOption__label--type--radio': type === 'radio',
  });

  return (
    <div
      className={classNames("QuizOption", {
        "QuizOption--Readonly": readonly,
        "QuizOption--Disabled": disabled,
      })}
    >
      <input
        className="QuizOption__input"
        type={type}
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
        id={name + value}
        readOnly={readonly}
        disabled={disabled}
      />
      <label htmlFor={name + value} className={optionClass}>
        {label}
      </label>
    </div>
  );
};

QuizOption.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default QuizOption;
