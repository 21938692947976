var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (avatar, follow, follows, publications, roles, user) {
      pug_html = pug_html + "\u003Cdiv class=\"tooltipster-content\"\u003E\u003Cdiv class=\"author_info\"\u003E\u003Cdiv class=\"row min\"\u003E\u003Cdiv" + (pug.attr("class", pug.classes(['col-xs-' + (follow ? '12' : '7')], [true]), false, true)) + "\u003E\u003Cdiv class=\"author_info_tooltip\"\u003E" + (null == (pug_interp = avatar) ? "" : pug_interp) + "\u003Cdiv class=\"nowrap\"\u003E\u003Cspan class=\"subbold size15 black\"\u003E" + (pug.escape(null == (pug_interp = user.name) ? "" : pug_interp)) + "\u003Cbr\u003E" + (pug.escape(null == (pug_interp = user.email) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Cspan class=\"normal size12 gray\"\u003E" + (pug.escape(null == (pug_interp = publications) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-5 alignright\"\u003E\u003Cdiv\u003E";
if (follows) {
pug_html = pug_html + "\u003Cbutton" + (pug.attr("class", pug.classes(['tooltipster__subscribe-btn js-tooltip-unsubscribe-'+user.id], [true]), false, true)) + "\u003EОтписаться\u003C\u002Fbutton\u003E";
}
else {
pug_html = pug_html + "\u003Cbutton" + (pug.attr("class", pug.classes(['tooltipster__subscribe-btn js-tooltip-subscribe-'+user.id], [true]), false, true)) + "\u003EПодписаться\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-12 roles\"\u003E";
if (user.is_admin) {
pug_html = pug_html + "\u003Cdiv class=\"roles_item\"\u003E\u003Cspan class=\"badge\"\u003EАдминистратор\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
if (user.is_moder) {
pug_html = pug_html + "\u003Cdiv class=\"roles_item\"\u003E\u003Cspan class=\"badge\" style=\"background-color: #000000\"\u003EМодератор\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
// iterate roles
;(function(){
  var $$obj = roles;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var role = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"roles_item\"\u003E\u003Cspan" + (" class=\"badge\""+pug.attr("style", pug.style('background-color:'+role.color), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = role.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
// iterate role.categories
;(function(){
  var $$obj = role.categories;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var category = $$obj[pug_index1];
pug_html = pug_html + "\u003Cspan class=\"badge default\"\u003E" + (pug.escape(null == (pug_interp = category.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var category = $$obj[pug_index1];
pug_html = pug_html + "\u003Cspan class=\"badge default\"\u003E" + (pug.escape(null == (pug_interp = category.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var role = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"roles_item\"\u003E\u003Cspan" + (" class=\"badge\""+pug.attr("style", pug.style('background-color:'+role.color), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = role.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
// iterate role.categories
;(function(){
  var $$obj = role.categories;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var category = $$obj[pug_index2];
pug_html = pug_html + "\u003Cspan class=\"badge default\"\u003E" + (pug.escape(null == (pug_interp = category.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var category = $$obj[pug_index2];
pug_html = pug_html + "\u003Cspan class=\"badge default\"\u003E" + (pug.escape(null == (pug_interp = category.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "avatar" in locals_for_with ?
        locals_for_with.avatar :
        typeof avatar !== 'undefined' ? avatar : undefined, "follow" in locals_for_with ?
        locals_for_with.follow :
        typeof follow !== 'undefined' ? follow : undefined, "follows" in locals_for_with ?
        locals_for_with.follows :
        typeof follows !== 'undefined' ? follows : undefined, "publications" in locals_for_with ?
        locals_for_with.publications :
        typeof publications !== 'undefined' ? publications : undefined, "roles" in locals_for_with ?
        locals_for_with.roles :
        typeof roles !== 'undefined' ? roles : undefined, "user" in locals_for_with ?
        locals_for_with.user :
        typeof user !== 'undefined' ? user : undefined));
    ;;return pug_html;};
module.exports = template;