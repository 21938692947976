/* eslint-disable camelcase */
/* global Turbolinks */
import React, { PureComponent } from 'react';
import axios from 'axios';

import debounce from 'lodash-es/debounce';
import cloneDeep from 'lodash-es/cloneDeep';
import pick from 'lodash-es/pick';
import isEqual from 'lodash-es/isEqual';

import './App.sass';

import iconDots from './dots.svg';
import iconClose from './close.svg';
import iconGuide from './icon-guide.svg';

import SelectCommunity from './components/Select/SelectCommunity';
import Guide from './components/Guide';
import EditorContent from '~/editor2/components/EditorContent';
import ModalPublication from '~/editor2/components/Modal/ModalPublication';
import PopoverMenu from '~/editor2/components/Popovers/Menu';
import PopoverError from '~/editor2/components/Popovers/Error';
import ModalDonePublication from '~/editor2/components/Modal/ModalDonePublication';

import { EditorContext } from './context';
import ModalQuizPub from "./components/Modal/ModalQuizPub";
import ModalQuizPubWhithoutDescr from "./components/Modal/ModalQuizPub/ModalQuizPubWhithoutDescr";

const { CancelToken } = axios;

function getChanges(sv) {
  return pick(sv, 'title', 'category_id', 'content');
}

export default class App extends PureComponent {

  editorRef = React.createRef();

  constructor(props) {
    super(props);
    this.debouncedPersistChanges = debounce(::this.persistChanges, 500);
    this.state = {
      article: {},
      context_value: {},
      initialContent: null,
      showGuide: props.showguide || false,
      isLoading: true,
      isOpenPubModal: false,
      isOpenPubModalDone: false,
      isOpenPubQuizModal: false,
      isOpenPubQuizWithoutDescrModal: false,
      isOpenMenu: false,
      isPublishing: false,

      isErrorTitle: false,
      isErrorContent: false,
      isDisabledBack: false
    };
  }

  componentDidMount() {
    this.loadArticle();
    this.loadMe();
  }

  setCommunity = ({ value, label }) => {
    this.setState(
      state => ({
        ...state,
        article: {
          ...state.article,
          category: {
            id: value,
            name: label,
          },
          category_id: value,
        },
      }),
      this.debouncedPersistChangesWithCancel,
    );
  };

  handlerChangeEditorContent = changeData => {
    console.log('handlerChangeEditorContent');
    this.setState(
      state => ({
        ...state,
        article: {
          ...state.article,
          ...changeData,
        },
      }),
      this.debouncedPersistChangesWithCancel,
    );
  };

  showErrorCategory = (error) => {
    console.log('showErrorCategory');
    this.setState({
      errorCategory: error
    });
  }

  handleErrorEditorContent = (error) => {
    console.log(error);
    if (error) {
      if ('category' in error) {
        this.showErrorCategory(error.category);
      }
    }
  }

  handlerClickOpenPublication = () => {
    const { article } = this.state;
    if (article.category_id) {
      if (article.title) {
        if (
          article.content && (
            article.content.blocks.length > 1
            || !!article.content.blocks[0].text
          )) {

          const blocksContent = this.editorRef
            .current
            .state
            .editorState
            .getCurrentContent()
            .getBlockMap();

          const blocksData = blocksContent
            .map(block => block.getData().toJSON());

          const quizBlocks = blocksData.filter(blockData => blockData.type === 'quiz');

          const isQuizWithoutDescription = quizBlocks.length > 0 && blocksContent
            .filter(block => block.getType() !== 'atomic')
            .every(block => !block.getText());

          const isHaveNotFilledQuiz = quizBlocks
            .some(blockData => !blockData.isFilled);

          if (isHaveNotFilledQuiz) {
            this.setState({ isOpenPubQuizModal: true });
          } else if (isQuizWithoutDescription) {
            this.setState({
              isOpenPubQuizWithoutDescrModal: true,
            });
          } else {
            this.setState({ isOpenPubModal: true });
          }
        } else {
          this.setState({
            isErrorContent: true
          });
        }
      } else {
        this.setState({
          isErrorTitle: true
        });
      }
    } else {
      this.showErrorCategory(
        <>
          Выберите сообщество<br/>
          в которое публикуете<br/>
          вашу статью
        </>
      );
    }
  };


  handlerCloseModalPub = () => {
    this.setState({ isOpenPubModal: false });
  };

  handlerCloseDonePub = () => {
    const { article } = this.state;
    window.location = `/article/${article.id}`;
  }

  handlerCompletePublicationModal = changeFromPublicationModal => {
    const { id } = this.props;
    this.setState({
      isPublishing: true,
    }, () => {
      axios
        .put(this.backendURL(), {
          article: changeFromPublicationModal,
        })
        .then(() => axios.post(`/api/v1/articles/${id}/publish`).then(resp => {
            const article = resp.data;
            this.setState({
              article,
              isOpenPubModal: false,
              isOpenPubModalDone: true,
              isPublishing: false,
            });
          }))
        .catch(err => {
          this.setState({
            isPublishing: false,
          });
          console.log(err.response);
          if (err.response && err.response.data.error) {
            alert(err.response.data.error);
          } else {
            alert("Возникла неизвестная ошибка при публикации");
          }
        });
    });
  };

  goBack() {
    this.setState({isDisabledBack: true});
    if (window.history) {
      window.history.back();
    } else {
      console.error('no window.history to go back to');
    }
  }

  backendURL() {
    const { id } = this.props;
    return `/articles/${id}.json`;
  }

  canceToken() {
    if (this.axios_source) {
      this.axios_source.cancel('new request');
    }
    this.axios_source = CancelToken.source();
  }

  loadArticle() {
    axios
      .get(this.backendURL())
      .then(r => {
        this.setState({
          article: cloneDeep(r.data),
          savedArticle: cloneDeep(r.data),
          initialContent: cloneDeep(r.data.content),
          isLoading: false,
        });
      })
      .catch(e => {
        console.error(e);
        alert('При загрузке материала произошла ошибка. Пожалуйста, попробуйте ещё раз.');
      });
  }

  loadMe() {
    axios.get('/api/v1/me').then(resp => {
      this.setState({context_value: {me: resp.data}});
    }).catch(err => {
      console.error(err);
    });
  }

  resetErrors() {
    console.log('resetErrors');
    this.setState({
      isErrorTitle: false,
      isErrorContent: false,
      errorCategory: undefined
    });
  }

  debouncedPersistChangesWithCancel = () => {
    this.canceToken();
    this.debouncedPersistChanges();
  }

  persistChanges() {
    const { article, savedArticle } = this.state;
    const changes = getChanges(article);
    const prevChanges = getChanges(savedArticle);
    if (isEqual(changes, prevChanges)) {
      // console.log('no persist - no changes', changes, prevChanges);
      return;
    }
    this.resetErrors();
    this.setState({
      saving: true,
    });
    axios
      .put(
        this.backendURL(),
        { article: changes },
        {
          cancelToken: this.axios_source.token,
        },
      )
      .then(r => {
        this.setState({
          saving: false,
          article: {
            ...article,
            ...r.data,
          },
          savedArticle: cloneDeep({
            ...article,
            ...r.data,
          }),
        });
      })
      .catch(e => {
        if (axios.isCancel(e)) {
          console.log('Request canceled', e.message);
        } else {
          console.error(e);
          if (e.response.data && e.response.data.error) {
            alert(e.response.data.error);
            // console.log(this.state.article.article_tags)
            // console.log("restore saved", this.state.savedArticle.article_tags)
            this.setState({ article: cloneDeep(this.state.savedArticle) });
          } else {
            alert('При сохранении материала произошла ошибка. Пожалуйста, попробуйте ещё раз.');
          }
        }
      });
  }

  notifyGuideWatched = () => {
    this.setState({ showGuide: false });
    axios
      .post(`/api/v1/users/watch_editor2_guide`)
      .then(() => {})
      .catch(err => {
        console.error(err);
      });
  }

  onChangeArticle = (article) => {
    this.setState({ article });
  }

  handleCloseMenu = () => {
    this.setState({ isOpenMenu: false });
  }

  handleOpenMenu = () => {
    this.setState({ isOpenMenu: true }, () => {
      document.addEventListener('click', this.handleCloseMenu, { once: true });
    });
  }

  handleSelectFocus = () => {
    console.log('handleSelectFocus');
    this.setState({
      isOpenMenu: false,
      isErrorTitle: false,
      isErrorContent: false,

      errorCategory: undefined,
    });
  }

  render() {
    const {
      saving,
      article,
      isLoading,
      showGuide,
      isOpenPubModal,
      isOpenPubModalDone,
      isOpenPubQuizModal,
      isOpenPubQuizWithoutDescrModal,
      isOpenMenu,
      isPublishing,
      isErrorTitle,
      isErrorContent,
      errorCategory,
      isDisabledBack,
      context_value,
    } = this.state;
    const category = article && article.category && { value: article.category.id, label: article.category.name };
    const user = article && article.user;
    const updated_at = article && article.updated_at_human;
    return (
      <EditorContext.Provider value={{ ...context_value, isOpenPubQuizModal }}>
        <div className="Editor2">
          <div className="Editor2__Head">
            <div className="Editor2__HeadContainer">
              <div className="Editor2__HeadInner">
                <div className="Editor2__HeadTop">
                  <div className="Editor2__HeadTop-inner">
                    <div className="Editor2__HeadInfo">
                      <div className="Editor2__User">
                        <div className="Editor2__UserAvatarWrap">
                          <img className="Editor2__UserAvatarImg" src={user && user.vs_avatar} alt="" />
                        </div>
                        <div className="Editor2__UserAvatar-inner">
                          <div className="Editor2__UserName">{user && user.name}</div>
                          <div className="Editor2__Date">
                            {!saving && `Сохранено ${updated_at}`}
                            {saving && 'Сохранение...'}
                          </div>
                          <div className="Editor2__Date Editor2__Date--mobile">
                            {!saving && `Сохр. ${updated_at}`}
                            {saving && 'Сохранение...'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Editor2__HeadActions">
                      <div className="Editor2__HeadAction">
                        <PopoverMenu
                          isOpen={isOpenMenu}
                          items={[
                            {
                              title: 'Мои материалы',
                              onClick: () => {
                                this.setState({ isOpenMenu: false });
                                Turbolinks.visit('/materials/publications');
                              },
                            },
                            {
                              title: 'Удалить статью',
                              onClick: () => {
                                this.setState({ isOpenMenu: false });
                                axios
                                  .delete(`/articles/${article.id}`)
                                  .then(() => {
                                    Turbolinks.visit('/');
                                  })
                                  .catch(err => {
                                    console.log(err);
                                    alert('Не удалось удалить статью!');
                                  });
                              },
                              modify: 'danger',
                            },
                          ]}
                        >
                          <button
                            className="button Editor2__BtnMore"
                            type="button"
                            onClick={this.handleOpenMenu}
                          >
                            <img src={iconDots} alt="" />
                          </button>
                        </PopoverMenu>
                      </div>
                      <div className="Editor2__HeadAction">
                        <button
                          className="button button--prime"
                          type="button"
                          onClick={this.handlerClickOpenPublication}
                        >
                          Опубликовать
                        </button>
                      </div>
                    </div>
                    <button className="Editor2__Close" disabled={isDisabledBack} onClick={::this.goBack}>
                      <img src={iconClose} alt="Назад" />
                    </button>
                  </div>
                </div>
                <div className="Editor2__CommunitySelect">
                  <div className="Editor2__CommunitySelectTitle">Выберите сообщество</div>
                  <div className="Editor2__CommunitySelectControl">
                    <PopoverError
                      error={errorCategory}
                      isShow={!!errorCategory}
                    >
                      <SelectCommunity
                        onFocus={this.handleSelectFocus}
                        onChange={this.setCommunity}
                        value={category}
                        isError={!!errorCategory}
                      />
                    </PopoverError>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Editor2__Content">
            <EditorContent
              ref={this.editorRef}
              article={article}
              isLoading={isLoading}
              isErrorTitle={isErrorTitle}
              isErrorContent={isErrorContent}
              onChange={this.handlerChangeEditorContent}
              onError={this.handleErrorEditorContent}
            />
          </div>
          <button
            className="Editor2__BtnGuide"
            type="button"
            title="Помощь"
            onClick={() => this.setState({ showGuide: true })}
          >
            <img src={iconGuide} alt="" />
          </button>
          <div className="Editor2__footer-mobile">
            <div className="Editor2__footer-inner">
              <div className="Editor2__footer-help" onClick={() => this.setState({ showGuide: true })}>
                <button
                  className="Editor2__BtnGuide-mobile"
                  type="button"
                  title="Помощь"
                >
                  <img src={iconGuide} alt="" />
                </button>
                <div className="Editor2__footer-help-text">
                  Помощь
                </div>
              </div>
              <div className="Editor2__footer-public" onClick={this.handlerClickOpenPublication}>
                  Опубликовать
              </div>
            </div>
          </div>
          <Guide className="Editor2__Guide" isShow={showGuide} onClose={this.notifyGuideWatched} />
          <ModalDonePublication
            isShow={isOpenPubModalDone}
            article={article}
            onClose={this.handlerCloseDonePub}
          />
          <ModalPublication
            article={article}
            isOpen={isOpenPubModal}
            isPublishing={isPublishing}
            onClose={this.handlerCloseModalPub}
            onComplete={this.handlerCompletePublicationModal}
            onChange={this.onChangeArticle}
          />
          <ModalQuizPub
            isOpen={isOpenPubQuizModal}
            onAccept={() => {
              this.setState({
                isOpenPubModal: true,
                isOpenPubQuizModal: false,
              });
            }}
            onCancel={() => {
              this.setState({
                isOpenPubQuizModal: false,
              });
            }}
          />
          <ModalQuizPubWhithoutDescr
            isOpen={isOpenPubQuizWithoutDescrModal}
            onClose={() => {
              this.setState({
                isOpenPubQuizWithoutDescrModal: false,
              });
            }}
          />
        </div>
      </EditorContext.Provider>
    );
  }
}
