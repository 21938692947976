import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';

import Icon from './icon.r.svg';
import constants from './constants';

import insertDataBlock from '~/editor2/utils/draftjs/insertDataBlock';
import ModalAddVideo from '~/editor2/components/Modal/ModalAddVideo';
import { Sizes } from '~/editor2/components/Popovers/Size'
import {withEditorContentContext} from "~/editor2/components/EditorContent/EditorContentContext";

class Button extends PureComponent {
  constructor(props) {
    super(props);

    this.handlerClickBtn = ::this.handlerClickBtn;
    this.handleCloseModal = ::this.handleCloseModal;
    this.handlerCompleteAddVideo = ::this.handlerCompleteAddVideo;

    this.state = {
      showModal: false,
    };
  }

  handlerClickBtn() {
    const { article = {}, onError } = this.props;
    if (!article.category && onError) {
      onError({
        category: <>Для добавления<br />видео, необходимо<br />выбрать сообщество</>
      });
      return;
    }
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handlerCompleteAddVideo(video){
    const { onChange, editorState } = this.props;
    const dataBlock = {
      type: constants.PLUGIN_TYPE,
      caption: '',
      size: Sizes.CONTAINS,
      video,
    };
    this.setState({
      showModal: false,
    });
    const editorStateWithVideo = insertDataBlock(editorState, dataBlock);
    onChange(editorStateWithVideo);

    // помогает правильно поставить фокус после вставки видео
    setTimeout(() => {
      const contentState = editorStateWithVideo.getCurrentContent();
      onChange(EditorState.push(editorStateWithVideo, contentState.merge({
        selectionBefore: contentState.getSelectionBefore(),
        selectionAfter: contentState.getSelectionAfter().set('hasFocus', true)
      }), 'change-selection'));
    }, 100);
  }

  render() {
    const { className, article = {} } = this.props;
    const {
      showModal,
    } = this.state;
    // console.log('videos plugin button props', this.props)
    return (
      <>
        <button
          className={className}
          type="button"
          onClick={this.handlerClickBtn}
          title={constants.PLUGIN_TITLE_BUTTON}
        >
          <Icon />
        </button>
        <ModalAddVideo
          showModal={showModal}
          articleId={article.id}
          onClose={this.handleCloseModal}
          onCompleteVideo={this.handlerCompleteAddVideo}
        />
      </>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string,
  editorState: PropTypes.any,
  article: PropTypes.any,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};

export default withEditorContentContext(Button);
