import './index.scss';
import './subscribe-popup/subscribers-popup.scss';
import axios from 'axios';
import template from './subscribe-popup/subscribers-popup.pug';
import { preloader } from '~/blocks/infinity-scroll/index';
import flash from '~/blocks/flash/index';

const subscribershandler = (params = {}, title = '') => e => {
  if (e.target.tagName === 'A') return;
  e.preventDefault();
  $.magnificPopup.open({
    type: 'inline',
    modal: true,
    showCloseBtn: false,
    fixedContentPos: true,
    fixedBgPos: true,
    items: {
      src: preloader,
    },
    callbacks: {
      open() {
        const paramsRequest = {
          ...params,
          subscriptionable_type: 'Category',
          subscriptionable_id: $(e.currentTarget).data('category_id'),
        };
        axios
          .get(`/api/v1/user_subscriptions`, {
            params: paramsRequest,
          })
          .then(resp => {
            const $content = $('.mfp-content');
            $content.html(
              template({
                title,
                community: $(e.currentTarget).data(),
                users: resp.data,
              })
            );
            if (resp.data && resp.data.length && resp.headers && resp.headers['x-next-page']) {
              const $subsList = $content.find('.subscribers-popup__subscriber-list');
              const $preloader = $(preloader);
              let nextPage = resp.headers['x-next-page'];
              let isLoading = false;
              $subsList.append($preloader);
              $subsList
                .on('scroll', function handlerScrollListSubs () {
                  if (isLoading) return;
                  if ($subsList.scrollTop() + ($subsList.outerHeight() * 1.2) >= $subsList[0].scrollHeight) {
                    isLoading = true;
                    axios
                      .get(nextPage, { params: paramsRequest })
                      // .then((response) => new Promise((resolve => setTimeout(() => resolve(response), 1000))))
                      .then((respNextPage) => {
                        const tplPopup =  template({
                          title,
                          community: $(e.currentTarget).data(),
                          users: respNextPage.data,
                        });
                        nextPage = respNextPage.headers['x-next-page'];
                        const $items = $(tplPopup).find('.subscribers-popup__subscriber');
                        $items.insertBefore($preloader);
                        // $subsList.append();
                        if (
                          respNextPage.data
                          && respNextPage.data.length
                          && respNextPage.headers
                          && respNextPage.headers['x-next-page']
                        ) {
                          setTimeout(() => { isLoading = false; }, 300);
                        } else {
                          console.log('remove');
                          $preloader.remove();
                        }
                      })
                      .catch(() => {
                        isLoading = false;
                      });
                  }
                });
            }
          })
          .catch((err) => {
            console.log(err);
            $.magnificPopup.close();
            flash({
              error: 'Не удалось получить пользователей.'
            });
          });
      },
    },
  });
};

export function initSubscriptionForm() {
  $('.js-my-subscribers-category').off('click.my-subs').on('click.my-subs', subscribershandler({ my: true }, 'Подписчики, которых вы знаете'));
  $('.js-subscribers-category').off('click.subs-cat').on('click.subs-cat', subscribershandler({}, 'Подписчики'));

  $('.js-subscription-form')
    .off('change.async-load')
    .on('change.async-load', function(e) {
      e.preventDefault();
      const $form = $(this);
      const $inputCheck = $form.find('input[name="check"]');
      const $button = $form.find('.community__button');
      $button.attr('disabled', true);
      $form.ajaxSubmit({
        success() {
          if ($inputCheck.is(':checked')) {
            $button.removeClass('community__button--subscribe');
            $button.addClass('community__button--unsubscribe');
            $button.contents().last()[0].textContent = 'Отписаться';
          } else {
            $button.addClass('community__button--subscribe');
            $button.removeClass('community__button--unsubscribe');
            $button.contents().last()[0].textContent = 'Подписаться';
          }
          $button.attr('disabled', false);
        },
        error(e) {
          $button.attr('disabled', false);
          console.error(e);
        },
      });
    });
}
$(document).on('turbolinks:load', initSubscriptionForm);
