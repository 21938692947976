import React from 'react'

import Button from './Button';
import Block from './Block';
import constants from './constants';
import './styles.sass';

/**
 * @param {Number} articleId
 * @param {Array<Object>} collection
 * @param {Boolean} isReadOnly
 * @return {{buttonComponent: (function(*): *), blockComponent: (function(*): *), title: *, type: *}}
 */
export default {
  title: constants.PLUGIN_NAME,
  type: constants.PLUGIN_TYPE,
  buttonComponent: Button,
  blockComponent: Block,
};

