import debounce from 'lodash-es/debounce';
import axios from 'axios';
function init() {
  $('.js-leaderboard-search').on('input keyup change', debounce(function(event) {
    const params = {q: $(this).val()};
    axios.get(window.location.pathname, {params}).then(resp => {
      $('.js-leader-users').html(resp.data)
      window.history.pushState(params, $('title').text(), window.location.pathname + '?' + $.param(params))
    })
  }, 200))
}

$(document).on('turbolinks:load', init)
