import { old } from './old';

export function render(html, isNew) {
  window.showModal(html, isNew);
  window.initLables();
  old();

  return import(/* webpackChunkName: "tinymce" */ `~/tinymce`).then(({ tinymce, contentStyle }) => {
    return tinymce.init({
      skin: false,
      selector: '.js-competition-tinymce',
      body_class : "competition-tinymce-body",
      content_style: contentStyle.toString(),
      menubar: false,
      language: 'ru',
      statusbar: false,
      // paste_as_text: true,
      plugins: 'paste link lists placeholder autoresize',
      toolbar: 'bold italic underline strikethrough | bullist numlist | link',
      default_link_target: "_blank",
      autoresize_bottom_margin: 10,
      height: "200"
    });
  });
}
