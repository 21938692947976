import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import isEqual from 'lodash-es/isEqual';
import Plyr from 'plyr-react';
import 'plyr-react/dist/plyr.css';

import memoize from "lodash-es/memoize";
import SizePopover, { Sizes } from '~/editor2/components/Popovers/Size';
import { getEmbededDataVideo } from '~/editor2/plugins/videos/helpers';
import ButtonIcon from '~/editor2/components/ButtonIcon';
import ModalAddVideo from '~/editor2/components/Modal/ModalAddVideo';
import iconBtnChangeVideo from '~/editor2/components/Dropzone/icon-btn-change-video.svg';
import { preloader } from '~/blocks/infinity-scroll';
import {withEditorContentContext} from "~/editor2/components/EditorContent/EditorContentContext";

const MEDIATEKA_CONVERTED = 'converted';

const VIDEO_QUALITIES = ['360p', '720p', '1080p'];
const VIDEO_QUALITIES_SIZES = {
  '360p': 360,
  '720p': 720,
  '1080p': 1080,
};

/**
 * @type import("plyr/src/js/plyr.d.ts").Plyr.Options
 */
const PLAYER_OPTIONS = {
  controls: [
    'play',
    'progress',
    'current-time',
    'duration',
    'mute',
    'volume',
    'settings',
    'fullscreen',
  ],
  settings: [
    'quality'
  ],
  quality: {
    default: 720,
    options: [360, 720, 1080],
  },
  i18n: {
    quality: 'Качество'
  }
};

class Block extends PureComponent {
  constructor(props) {
    super(props);
    this.handlerChangeCaption = ::this.handlerChangeCaption;
    this.handleClickPopover = ::this.handleClickPopover;
    this.handlerChangeSize = ::this.handlerChangeSize;
    this.handlerCloseModal = ::this.handlerCloseModal;
    this.handlerCompleteAddVideo = ::this.handlerCompleteAddVideo;
    this.handlerClickBtnChange = ::this.handlerClickBtnChange;
    this.state = {
      isShowSizer: false,
      isShowModal: false,
      isLoadingMedia: false,
    };
  }

  componentDidMount() {
    const video = this.getVideo();
    const canLoadingMedia = !!(video && video.mediateka_url);
    if (canLoadingMedia) {
      this.loadMediateka();
    }
  }

  componentDidUpdate(oldProps) {
    const { urlFromMediateka, isLoadingMedia, mediatekaError } = this.state;
    const video = this.getVideo();
    const canLoadingMedia = !!(video && video.mediateka_url);
    if (isEqual(oldProps.data.video, this.props.data.video)) {
      return;
    }
    if (!isLoadingMedia && !urlFromMediateka && canLoadingMedia && !mediatekaError) {
      this.loadMediateka();
    }
  }

  videoData(response) {
    const { linksToVideo, linkToPoster } = response;
    const videos = linksToVideo
      .filter(({ fileFormatSize }) => VIDEO_QUALITIES.includes(fileFormatSize))
      .reduce((acc, curr) => ({
          ...acc,
          [curr.fileFormatSize]: curr,
        }), {});
    const poster = (linkToPoster && linkToPoster !== "")
      ? linkToPoster
      : linksToVideo.find(({fileMimeType}) => fileMimeType.startsWith('image/'));
    return {
      videos,
      poster,
    };
  }

  loadMediateka() {
    const video = this.getVideo();
    this.setState({
      isLoadingMedia: true
    }, () => {
      const ax = axios.create();
      ax.defaults.headers.common = {};
      // console.log(ax.defaults)
      ax.get(video.mediateka_url, {crossDomain: true, headers: {
        'Request-String': video.request_string,
        'Request-Hash': video.request_hash,
      }})
        .then((resp) => {
          if (resp.data.authCode == 200) {
            const mediatekaData = this.videoData(resp.data);
            if (mediatekaData.videos) {
              this.setState({
                isLoadingMedia: false,
                mediatekaData,
              });
            } else {
              this.setState({ isLoadingMedia: false });
            }
          } else {
            this.setState({
              isLoadingMedia: false,
            });
            window.open('https://media.sberbank-school.ru');
          }
        })
        .catch((err) => {
          console.error(err);
          axios.post('/api/v1/frontend_errors', {
            message: 'Ошибка загрузки данных из медиатеки',
            data: {
              message: err.message
            },
            location: window.location.href
          })
            .then(() => {})
            .catch(() => {});
          // alert('Ошибка загрузки данных из медиатеки!');
          this.setState({
            isLoadingMedia: false,
            mediatekaError: err,
          });
        });
    });
  }

  handlerChangeCaption(event) {
    const { container } = this.props;
    container.updateData({ caption: event.target.value });
  }

  handleClickPopover(e) {
    if (!this.isCanEdit()) return;
    e.preventDefault();
    const { isShowSizer } = this.state;
    this.setState({
      isShowSizer: !isShowSizer,
    });
  }

  handlerChangeSize(newSize) {
    const { container } = this.props;
    container.updateData({ size: newSize });
  }

  handlerCloseModal() {
    this.setState({ isShowModal: false });
  }

  handlerCompleteAddVideo(video) {
    const { container } = this.props;
    container.updateData({ video });
    this.setState({ isShowModal: false });
  }

  handlerClickBtnChange(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isShowModal: true });
  }

  handlerFocus = () => {
    this.setState({ isFocused: true });
  }

  handlerBlur = () => {
    this.setState({ isFocused: false });
  }

  isCanEdit() {
    const { isFocused } = this.state;
    const { isReadOnly } = this.props;
    return !isReadOnly; // || isFocused;
  }

  getVideo() {
    const { data, article = {} } = this.props;
    const collection = article.megadraft_videos || [];
    const { video } = data;
    if (video.id) {
      return collection.find(item => item.id === video.id);
    }
    return video;
  }

  getSourcesPlayer = memoize(() => {
    const { mediatekaData } = this.state;
    const video = this.getVideo();

    const availableQualities =
      mediatekaData &&
      mediatekaData.videos &&
      Object.keys(mediatekaData.videos).length > 0 &&
      VIDEO_QUALITIES.filter(quality => mediatekaData.videos[quality]);

    return {
      type: 'video',
      poster: mediatekaData && mediatekaData.poster,
      sources: availableQualities && availableQualities.length > 0
        ? availableQualities.map(quality => {
            const { fileMimeType, fileLink } = mediatekaData.videos[quality];
            return {
              type: fileMimeType,
              src: fileLink,
              size: VIDEO_QUALITIES_SIZES[quality]
            };
          })
        : [{
          src: video.url || video.external_url,
        }]
    };
  }, (...arg) => JSON.stringify(arg))

  render() {
    const { isShowSizer, isShowModal, isLoadingMedia, mediatekaData } = this.state;
    const { data, article = {} } = this.props;
    const { size, caption } = data;
    const video = this.getVideo();

    if (!video || isLoadingMedia) {
      return (
        <div
          className={classNames('Videos', {
            'Videos--Contains': size === Sizes.CONTAINS,
            'Videos--Float': size === Sizes.FLOAT,
            'Videos--FullWidth': size === Sizes.FULL_WIDTH,
            'Videos--FullScreen': size === Sizes.FULL_SCREEN,
          })}
        >
          <div className="Videos__Wrapper">
            <div className="Videos__Video Videos__Video--WithMenu">
              <div className="Videos__Preloader" dangerouslySetInnerHTML={{ __html: preloader }} />
            </div>
          </div>
        </div>
      );
    }

    const embededData = video.external_url && getEmbededDataVideo(video.external_url);

    return (
      <div
        className={classNames('Videos', {
          'Videos--Contains': size === Sizes.CONTAINS,
          'Videos--Float': size === Sizes.FLOAT,
          'Videos--FullWidth': size === Sizes.FULL_WIDTH,
          'Videos--FullScreen': size === Sizes.FULL_SCREEN,
        })}
        onFocus={this.handlerFocus}
        onBlur={this.handlerBlur}
        onInput={(e) => e.stopPropagation()}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className="Videos__Wrapper">
          <SizePopover
            selected={size}
            onChange={this.handlerChangeSize}
            onOuterAction={this.handleClickPopover}
            isOpen={this.isCanEdit() && isShowSizer}
          >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className={classNames('Videos__Video', {
                'Videos__Video--WithMenu': this.isCanEdit() && isShowSizer,
              })}
              onClick={this.handleClickPopover}
            >
              {!embededData && (
                <div className="Videos__VideoPlayer">
                  <Plyr source={this.getSourcesPlayer({ video, mediatekaData })} options={PLAYER_OPTIONS} />
                </div>
              )}
              {embededData && (
                <>
                  {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                  <iframe
                    className="Videos__VideoEmbed"
                    src={embededData.embeded}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </>
              )}
              {this.isCanEdit() && (
                <>
                  <div className="Videos__VideoOverflow" />
                  <ButtonIcon
                    className="Videos__Btn"
                    text="Заменить видео"
                    iconSrc={iconBtnChangeVideo}
                    onClick={this.handlerClickBtnChange}
                  />
                </>
              )}
            </div>
          </SizePopover>
          {!this.isCanEdit() && !!caption && <div className="Videos__Caption">{caption}</div>}
          {this.isCanEdit() && (
            <input
              className="Videos__Caption"
              value={caption}
              placeholder="Добавьте подпись (опционально)"
              onChange={this.handlerChangeCaption}
            />
          )}
        </div>
        {this.isCanEdit() && (
          <ModalAddVideo
            showModal={isShowModal}
            onClose={this.handlerCloseModal}
            onCompleteVideo={this.handlerCompleteAddVideo}
            articleId={article.id}
          />
        )}
      </div>
    );
  }
}

Block.propTypes = {
  container: PropTypes.any,
  article: PropTypes.any,
  isReadOnly: PropTypes.bool,
  data: PropTypes.shape({
    video: PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    }),
    size: PropTypes.oneOf([Sizes.CONTAINS, Sizes.FULL_SCREEN, Sizes.FLOAT, Sizes.FULL_WIDTH]).isRequired,
    caption: PropTypes.string,
  }),
};

export default withEditorContentContext(Block);
