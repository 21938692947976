import axios from 'axios';
import 'raty-js';
import 'raty-js/lib/jquery.raty.css';

import starHalf from './star-half.svg';
import starOff from './star-empty.svg';
import starOn from './star-full.svg';

import './ratings.sass';

const hints = ['Очень плохо', 'Плохо', 'Средне', 'Хорошо', 'Очень хорошо'];

function init() {
  $('.star-ratings').each(function () {
    const $star = $(this).find('.star-ratings__stars');
    $star.raty({
      starHalf,
      starOff,
      starOn,
      hints,
      readOnly: true,
    });
  });
}


export function updateStars($elem, score) {
  $elem.raty('readOnly', false);
  $elem.raty('score', score);
  $elem.raty('readOnly', true);
}

$(document).on('turbolinks:load', init);
