import React from 'react';
import ReactDOM from 'react-dom';
import ModalCommunity from './ModalCommunity';

class AppModalCommunity {
  /**
   * @param {HTMLElement} el
   * @param {object} props
   * @param {function} props.onClick
   * @param {number} props.categoryId
   */
  constructor(el, props) {
    this.el = el;
    ReactDOM.render(
      <ModalCommunity {...props} />,
      this.el
    );
  }

  destroy() {
    ReactDOM.unmountComponentAtNode(this.el);
  }
}

export default AppModalCommunity;
