import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import debounce from "lodash-es/debounce";

import {QuizShow} from "./QuizShow";
import {QuizEdit, QuizFormat} from "./QuizEdit";
import {withEditorContentContext} from "~/editor2/components/EditorContent/EditorContentContext";

class Block extends Component {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    isCanFocusQuestion: this.props.data?.isNew,
    isFocused: false,
    isPreview: false,
    isLoading: true,
    isErrorLoading: false,
    isVoting: false,

    quiz: null,
    answers: null,
    users: null,

    isShowEndDate: false
  };

  componentDidMount() {
    const {isReadOnly, data, container} = this.props;

    if (data.isNew) {
      container.updateData({
        ...data,
        isNew: undefined
      });
    }

    if (!isReadOnly || data.isFilled) {
      this.loadData();
    }
  }

  checkCompleteQuiz = () => {
    const {data, container} = this.props;
    const { quiz, answers} = this.state;
    container.updateData({
      ...data,
      isFilled:
        !!quiz.question
        && (answers.length > 1 && !answers.some(answ => !answ.answer))
        && (!data.isRequiredDateEnd || !!quiz.date_end)
    });
  }

  handleDateEndShow = isShowEndDate => {
    const {data, container} = this.props;
    container.updateData({
      ...data,
      isRequiredDateEnd: isShowEndDate,
    });
  }

  handlerFocus = () => {
    this.setState({isFocused: true});
  };

  handlerBlur = () => {
    this.setState({isFocused: false});
  };

  handleEdit = () => {
    const {data} = this.props;
    alert(JSON.stringify(data, null, 4));
  };

  handleTogglePreview = () => {
    this.setState(state => ({
      ...state,
      isPreview: !state.isPreview,
    }));
  };

  handleVote = answers => {
    const {data: {id}} = this.props;
    this.setState({ isVoting: true });
    axios
      .put(`/api/v1/quizzes/${id}/vote`, { answer_ids: answers })
      .then(this.loadData)
      .then(() => {
        this.setState({ isReVote: false });
      })
      .catch(() => {
        alert('Не удалось сохранить голос. Попробуйте позже.');
      })
      .finally(() => {
        this.setState({ isVoting: false });
      });
  };

  handleRevote = () => {
    this.setState({ isReVote: true });
  }

  handleRemove = () => {
    const {blockProps, container} = this.props;
    blockProps.setInitialReadOnly();
    container.remove();
  };

  handleUpdateQuiz = quiz => {
    if (this.cancelUpdateQuiz) {
      this.cancelUpdateQuiz.cancel();
    }
    this.cancelUpdateQuiz = axios.CancelToken.source();
    this.setState({
      quiz
    }, () => {
      this.debouncedSaveQuiz();
    });
  };

  handleAddAnswer = () => {
    const {quiz, answers} = this.state;
    axios
      .put(`/api/v1/quizzes/${quiz.id}`, {quiz: {answers_attributes: [...answers, {answer: ''}]}})
      .then((response) => {
        this.saveQuizFromResponse(response).then(() => this.checkCompleteQuiz());
      })
      .catch((err) => {
        console.error(err);
        alert('Не удалось создать вариант ответ. Попробуйте позже.');
      });
  };

  handleUpdateAnswer = answer => {
    if (this.cancelUpdateAnswer) {
      this.cancelUpdateAnswer.cancel();
    }
    this.cancelUpdateAnswer = axios.CancelToken.source();
    const {answers} = this.state;
    this.setState({
      answers: answers.map((item) => {
        if (item.id === answer.id) {
          return answer;
        }
        return item;
      })
    }, () => {
      this.debouncedSaveAnswer(answer);
    });
  };

  handleDeleteAnswer = answer => {
    const {data: {id}} = this.props;
    const {answers} = this.state;
    const answers_attributes = answers.map((answerItem) => {
      if (answer.id === answerItem.id) {
        return {...answer, _destroy: true};
      }
      return answerItem;
    });
    axios
      .put(`/api/v1/quizzes/${id}`, {quiz: {answers_attributes}})
      .then((response) => {
        this.saveQuizFromResponse(response).then(() => this.checkCompleteQuiz());
      })
      .catch(() => {
        alert('Не удалось удалить ответ.');
      });
  };

  // eslint-disable-next-line react/sort-comp
  debouncedSaveQuiz = debounce(() => {
    const {data: {id}} = this.props;
    axios
       .put(`/api/v1/quizzes/${id}`, this.quizPayload(), {
        cancelToken: this.cancelUpdateQuiz.token,
       }).then( resp => {
          this.saveQuizFromResponse(resp).then(() => this.checkCompleteQuiz());
       });
  }, 150);

  // eslint-disable-next-line react/sort-comp
  debouncedSaveAnswer = debounce((answer) => {
    const {quiz} = this.state;
    axios
      .put(`/api/v1/quizzes/${quiz.id}`, {quiz: {answers_attributes: [answer]}}, {
        cancelToken: this.cancelUpdateAnswer.token,
      })
      .then(() => this.checkCompleteQuiz());
  }, 150);

  loadData = () => {
    const {data: {id}} = this.props;
    return axios.get(`/api/v1/quizzes/${id}.json`).then(resp => {
      this.saveQuizFromResponse(resp);
    }).catch(() => {
      this.setState({
        isLoading: false,
        isErrorLoading: true,
      });
      return Promise.reject();
    });
  }

  saveQuizFromResponse(resp) {
    const { date_end, answers, users, ...quiz } = resp.data;
    return new Promise((resolve, reject) => {
      this.setState({
        quiz: {...quiz, date_end: date_end ? new Date(date_end) : null},
        answers,
        users,
        isLoading: false
      }, () => resolve());
    });
  }

  quizPayload() {
    const { quiz, answers } = this.state;
    return {
      quiz: {
        ...quiz,
        anonymous: quiz.format == QuizFormat.Anonymous,
        theme: quiz.style,
        multi: quiz.is_multi,
        allow_revote: !quiz.is_disallow_revote,
        answers,
      }
    };
  }

  render() {
    const {isReadOnly, blockProps, data} = this.props;
    const {
      quiz,
      answers,
      users,
      isPreview,
      isCanFocusQuestion,
      isErrorLoading,
      isLoading,
      isVoting,
      isReVote,
    } = this.state;

    if (isReadOnly && !data.isFilled) {
      return null;
    }

    if (isErrorLoading) {
      return <div style={{color: 'red'}}>Ошибка загрузка опроса</div>;
    }

    if (isLoading) {
      // TODO: добавить skeleton
      return <div>Загрузка...</div>;
    }

    return (
      <div
        onFocus={this.handlerFocus}
        onBlur={this.handlerBlur}
        onInput={e => e.stopPropagation()}
        onDrop={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        tabIndex={-1}
      >
        {isReadOnly || isPreview
          ? (
            <QuizShow
              quiz={quiz}
              answers={answers}
              users={users}
              isVoting={isVoting}
              isEditable={isPreview}
              isReVote={isReVote}
              onVote={this.handleVote}
              onEdit={this.handleTogglePreview}
              onReVote={this.handleRevote}
            />
          )
          : (
            <QuizEdit
              quiz={quiz}
              answers={answers}
              isFocusQuestionOnMount={isCanFocusQuestion}
              isShowEndDate={data.isRequiredDateEnd}
              isCanPreview={data.isFilled}
              blockProps={blockProps}
              onRemove={this.handleRemove}
              onPreview={this.handleTogglePreview}
              onUpdateQuiz={this.handleUpdateQuiz}
              onUpdateAnswer={this.handleUpdateAnswer}
              onDeleteAnswer={this.handleDeleteAnswer}
              onAddAnswer={this.handleAddAnswer}
              onDateEndShow={this.handleDateEndShow}
            />
          )
        }
      </div>
    );
  }
}

Block.propTypes = {
  container: PropTypes.any,
  blockProps: PropTypes.any,
  data: PropTypes.any,
  article: PropTypes.any,
  isReadOnly: PropTypes.bool,
};

export default withEditorContentContext(Block);
