import "core-js";
import "regenerator-runtime/runtime";

import Rails from "rails-ujs";
import Turbolinks from "turbolinks";
import Dropzone from 'dropzone';
import Chart from 'chart.js';
import moment from 'moment';

import 'jquery-form';
import 'jquery.scrollto';
import 'jquery-match-height';
import 'jquery-bar-rating';
import "magnific-popup";
import "magnific-popup/dist/magnific-popup.css";
import 'dropzone/dist/dropzone.css';
import 'font-awesome/scss/font-awesome.scss';

import './vendor/autoellipsis.jquery';
import './vendor/bootstrap-table';
import './vendor/jquery-ui.min';
import './vendor/jquery.datepicker';
import "./vendor/jquery-ui.min.css";
import "./vendor/jquery-ui.structure.min.css";
import "./vendor/jquery-ui.theme.min.css";


import "./fonts/index.scss";
import "./layout";
import "./application.sass";

import initPages from "./pages";
import initBlocks from "./blocks";
import { setHeaders } from "./axios-headers";
import initEditor2 from './editor2';

import "./old-init";
import "./old";

// import "./editor/loader"
import "./presentation/loader";

window.$ = $;
window.jQuery = $;

window.Dropzone = Dropzone;
Rails.start();
Turbolinks.start();

moment.locale('ru');
window.moment = moment;
window.Chart = Chart;

$.magnificPopup.instance._getScrollbarSize = function(e) {
  if (/android|iphone|ipad/i.test(window.navigator.appVersion)) {
    return 0;
  }
  return 10;
};

document.addEventListener("turbolinks:before-cache", function() {
  delete $('body')[0].dataset.done;
  $('.js-turbolinks-destroy-bootstrap-table').bootstrapTable('destroy');
  $('.js-turbolinks-destroy-selectmenu').selectmenu('destroy');
  $('.js-turbolinks-destroy-datepicker').removeDatepicker();
  if (window.intervalId) {
    clearInterval(window.intervalId);
    window.intervalId = null;
  }
  $.magnificPopup.close();
});

function removeNoScroll() {
  $('html')
    .removeClass('no_scroll')
    .css({
      marginRight: '',
      overflow: '',
    });
  $.magnificPopup.close();
}

// $(document).on('click.delete', '.js-delete-post', function (e) {
//   if (confirm('Вы дейсвительно хотите удалить?')) {
//     e.preventDefault();
//   }
// });

document.addEventListener("turbolinks:load", function() {
  setHeaders();
  removeNoScroll();
  initPages();
  initBlocks();
  initEditor2();
  $('body')[0].dataset.done=true;
});
$(setHeaders); // Иногда оно не успевает отработать в turbolinks:load @ Карим
