import Selectize from 'selectize';
import 'selectize/dist/css/selectize.css';

import './select.scss';

Selectize.define("clear_button", function(options) {
  var self = this;
  if (self.settings.mode === "multiple") {
    return;
  }

  options = $.extend({
    label     : "<i class=\"fa fa-times\"></i>",
    className : "btn_clear",
    append    : true
  }, options);

  self.on("initialize", function() {
    const checkVisible = () => {
      if (self.items.length) {
        self.$clear_button.show();
      } else {
        self.$clear_button.hide();
      }
    };
    self.$clear_button = $("<button type=\"button\" tabindex=\"-1\"></button>");
    self.$clear_button.addClass(options.className).html(options.label);
    checkVisible();
    self.$wrapper.prepend(self.$clear_button);
    self.$clear_button.click(function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (self.items.length) {
        self.setValue("");
        self.onChange();
        self.close();
      }
    });
    self.on("change", function() {
      checkVisible();
    });
  });
});

export default () => {
  const $selects = $('.js-select');
  $selects.each(function () {
    const $this = $(this);
    $this.selectize($this.data());
  });

  $(document)
    .off('turbolinks:before-cache.js-select')
    .one('turbolinks:before-cache.js-select', function () {
      $selects.each(function (ind, el) {
        const $el = $(el);
        $el[0].selectize.destroy();
        $el.css('display', '');
      });
    });
};
