import axios from 'axios';
import 'selectize';
import 'selectize/dist/css/selectize.css';
import debounce from "lodash-es/debounce";

import fileTpl from './file.pug';
import './index.sass';
import './repply-form.sass';
import './contest-popup.sass';
import './comment-editor.sass';
import flash from '~/blocks/flash/index';

let idReqAnim = null;

function is_touch_device() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

let editors = [];

function destroyEditors() {
  editors.forEach(function(el) { tinymce.remove(el); });
  editors = [];
}

function isSafariIOS() {
  var ua = window.navigator.userAgent;
  var iPhone = !!ua.match(/iPhone/i);
  var webkit = !!ua.match(/WebKit/i);
  var iOSSafari = iPhone && webkit && !ua.match(/CriOS/i);
  return iOSSafari;
}

const init = () => {
  const $articleSideBar = $('.js-article-sidebar');
  const $articleContent = $('.article__content, .article__content-old');
  const $hashtag = $('.article__hashtag');
  const $nameCompetition = $('.article__proviso');
  const $window = $(window);

  const initRichTextComment = () => {
    Promise
      .resolve()
      .then(() => import(/* webpackChunkName: "tinymce" */ '~/tinymce'))
      .then(({tinymce, contentStyle, loadInliteTheme, loadMobileTheme}) => {
        return new Promise(resolve => {
          let loadThem = null;
          if (is_touch_device()) {
            loadThem = loadMobileTheme();
          } else {
            loadThem = loadInliteTheme();
          }
          loadThem.then(() => { resolve({ tinymce, contentStyle }); });
        });
      })
      .then(({ tinymce, contentStyle }) => {
        const isTouchDevice = is_touch_device();
        if (isTouchDevice) $('html').addClass('is-mobile');
        $('.js-comment-textarea').each(function () {
          const $this = $(this);
          const toolbar = 'bold italic underline strikethrough | bullist numlist | link autolink';
          const selection_toolbar = 'bold italic underline strikethrough | bullist numlist | quicklink';
          tinymce.init({
            // placeholder: "Placeholder from settings",
            convert_urls: false,
            document_base_url: '/',
            relative_urls: false,
            theme: isTouchDevice ? 'modern' : 'inlite',
            skin: false,
            target: this,
            content_style: contentStyle.toString(),
            language: 'ru',
            toolbar: isTouchDevice ? toolbar : false,
            selection_toolbar: isTouchDevice ? false : selection_toolbar,
            insert_toolbar: false,
            statusbar: false,
            menubar: false,
            inline: true,
            paste_as_text: true,
            plugins: `link lists placeholder placeholder2 mention autolink paste`,
            default_link_target: '_blank',
            mentions: {
              insert(item) {
                return `<a href="${item.url}">${item.name}</a> `;
              },
              source(query, process, delimiter) {
                // Do your ajax call
                // When using multiple delimiters you can alter the query depending on the delimiter used
                if (delimiter === '@') {
                  axios.get('/api/v1/search/users', {params: {q: query}}).then(resp => {
                    process(resp.data.list);
                  });
                }
              }
            },
            setup: ed => {
              editors.push(ed);
              ed.on('change', () => {
                if (ed.getContent()) { // try fix ie11
                  $(ed.bodyElement).removeClass('empty');
                }
                $input.attr('name', ed.id);
                ed.save();
                $input.attr('name', 'comment[body]');
              });
            },
          });
          var $input = $this.siblings('input[name^="mce"]');
        });
      });
  };

  $('.js-comment-textarea').length && initRichTextComment();
  $(document).on('comment-editor.init', function () {
    $('.js-comment-textarea').length && initRichTextComment();
  });
  $(document).on('comment-editor.destroy', destroyEditors);
  document.addEventListener('turbolinks:before-render', destroyEditors);

  // Ellipsis

  function ellipsisText(e, etc) {
    const initialTxt = `${$(e).data('title')}`;
    const $txt = initialTxt.split(' ');

    $(e).text(initialTxt);

    while (e.scrollHeight > e.offsetHeight) {
      $txt.pop();
      $(e).text($txt.join(' ') + (etc || '...'));
    }
  }

  function ellipse() {
    $('.article__elem-title').each((k, v) => ellipsisText(v));
  }

  function calcScrollTop(elem) {
    if ($window.scrollTop() + $window.height() > elem.offset().top) {
      $articleSideBar.addClass('article-display');
    } else {
      $articleSideBar.removeClass('article-display');
    }
  }

  ellipse();

  $(window)
    .off('resize.article-title')
    .on('resize.article-title', ellipse);

  const articleSidebarPos = () => {
    if (!$articleSideBar.length) return;
    if ($window.width() > 991) {
      $articleSideBar.removeClass('article-display');
      if ($articleContent.offset().top < $window.scrollTop() + 30) {
        if (
          $articleContent.offset().top + $articleContent.outerHeight()
          < $window.scrollTop() + 30 + $articleSideBar.outerHeight()
        ) {
          $articleSideBar.css({
            position: 'absolute',
            top: `${$articleContent.offset().top + $articleContent.outerHeight() - $articleSideBar.outerHeight()}px`
          });
        } else {
          $articleSideBar.css({
            position: 'fixed',
            top: '30px'
          });
        }
      } else {
        $articleSideBar.css({
          position: 'absolute',
          top: `${$articleContent.offset().top + 10}px`
        });
      }
    } else {
      $articleSideBar.css({
        position: '',
        top: '',
      });
      if ($hashtag.length) {
        calcScrollTop($hashtag);
      } else {
        if($nameCompetition.length) {
          calcScrollTop($nameCompetition);
        }
      }
    }

  };

  $(window)
    .off('scroll.article-sidebar resize.article-sidebar load.article-sidebar rendered:editor-content')
    .on('scroll.article-sidebar resize.article-sidebar load.article-sidebar rendered:editor-content', articleSidebarPos)
    .trigger('resize.article-sidebar');

  $(document)
    .off('change.file-comment-attachment')
    .on('change.file-comment-attachment', '.js-file-comment-attachment', function handlerChangeInputAttach(e) {
      const $thisInput = $(this);
      const containerId = $thisInput.data('container');
      const $listContainer = $($thisInput.data('container'));
      if (!e.target.files.length) return;
      const f = e.target.files[0];
      if (!f.type.match('image.*')) return;
      // files.push(f);

      const renderPreview = (result, isResource = false) => {
        const $filePreview = $(fileTpl({result}));
        $listContainer.append($filePreview);
        $filePreview.on('click', '.article__delete', () => {
          if (isResource) {
            URL.revokeObjectURL(result);
          }
          $filePreview.remove();
        });

        const id = $thisInput.attr('id');
        const name = $thisInput.attr('name');
        $thisInput.removeAttr('id');
        $thisInput.removeAttr('class');

        const $newInput = $(
          `<input type="file" class="js-file-comment-attachment" id="${id}" name="${name}" data-container="${containerId}" />`,
        );
        $newInput.insertAfter($thisInput);
        $filePreview.append($thisInput);
      };

      if (URL && URL.createObjectURL) {
        const result = URL.createObjectURL(f);
        renderPreview(result, true);
      } else {
        const fr = new FileReader();
        fr.onload = function handlerLoadReadFile(eventFile) {
          renderPreview(eventFile.target.result);
        };
        fr.readAsDataURL(f);
      }
    });

  $(document)
    .off('submit.comment_form')
    .on('submit.comment_form', '.js-new-comment_form, .js-new-reply-form, .js-new-edit-form', function (event) {
      event.preventDefault();
      const $thisForm = $(this);
      const $sbmtBtn = $thisForm.find(':submit');
      const url = $thisForm.attr('action');
      $sbmtBtn.attr('disabled', true);
      const formData = new FormData(this);

      // // Костыль, нужен, пока в комменте не textarea, а div
      // const mceKey = [...formData.keys()].find(key => key.slice(0, 3) == 'mce');
      // if (mceKey) {
      //   formData.set('comment[body]', formData.get(mceKey))
      // }
      // // \Конец костыля

      const parentId = $(this).find('[name="comment[parent_id]"]').val();
      const isEdit = $thisForm.hasClass('js-new-edit-form');
      let request = null;
      if (isEdit) {
        request = axios.put(url, formData);
      } else {
        request = axios.post(url, formData);
      }
      request
        .then(resp => {
          if (resp.data.error) {
            $sbmtBtn.attr('disabled', false);
            flash({
              error: `Ошибка! ${resp.data.error}.`,
            });
          } else if (isEdit) {
            $thisForm.closest('[data-comment-id]').replaceWith(resp.data);
            initRichTextComment();
          } else if (!parentId) {
            $('.article__answers').prepend(resp.data);
            $sbmtBtn.attr('disabled', false);
            $('.js-comment-textarea-top').html('').addClass('empty');
            $('.js-comment-textarea-top').siblings('.article__list').empty();
            initRichTextComment();
            $('html, body').animate({
              scrollTop: $('.article__answers').offset().top - window.innerHeight / 2
            }, 500);
          } else {
            const $parent = $(`.article__answer[data-comment-id="${parentId}"]`);
            $parent.replaceWith(resp.data);
            $sbmtBtn.attr('disabled', false);
            initRichTextComment();
            const $lastAnswer = $(`.article__answer[data-comment-id="${parentId}"]`)
              .find('.article__answer-list .article__answer').last();
            $('html, body').animate({
              scrollTop: $lastAnswer.offset().top - window.innerHeight / 2
            }, 500);
          }
        })
        .catch(err => {
          console.error(err);
        });
    });

  $(document)
    .on('click.answer-show-more')
    .on('click.answer-show-more', '.article__answer-show-more', function (event) {
      event.preventDefault();
      $('.article__answers--has-more').removeClass('article__answers--has-more');
    });

  $(document)
    .off('click.list-file')
    .on('click.list-file', '.article__magnific', function (e) {
      e.preventDefault();
      e.stopPropagation();
      $.magnificPopup.open({
        mainClass: 'article__answer-magnific',
        type: 'image',
        items: {
          src: $(this).attr('href'),
        },
      });
    });

  const $tooltipBtn = $('#show-detail');
  const $tooltipBody = $('#article-tooltip');
  const $commentTolBtn = $('.article__answer-more ');
  const $tooltipCommentBody = $('.tooltip-open');

  $tooltipBtn
    .off('click.show-detail')
    .on('click.show-detail', function (event) {
      console.log('click.show-detail');
      $tooltipBody.fadeToggle('show');
    });

  $commentTolBtn
    .off('click.article__answer-more')
    .on('click.article__answer-more', function (e) {
      e.preventDefault();
      const $commentTolBody = $(this)
        .closest('.article__answer-head')
        .find('.tooltip-open');
      $commentTolBody.fadeToggle('show');
    });

  $(document)
    .off('click.article__tooltip')
    .on('click.article__tooltip', function (e) {
      const $target = $(e.target);
      if ($target.closest('.article__tooltip').length) return;
      if (!$target.closest('.article__answer-more').length && !$target.closest('#show-detail').length) {
        $tooltipCommentBody.fadeOut();
        $tooltipBody.fadeOut();
      }
    });

  $(document)
    .off('click.toggle-comment-form')
    .on('click.toggle-comment-form', '.js-toggle-reply, .js-toggle-edit', function (e) {
      e.preventDefault();
      const $toggledWrap = $($(this).attr('href'));
      const $originalAttach = $toggledWrap
        .find('.article__list .js-delete-edit-comment-attach')
        .parents('li')
        .show();
      $toggledWrap.toggle();
      $toggledWrap.find('.js-comment-textarea').focus();
      $toggledWrap
        .find('.article__list > li')
        .not($originalAttach)
        .remove();
      $originalAttach.find('input[type="checkbox"]').removeAttr('checked');
      tinymce.activeEditor.selection.select(tinymce.activeEditor.getBody(), true);
      tinymce.activeEditor.selection.collapse(false);
    });

  $('.js-delete-edit-comment-attach')
    .off('click.edit-comment-attach')
    .on('click.edit-comment-attach', function () {
      const $parent = $(this).parent();
      $parent.hide();
      $parent.find('input[type="checkbox"]').attr('checked', 'checked');
    });

  $('.js-contest-popup').on('click', (e) => {
    e.stopPropagation();
    e.preventDefault();
    const $target = $(e.target);
    $.magnificPopup.open({
      mainClass: 'article__contest-magnific',
      type: 'inline',
      items: {
        src: $target.attr('href'),
      },
      fixedContentPos: true,
    });
  })

  window.cancelAnimationFrame(idReqAnim);
  if (isSafariIOS() && window.visualViewport) {
    console.log('detect safari ios iphone');
    let isFocusedComment = false

    $(document.documentElement).addClass('is-safari')
    $(document)
      .on('focus', '.js-comment-textarea', () => { isFocusedComment = true; })
      .on('blur', '.js-comment-textarea', () => { isFocusedComment = false; })

    var viewport = window.visualViewport;

    const debouncedUpdateBar = debounce(() => {
      if (isFocusedComment) {
        var offsetY = window.innerHeight
          - viewport.height
          - viewport.offsetTop;
        Array
          .from(document.getElementsByClassName('mce-floatpanel'))
          .forEach(elem => {
            elem.style.setProperty('left', viewport.offsetLeft + 'px', 'important');
            elem.style.setProperty('bottom', offsetY + 'px', 'important');
            elem.style.setProperty('transform', "scale(" + 1 / viewport.scale + ")", 'important');
            elem.style.display = 'block';
          });
      }
    }, 300)

    function updateBar() {
      Array
        .from(document.getElementsByClassName('mce-floatpanel'))
        .forEach(elem => {
          elem.style.display = 'none';
        });
      debouncedUpdateBar()
    }

    window.visualViewport.addEventListener('resize', updateBar);
    window.visualViewport.addEventListener('scroll', updateBar);
    document.body.addEventListener('touchmove', updateBar);
  }
};

$(document).on('turbolinks:load', init);
