import './youtube'
import onVimeoIframeAPIReady from './vimeo'
import onInternalVideoReady from './internal'


function waitForVimeo() {
  var interval = null
  function it() {
    if (typeof(Vimeo) === 'undefined' || typeof(Vimeo.Player) === 'undefined' ) {
      if (!interval) {
        var tag = document.createElement('script');
        tag.src = "https://player.vimeo.com/api/player.js";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        interval = setInterval(it, 100)
      }
    } else {
      clearInterval(interval)
      interval = null
      onVimeoIframeAPIReady()
    }
  }
  it()
}

var initVideoPlayers = function() {

  var hasYoutube = $(".youtube-video").length > 0
  var hasVimeo = $(".vimeo-video").length > 0
  var hasInternal = $('video').length > 0

  if (hasYoutube) {
    $(".youtube-video").each(function() {
      var $t = $(this)
    })

    if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {//onYouTubeIframeAPIReady() will be called automatically when new tab is opening
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      onYouTubeIframeAPIReady()
    }
  }

  if (hasVimeo) {
    waitForVimeo()
  }
  if (hasInternal) {
    onInternalVideoReady()
  }
}

//$(initVideoPlayers)
$(document).on('turbolinks:load', initVideoPlayers);
