import axios from 'axios'
import flash from '~/blocks/flash'
function initChangeRole() {
  $('.js-admin-change-category-role').off('click').on('click', function (event) {
    event.preventDefault()
    const data = {
      category_id: $(this).data('category-id'),
      role_id: $(this).data('role-id'),
    }
    axios.post($(this).attr('href'), data).then(resp => {
      //$(this).closest('.js-admin-user-add-cat').before(resp.data)
      $(this).closest('.js-roles-container').html(resp.data)
      initChangeRole()
      flash({success: 'Роль успешно изменена'})
      //$(this).hide()
    }).catch(err => {
      flash({error: 'Ошибка изменения роли'})
    })
  })
}

function initToggleRoles() {
  $('.js-admin-user-toggle-roles').on('click', function(event) {
    event.preventDefault()
    const selector = $(this).data('selector')
    const replace = $(this).data('replace')
    const value = $(this).html()
    const $target = $(selector)
    if ($(this).data('done')) {
      $target.toggle()
      $(this).html(replace)
      $(this).data('replace', value)
    }
    axios.get($(this).attr('href')).then(resp => {
      $target.html(resp.data)
      $(this).data("done", 'true')
      initChangeRole()
      $(this).html(replace)
      $(this).data('replace', value)
    }).catch(err => {
      console.error(err)
      flash({error: 'Ошибка загрузки ролей'})
    })
  })
}

function init() {
  initToggleRoles()
}
$(document).on("turbolinks:load", init);
