export function loader(selector, onDone, onToggle) {
  const r = () =>{
    import(/* webpackChunkName: "greet" */ 'react').then(RImport => {
      import(/* webpackChunkName: "greet" */ 'react-dom').then(RDImport => {
        import(/* webpackChunkName: "greet" */ './Greet').then(GImport => {
          const React = RImport.default
          const ReactDOM = RDImport.default
          const Greet = GImport.default
          const greet = React.createElement(Greet, {onDone, onToggle}, null)
          ReactDOM.render(greet, document.querySelector(selector))
        })
      })
    })
  }
  r()
  if (module.hot) module.hot.accept('./Greet', r)
}
