import React from 'react';
import { components } from 'react-select';

import IconSearch from '~/editor2/components/Select/icon-search.r.svg';

/**
 * @type { React.ComponentProps<typeof ReactSelect>['styles'] }
 */
export const customSelectStyles = {
  control: (base, state) => ({
    ...base,
    border: state.menuIsOpen ? '1px solid #fff' : '1px solid #E5E5E5',
    borderBottom: state.menuIsOpen ? '1px solid #EBEBEB' : '1px solid #E5E5E5',
    borderRadius: 4,
    borderBottomLeftRadius: state.menuIsOpen ? 0 : 4,
    borderBottomRightRadius: state.menuIsOpen ? 0 : 4,
    minHeight: 50,
    boxShadow: state.menuIsOpen ? '0px 4px 25px rgba(0, 0, 0, 0.15)' : 'none',
    transition: 'none',
    ':hover': {
      border: state.menuIsOpen ? '1px solid #fff' : '1px solid #E5E5E5',
      borderBottom: state.menuIsOpen ? '1px solid #EBEBEB' : '1px solid #E5E5E5',
    },

  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 15,
    color: '#A0A0A0',
  }),
  valueContainer: (base, state) => ({
    ...base,
    paddingLeft: state.selectProps.menuIsOpen ? 50 : 25,
    paddingRight: 25,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (base) =>({
    ...base,
    marginTop: 0,
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    border: 'none',
    boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.15)',
  }),
  option: (base, { isFocused }) => ({
    ...base,
    padding: '6px 25px',
    fontSize: 15,
    color: isFocused ? '#389A52' : '#A0A0A0',
    background: 'transparent',
    cursor: 'pointer'
  }),
  indicatorsContainer: (base) => ({
    ...base,
    marginRight: 10,
    cursor: 'pointer',
  })
};

/**
 * @type { React.ComponentProps<typeof ReactSelect>['components'] }
 */
export const customSelectComponents = {
  ValueContainer: ({ children, ...props}) => {
    return (
      <components.ValueContainer {...props}>
        {props.selectProps.menuIsOpen && (
          <IconSearch
            style={{
              position: 'absolute',
              left: 25,
            }}
          />
        )}
        {children}
      </components.ValueContainer>
    );
  }
};
