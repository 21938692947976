import React from 'react';
import PropTypes from 'prop-types';
import RCTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import './RCTooltipCustom.sass';

/**
 * @extends {RCTooltip}
 */
class RCTooltipCustom extends React.PureComponent {
  render() {
    const { isHide, children, ...props } = this.props;
    return (
      <RCTooltip  {...props} {...(isHide ? { visible: false } : undefined)} overlayClassName="RCTooltipCustom">
        {children}
      </RCTooltip>
    );
  }
}

RCTooltipCustom.propTypes = {
  children: PropTypes.node,
  overlay: PropTypes.node,
  placement: PropTypes.string,
  isHide: PropTypes.bool,
};

export {
  RCTooltip,
  RCTooltipCustom,
};

export default RCTooltip;
