import throttle from 'lodash-es/throttle';


$(document).on('turbolinks:load', function () {
  let $scrollableContainers = $('.js-shadowable');

  let checkShadow = ($scrollableContainer) => {
    let $shadowableContainer = $scrollableContainer.parent()
    if($scrollableContainer[0].scrollWidth > $scrollableContainer.outerWidth()) {
      if( $scrollableContainer.scrollLeft() < Math.floor($scrollableContainer[0].scrollWidth - $scrollableContainer.outerWidth()) ) {
        $shadowableContainer.addClass('has-sh-right')
      } else {
        $shadowableContainer.removeClass('has-sh-right')
      }
      if($scrollableContainer.scrollLeft() > 12){
        $shadowableContainer.addClass('has-sh-left')
      } else {
        $shadowableContainer.removeClass('has-sh-left')
      }
    } else {
      $shadowableContainer.removeClass('has-sh-right')
      $shadowableContainer.removeClass('has-sh-left')
    }
  }

  let checkShadows = () => {
    $scrollableContainers.each(function () {
      checkShadow($(this))
    })
  }

  $(window).on('resize.shadowable', throttle(function () {
    checkShadows()
  }, 50))

  $scrollableContainers.on('scroll',function () {
    checkShadow($(this))
  })

  checkShadows()
})
