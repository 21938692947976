import './index.scss';
import axios from 'axios';
import flash from '~/blocks/flash';

function init () {
  $(document).on('click', '.article__rival-more', function (e) {
    const $showMoreBtn = $('.article__rival-more');
    const $urlMoreArticle = $showMoreBtn.attr('href');
    const $articleElems = $('.article__rival-elements');
    e.preventDefault();
    axios.get($urlMoreArticle).then(resp => {
      $articleElems.append($(resp.data.articles));
      $showMoreBtn.replaceWith(resp.data.button);
    }).catch(err => {
      console.error(err);
      flash({error: 'Произошла ошибка'});
    });
  });
}


$(init);
