import axios from 'axios';
import flash from '~/blocks/flash';
import Turbolinks from 'turbolinks';
function init() {
  $('.js-admin-edit-tag-form').on('submit', function(event) {
    event.preventDefault();
    axios.post($(this).attr('action'), $(this).serialize()).then(resp => {
      flash({success: 'Название тэга успешно обновлено'})
      if (resp.data.reload) {
        setTimeout(function() {
          window.location.reload()
        }, 1000)
      }
    }).catch((err) => {
      console.error(err)
      flash({error: 'Произошла ошибка'})
    })
  });
  $('.js-admin-destroy-tag-link').on('click', function(event) {
    event.preventDefault();
    if ($(this).data('process')) {
      return;
    }
    $(this).data('process', 'true');
    axios.delete($(this).attr('href')).then(resp => {
      flash({success: 'Тэг успешно удалён'})
      $(this).closest('.js-admin-tag-item').remove()
    }).catch((err) => {
      console.error(err)
      flash({error: 'Произошла ошибка'})
      $(this).data('process', '')
    })
  })
}

$(document).on('turbolinks:load', init)
