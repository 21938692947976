import './index.sass';
import axios from 'axios';
import flash from '~/blocks/flash';

function init() {
  $(document).on('click', '.js-article-bookmark', function (event) {
    event.preventDefault();
    axios.post($(this).attr('href'), {}).then(resp => {
      $(this).closest('.js-change-bookmark').html(resp.data);
    }).catch(err => {
      console.error(err);
      flash({error: 'Произошла ошибка'});
    });
  });
}

$(init);
