import urlParser from "js-video-url-parser/dist/jsVideoUrlParser";

const urlVideoParser = urlParser;

/**
 * @param {string} url
 * @return {{preview: string, embeded: string}|boolean}
 */
export const getEmbededDataVideo = (url) => {
  const urlInfo = urlParser.parse(url);
  if (urlInfo) {
    switch (urlInfo.provider) {
      case 'youtube':
        return {
          preview: `https://img.youtube.com/vi/${urlInfo.id}/hqdefault.jpg`,
          embeded: `https://www.youtube.com/embed/${urlInfo.id}`
        };
      case 'vimeo':
        return {
          preview: `https://i.vimeocdn.com/video/${urlInfo.id}.jpeg`,
          embeded: `https://player.vimeo.com/video/${urlInfo.id}`
        };
      default:
        return false;
    }
  }
  return false;
};

export { urlVideoParser };
