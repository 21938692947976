import template from './template.pug';
import './index.sass';

/**
 * @param {Object} object
 * @param {string|undefined} object.success
 * @param {string|undefined} object.danger
 * @param {string|undefined} object.error
 */
export default function flash (object) {
  $('body').append(Object.keys(object).map(kind => {
    return template({kind, content: object[kind]});
  }).join(''));
}

$(document).on('click', '.alert', function handlerClickAlert() {
  $(this).remove();
});
