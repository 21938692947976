import Immutable from 'immutable';

import {
  genKey,
  EditorState,
  ContentBlock,
  CharacterMetadata,
  AtomicBlockUtils,
  SelectionState,
} from 'draft-js';

const {List, Map, Repeat} = Immutable;

/**
 * @param {EditorState} editorState
 * @param data
 * @param selection
 * @param type
 * @param entityKey
 */
function insertDataBlock(editorState, data = {}, selection, type = 'atomic', entityKey) {
  const newBlockKey = genKey();

  let editorStateWithNewBlock = AtomicBlockUtils.moveAtomicBlock(
    editorState,
    new ContentBlock({
      key: newBlockKey,
      type,
      text: '',
      data: Map(data),
      characterList: List()
    }),
    selection || editorState.getSelection(),
  );

  let contentStateWithNewBlock = editorStateWithNewBlock.getCurrentContent();

  const firstBlock = contentStateWithNewBlock.getFirstBlock();

  if (data.type === 'quiz' && firstBlock.getKey() === newBlockKey) {
    editorStateWithNewBlock = AtomicBlockUtils.moveAtomicBlock(
      editorStateWithNewBlock,
      new ContentBlock({
        key: genKey(),
        type: 'unstyled',
        text: '',
        characterList: List(Repeat(CharacterMetadata.EMPTY, ''.length))
      }),
      editorStateWithNewBlock.getSelection(),
      'before'
    );
    contentStateWithNewBlock = editorStateWithNewBlock.getCurrentContent();
  }

  const lastBlock = contentStateWithNewBlock.getLastBlock();

  if (lastBlock.getKey() === newBlockKey) {
    const keyEmptyBlock =  genKey();
    const editorStateAfterAddEmptyBlock = AtomicBlockUtils.moveAtomicBlock(
      editorStateWithNewBlock,
      new ContentBlock({
        key: keyEmptyBlock,
        type: 'unstyled',
        text: '',
        characterList: List(Repeat(CharacterMetadata.EMPTY, ''.length))
      }),
      editorStateWithNewBlock.getSelection(),
      'after'
    );
    return EditorState.forceSelection(
      editorStateAfterAddEmptyBlock,
      new SelectionState({
        anchorKey: keyEmptyBlock, // key of block
        anchorOffset: 0,
        focusKey: keyEmptyBlock,
        focusOffset: 0,
        hasFocus: true,
      })
    );
  }

  const keyBlockAfter = contentStateWithNewBlock.getBlockAfter(newBlockKey).getKey();

  return EditorState.forceSelection(
    editorStateWithNewBlock,
    new SelectionState({
      anchorKey: keyBlockAfter,
      anchorOffset: 0,
      focusKey: keyBlockAfter,
      focusOffset: 0,
      hasFocus: true,
    })
  );
}

export default insertDataBlock;
