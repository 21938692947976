import './switch-scope-notify.sass';

export default function () {
  const $notySwitcher = $('.switch-scope-notify');
  if ($notySwitcher.length) {
    $(window).off('load.swithcer-noty').on('load.swithcer-noty', function () {
      const $tabs = $('.js-tab-notify').filter(':not(.second-menu__tab--active)');
      const $tab = $tabs.eq(0);
      $tab.tooltipster({
        theme: 'tooltipster-default swithcer-noty',
        position: 'bottom',
        interactive: true,
        contentAsHTML: true,
        trigger: 'custom',
        maxWidth: 430,
        functionInit() {
          return $notySwitcher.html();
        },
      });
      $tab.tooltipster('show');
    });
  }
  $(document).off('click.swithcer-noty').on('click.swithcer-noty','.switch-scope-notify__close', function () {
    $('.js-tab-notify').filter(':not(.second-menu__tab--active)').tooltipster('hide');
  });
};
