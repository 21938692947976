export default function onInternalVideoReady() {
  $('video').each(function() {
    var $t = $(this)
    var sent = false;
    var sending = false;

    function sendViewedTillEnd(articleId) {
      console.log("local video is sending message: article " + articleId + " is viewed till end");
      sending = true;
      $.ajax({
        type: "post",
        url: "/article/viewed-till-end/update/" + articleId,
        data: {},
        success: function() {
          sent = true;
        },
        error: function(error) {
          console.error(error)
          sending = false;
        }
      });
    }

    $t[0].ontimeupdate = function(e) {
      var percent = $t[0].currentTime === 0 ? 0 : $t[0].currentTime / $t[0].duration;
      if ( !sent && !sending && (percent > 0.95) ) {
        var articleId = $t.data('article-id')
        sendViewedTillEnd(articleId)
      }
    }
  })
}
