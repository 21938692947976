import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './Info.sass';

class Info extends PureComponent {
  render() {
    const { text } = this.props;
    return (
      <div className="Info">
        <div className="Info__IconWrap">
          <div className="Info__Icon" />
        </div>
        <div className="Info__Content">
          <b>Внимание!</b>
          &ensp;
          {text}
        </div>
      </div>
    );
  }
}

Info.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Info;
