import React, { PureComponent, Fragment } from 'react';
import classNames from 'classnames';
import ReactDropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import axios from 'axios';

import './Dropzone.sass';

import ProgressCircle from '~/editor2/components/ProgressCircle';
import ButtonIcon from '~/editor2/components/ButtonIcon';

import IconImages from './icon-image.r.svg';
import IconVideo from './icon-video.r.svg';
import IconDrop from './icon-drop.svg';
import IconDropRej from './icon-drop-rej.svg';
import IconBtnVideoSrc from './icon-btn-change-video.svg';
import IconBtnImageSrc from './icon-btn-change-img.svg';


export const TypeDropzone = {
  IMAGE: 'image',
  IMAGE_CATEGORY: 'image-category',
  VIDEO: 'video',
  COVER: 'cover',
};

class Dropzone extends PureComponent {
  constructor(props) {
    super(props);
    this.handlerDropFile = ::this.handlerDropFile;

    this.state = {
      file: undefined,
      isLoading: false,
      progress: 0,
      fileUrl: undefined,
    };
  }

  getIcon() {
    const { type } = this.props;
    switch (type) {
      case TypeDropzone.IMAGE:
      case TypeDropzone.IMAGE_CATEGORY:
      case TypeDropzone.COVER:
        return IconImages;
      case TypeDropzone.VIDEO:
        return IconVideo;
      default:
        return IconImages;
    }
  }

  getTitle() {
    const { type } = this.props;
    switch (type) {
      case TypeDropzone.IMAGE:
      case TypeDropzone.IMAGE_CATEGORY:
        return 'Загрузить изображение';
      case TypeDropzone.VIDEO:
        return 'Загрузить видео';
      case TypeDropzone.COVER:
        return 'Загрузить обложку';
      default:
        return 'Тип не определен';
    }
  }

  getMessage() {
    const { type } = this.props;
    switch (type) {
      case TypeDropzone.IMAGE:
      case TypeDropzone.IMAGE_CATEGORY:
        return (
          <>
            Для загрузки нажмите на эту область
            <br />
            или просто перетащите сюда изображение
            <br />
            (доступно для загрузки: JPG, PNG, SVG)
            <br />
          </>
        );
      case TypeDropzone.VIDEO:
        return (
          <>
            Для загрузки нажмите на эту область
            <br />
            или просто перетащите сюда видео
            <br />
            (доступно для загрузки: MPEG, WMV, AVI, MP4)
            <br />
          </>
        );
      case TypeDropzone.COVER:
        return (
          <>
            Сделайте свою статью интереснее
            <br />
            с помощью красивой обложки
          </>
        );
      default:
        return '';
    }
  }

  getTypeFile() {
    const { type } = this.props;
    switch (type) {
      case TypeDropzone.IMAGE:
      case TypeDropzone.IMAGE_CATEGORY:
      case TypeDropzone.COVER:
        return 'image/*';
      case TypeDropzone.VIDEO:
        // https://stackoverflow.com/questions/19107685/safari-input-type-file-accept-video-ignores-mp4-files
        return 'video/mp4,video/*';
      default:
        return '*/*';
    }
  }

  getEditBtnTitle() {
    const { type } = this.props;
    switch (type) {
      case TypeDropzone.IMAGE:
      case TypeDropzone.IMAGE_CATEGORY:
        return 'Заменить изображение';
      case TypeDropzone.COVER:
        return 'Заменить обложку';
      case TypeDropzone.VIDEO:
        return 'Заменить видео';
      default:
        return 'Заменить';
    }
  }

  getEditBtnIcon() {
    const { type } = this.props;
    switch (type) {
      case TypeDropzone.IMAGE:
      case TypeDropzone.IMAGE_CATEGORY:
      case TypeDropzone.COVER:
        return IconBtnImageSrc;
      case TypeDropzone.VIDEO:
        return IconBtnVideoSrc;
      default:
        return IconBtnImageSrc;
    }
  }

  getIdVideo = () => axios.post(`/api/v1/articles/${this.props.articleId}/videos`).then(respone => respone.data.id)

  handlerDropFile(acceptedFiles) {
    if (acceptedFiles && acceptedFiles.length) {
      this.startUploadFile(acceptedFiles[0]);
    }
  }

  startUploadFile(file) {
    this.setState(
      {
        file,
        isLoading: true,
        progress: 0,
      },
      this.startUpload,
    );
  }

  startUpload() {
    const { file } = this.state;
    if (!file) return;
    const { onUploadStart, articleId, categoryId, type, fieldName } = this.props;
    const formData = new FormData();
    formData.append(fieldName, file, file.name);
    onUploadStart && onUploadStart(file);
    let uploadPromise;
    if (type === TypeDropzone.VIDEO) {
      uploadPromise = this.getIdVideo()
        .then(id => this.uploadFile(`/api/v1/articles/${articleId}/videos/${id}`, formData, 'put'))
        .then(this.uploadCompleteMegadraft);
    } else if (type === TypeDropzone.COVER) {
      uploadPromise = this.uploadFile(`/articles/${articleId}.json`, formData, 'put')
        .then(this.uploadCompleteCover);
    } else if (type === TypeDropzone.IMAGE_CATEGORY) {
      uploadPromise = this.uploadFile(`/api/v1/categories/${categoryId}`, formData, 'put')
        .then(this.uploadCompleteCategory);
    } else {
      uploadPromise = this.uploadFile(`/api/v1/articles/${articleId}/images`, formData)
        .then(this.uploadCompleteMegadraft);
    }
    uploadPromise.catch((err) => {
      const { onError } = this.props;
      console.error(err);
      if (err.response && err.response.data.error) {
        alert(err.response.data.error);
      } else {
        alert('Возникла ошибка при загрузке файла');
      }
      this.setState({
        file: undefined,
        isLoading: false,
        progress: 0,
        fileUrl: undefined,
      });
      onError && onError(err);
    });
  }

  uploadFile = (url, formData, method = 'post') => axios[method](url, formData, {
        onUploadProgress: progressEvent => {
          const percentCompleted = progressEvent.loaded / progressEvent.total;
          this.setState(state => ({ ...state, progress: percentCompleted }));
        },
      })

  uploadCompleteCover = (response) => {
    const { onUploadComplete } = this.props;
    this.setState({ progress: 1, fileUrl: response.data.url, isLoading: false }, () => {
      onUploadComplete && onUploadComplete(response.data.background_image);
    });
  }

  uploadCompleteCategory = (response) => {
    const { onUploadComplete } = this.props;
    this.setState({ progress: 1, fileUrl: response.data.original_image, isLoading: false }, () => {
      onUploadComplete && onUploadComplete(response.data);
    });
  }

  uploadCompleteMegadraft = (response) => {
    const { onUploadComplete } = this.props;
    this.setState({ progress: 1, fileUrl: response.data.url, isLoading: false }, () => {
      onUploadComplete && onUploadComplete(response.data);
    });
  }

  render() {
    const { className, type, subMsg } = this.props;
    const { file, fileUrl, progress, isLoading } = this.state;
    const Icon = this.getIcon();
    return (
      <ReactDropzone multiple={false} disabled={isLoading} accept={this.getTypeFile()} onDrop={this.handlerDropFile}>
        {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
          <div
            className={classNames('Dropzone', {
              'Dropzone--CanDrop': !isLoading,
              'Dropzone--DragAccept': isDragAccept,
              'Dropzone--DragReject': isDragReject,
            }, className)}
            {...getRootProps()}
          >
            <input className="Dropzone__Input" {...getInputProps()} />
            {!file && !isDragActive && (
              <>
                <div className="Dropzone__Message">
                  <div className="Dropzone__MessageIcon">
                    <Icon width={44} height={35} />
                  </div>
                  <div className="Dropzone__MessageTitle">{this.getTitle()}</div>
                  <div className="Dropzone__MessageText">{this.getMessage()}</div>
                </div>
                {subMsg && <div className="Dropzone__SubMsg">{subMsg}</div>}
              </>
            )}
            {isDragAccept && (
              <div className="Dropzone__DropMessage">
                <img className="Dropzone__DropIcon" src={IconDrop} alt="" />
                <div className="Dropzone__DropText">Сбросьте файл для загрузки</div>
              </div>
            )}
            {isDragReject && (
              <div className="Dropzone__DropMessage">
                <img className="Dropzone__DropIcon" src={IconDropRej} alt="" />
                <div className="Dropzone__DropText">Файл не поддерживается</div>
              </div>
            )}
            {isLoading && (
              <div className="Dropzone__Loader">
                <div className="Dropzone__LoaderProgress">
                  <ProgressCircle size={60} progress={progress} />
                </div>
                <div className="Dropzone__FileName">{file.name}</div>
                <div className="Dropzone__Progress">{`Загружено: ${(+progress * 100).toFixed(1)}%`}</div>
              </div>
            )}
            {fileUrl && !isDragActive && !isLoading && (
              <>
                {(type === TypeDropzone.IMAGE || type === TypeDropzone.COVER) && (
                  <div className="Dropzone__PreviewImage" style={{ backgroundImage: `url("${fileUrl}")` }} />
                )}
                {type === TypeDropzone.VIDEO && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      className="Dropzone__PreviewVideo"
                      src={fileUrl}
                      controls="controls"
                      onClick={e => e.stopPropagation()}
                    />
                  </>
                )}
                <ButtonIcon
                  className="Dropzone__Btn"
                  iconSrc={this.getEditBtnIcon()}
                  text={this.getEditBtnTitle()}
                />
              </>
            )}
          </div>
        )}
      </ReactDropzone>
    );
  }
}

Dropzone.propTypes = {
  className: PropTypes.string,
  articleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf([TypeDropzone.IMAGE, TypeDropzone.VIDEO, TypeDropzone.COVER, TypeDropzone.IMAGE_CATEGORY]),
  fieldName: PropTypes.string,
  onUploadStart: PropTypes.func,
  onUploadComplete: PropTypes.func,
  onError: PropTypes.func,
  subMsg: PropTypes.node,
};

Dropzone.defaultProps = {
  type: TypeDropzone.IMAGE,
  fieldName: 'file',
};

export default Dropzone;
