import './options-menu.sass';

function init () {
  $(document).off('click.options-menu').on('click.options-menu', '.options-menu', function () {
    const $this = $(this);
    $('.options-menu').not($this).removeClass('options-menu--open');
    $this.toggleClass('options-menu--open');
  });

  $(document).off('click.options-menu-outside').on('click.options-menu-outside', function (e) {
    if(!$(e.target).closest('.options-menu').length) {
      $('.options-menu').removeClass('options-menu--open');
    }
  });
}

$(init);
