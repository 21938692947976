import React from "react";
import ReactDOM from "react-dom";
import App from './App';

export default function initEditor() {
  const el = document.getElementById('app-editor');
  if (!el) {
    return;
  }
  const props = {
    id: el.dataset.id,
    showguide: !!el.dataset.hasOwnProperty('showguide'),
  }
  ReactDOM.render(
    <App {...props} />,
    el
  );

  if (module.hot) {
    module.hot.accept('./App', () => {
      // eslint-disable-next-line global-require
      const NewApp = require('./App').default;
      ReactDOM.render(
        <NewApp {...props} />,
        el
      );
    });
  }

};
