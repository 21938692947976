import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import Modal from '~/editor2/components/Modal';
import './ModalQuizPub.sass';

class ModalQuizPub extends PureComponent {
  render() {
    const {isOpen, onAccept, onCancel} = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCancel}
        title="При публикации статьи созданный вами опрос не будет опубликован!"
        portalClassName="ModalQuizPub"
      >
        <div className="ModalQuizPub__Content">
          Проверьте заполнение темы опроса, укажите более 1-го
          варианта ответа и выберите формат опроса (анонимный или публичный)
        </div>
        <div className="ModalQuizPub__Actions">
          <div className="ModalQuizPub__Action">
            <button className="button button--prime button--lg" onClick={onCancel}>
              Ок, я доработаю опрос
            </button>
          </div>
          <div className="ModalQuizPub__Action">
            <button className="button button--outline2 button--lg" onClick={onAccept}>
              Все равно опубликовать статью
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

ModalQuizPub.propTypes = {
  isOpen: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ModalQuizPub;
