var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (category_name, id, image, title) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"recommend_article_item\""+pug.attr("id", "recommend_article_item_"+id, true, true)) + "\u003E";
if (image) {
pug_html = pug_html + "\u003Cdiv class=\"img\"\u003E\u003Cimg" + (" class=\"responsive\""+pug.attr("src", image, true, true)+pug.attr("alt", title, true, true)) + "\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-md-10\"\u003E\u003Cdiv class=\"row_1\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cfont\u003E" + (pug.escape(null == (pug_interp = ' – ') ? "" : pug_interp)) + "\u003C\u002Ffont\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = category_name) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-md-2 alignright\"\u003E\u003Cdiv class=\"form_radio\"\u003E\u003Cinput" + (" type=\"radio\" name=\"recommendation_id\""+pug.attr("id", "recommendation_id_"+id, true, true)+pug.attr("value", id, true, true)) + "\u003E\u003Clabel" + (pug.attr("for", "recommendation_id_"+id, true, true)) + "\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "category_name" in locals_for_with ?
        locals_for_with.category_name :
        typeof category_name !== 'undefined' ? category_name : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "image" in locals_for_with ?
        locals_for_with.image :
        typeof image !== 'undefined' ? image : undefined, "title" in locals_for_with ?
        locals_for_with.title :
        typeof title !== 'undefined' ? title : undefined));
    ;;return pug_html;};
module.exports = template;