var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (content, kind) {
      pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["alert",'alert-'+kind], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"container\"\u003E" + (pug.escape(null == (pug_interp = content) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "content" in locals_for_with ?
        locals_for_with.content :
        typeof content !== 'undefined' ? content : undefined, "kind" in locals_for_with ?
        locals_for_with.kind :
        typeof kind !== 'undefined' ? kind : undefined));
    ;;return pug_html;};
module.exports = template;