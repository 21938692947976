import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import SizePopover, { Sizes } from '~/editor2/components/Popovers/Size';

import iconCarouselMake from './icon-make-carousel.svg';
import iconCarouselEdit from './icon-edit-carousel.svg';
import CarouselEditor from './CarouselEditor';
import ButtonIcon from '~/editor2/components/ButtonIcon';
import {withEditorContentContext} from "~/editor2/components/EditorContent/EditorContentContext";

class Block extends PureComponent {
  constructor(props) {
    super(props);
    this.handleCaptionChange = ::this.handleCaptionChange;
    this.handleClickPopover = ::this.handleClickPopover;
    this.handlerChangeSize = ::this.handlerChangeSize;
    this.handlerClickCreateCarousel = ::this.handlerClickCreateCarousel;
    this.handlerClickEditCarousel = ::this.handlerClickEditCarousel;
    this.handlerSaveEditorCarousel = ::this.handlerSaveEditorCarousel;

    this.state = {
      open: false,
      isEditCarousel: false,
      activeSlide: 0,
    };
  }

  handleCaptionChange(event) {
    const { container } = this.props;
    container.updateData({ caption: event.target.value });
  }

  handleClickPopover(e) {
    if(!this.isCanEdit()) return;
    e.preventDefault();
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  }

  handlerChangeSize(newSize) {
    const { container } = this.props;
    container.updateData({ size: newSize });
  }

  handlerClickCreateCarousel(e) {
    e.preventDefault();
    const { container, data } = this.props;
    const { size } = data;
    container.updateData({
      isCarousel: true,
      size: [Sizes.FLOAT, Sizes.FULL_SCREEN].includes(size) ? Sizes.CONTAINS : size,
    });
    this.setState({ isEditCarousel: true });
  }

  handlerClickEditCarousel(e) {
    e.preventDefault();
    this.setState({ isEditCarousel: true });
  }

  handlerSaveEditorCarousel(newImages) {
    const { container } = this.props;
    if (newImages.length) {
      this.setState({ isEditCarousel: false });
      container.updateData({ images: [...newImages] });
    } else {
      container.remove();
    }
  }

  handlerFocus = () => {
    this.setState({ isFocused: true });
  }

  handlerBlur = () => {
    this.setState({ isFocused: false });
  }

  isCanEdit() {
    const { isFocused } = this.state;
    const { isReadOnly } = this.props;
    return !isReadOnly; // || isFocused;
  }

  handlerAfterChangeSlider = (index) => {
    this.setState({
      activeSlide: index
    });
  }

  render() {
    const { open, isEditCarousel, activeSlide } = this.state;
    const { article = {}, data } = this.props;
    const {
      size, caption, images, isCarousel,
    } = data;
    const { id } = article;
    return (
      <div
        className={classNames('Images', {
          'Images--Carousel': isCarousel,
          'Images--Contains': size === Sizes.CONTAINS,
          'Images--Float': size === Sizes.FLOAT,
          'Images--FullWidth': size === Sizes.FULL_WIDTH,
          'Images--FullScreen': size === Sizes.FULL_SCREEN,
        })}
        onFocus={this.handlerFocus}
        onBlur={this.handlerBlur}
        onInput={(e) => e.stopPropagation()}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className="Images__Container">
          <>
            {isCarousel && isEditCarousel && (
              <CarouselEditor
                articleId={id}
                images={images}
                onCancel={() => this.setState({ isEditCarousel: false })}
                onSave={this.handlerSaveEditorCarousel}
              />
            )}
            {(!isEditCarousel || !isCarousel) && (
              <>
                <SizePopover
                  selected={size}
                  sizes={isCarousel ? [Sizes.CONTAINS, Sizes.FULL_WIDTH] : undefined}
                  onChange={this.handlerChangeSize}
                  onOuterAction={this.handleClickPopover}
                  isOpen={this.isCanEdit() && open}
                >
                  <div
                    className={classNames('Images__ImgWrap', {
                      'Images__ImgWrap--WithMenu': this.isCanEdit() && open,
                    })}
                    role="button"
                    tabIndex={0}
                    onClick={this.handleClickPopover}
                  >
                    {isCarousel && (
                      <div className="Images__CarouselWrap">
                        <Slider className="Images__Carousel" afterChange={this.handlerAfterChangeSlider}>
                          {images.map((img) => (
                            <div className="Images__CarouselSlide" key={img.id}>
                              <div className="Images__CarouselImage" style={{ backgroundImage: `url('${img.url}')` }} />
                            </div>
                          ))}
                        </Slider>
                        {this.isCanEdit() && (
                          <ButtonIcon
                            className="Images__Btn"
                            iconSrc={iconCarouselEdit}
                            text="Редактировать карусель"
                            onClick={this.handlerClickEditCarousel}
                          />
                        )}
                        <div className="Images__CarouselCounter">
                          {activeSlide + 1} из {images.length}
                        </div>
                      </div>
                    )}
                    {!isCarousel && (
                      <>
                        <img
                          className="Images__Img"
                          src={images[0].url}
                          alt=""
                        />
                        {this.isCanEdit() && (
                          <ButtonIcon
                            className="Images__Btn"
                            iconSrc={iconCarouselMake}
                            text="Сделать карусель"
                            onClick={this.handlerClickCreateCarousel}
                          />
                        )}
                      </>
                    )}
                  </div>
                </SizePopover>
                {!this.isCanEdit() && !!caption && <div className="Images__Caption">{caption}</div>}
                {this.isCanEdit() && (
                  <input
                    className="Images__Caption"
                    placeholder="Добавьте подпись (опционально)"
                    value={caption}
                    onChange={this.handleCaptionChange}
                  />
                )}
              </>
            )}
          </>
        </div>
      </div>
    );
  }
}

Block.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  container: PropTypes.any,
  article: PropTypes.any,
  isReadOnly: PropTypes.bool,
  data: PropTypes.shape({
    size: PropTypes.oneOf([Sizes.CONTAINS, Sizes.FLOAT, Sizes.FULL_SCREEN, Sizes.FULL_WIDTH]),
    caption: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object),
    isCarousel: PropTypes.bool,
  }),
};

export default withEditorContentContext(Block);
