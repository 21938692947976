import AppModalCommunity from '~/pages/admin/categories/new/ModalCommunity';

function init() {
  $('.js-admin-new-category-btn').on('click', function(event) {
    event.preventDefault();
    const $btnAdminAddEdit = $(this);
    let appModalCommunity = null;
    $.magnificPopup.open({
      items: {
        src: '<div />',
        type: 'inline',
      },
      fixedBgPos: true,
      fixedContentPos: true,
      modal: true,
      mainClass: 'modal-community-wrapper',
      autoFocusLast: false,
      callbacks: {
        open: () => {
          $('.mfp-wrap').attr('tabindex', false);
          const $modalContainer = $('.modal-community-wrapper .mfp-content');
          if ($modalContainer.length) {
            const categoryId = $btnAdminAddEdit.data('id');
            appModalCommunity = new AppModalCommunity($modalContainer[0], {
              categoryId,
              onClose: ({ original_image, img_rect, category } = {}) => {
                if (categoryId && (original_image || img_rect || category)) {
                  $(`.js-img-cat-id-${categoryId}`)
                    .css({
                      backgroundImage: `url('${img_rect || original_image}')`
                    });
                  category && category.name && $('.name_cat').text(category.name);
                }
                $.magnificPopup.close();
              }
            });
          }
        },
        beforeClose: () => {
          if (appModalCommunity) {
            appModalCommunity.destroy();
          }
        }
      }
    });
  });
}

$(document).on('turbolinks:load', init);
